import ComingSoon from "./components/comingSoon/ComingSoon"
import Layout from "./components/layout/Layout"
import {createBrowserRouter, Navigate} from 'react-router-dom'
import SelectionPage from './components/SelectionPage/SelectionPage.jsx';
import Home from "./pages/Home.jsx";
import About from './pages/about/About.jsx';
import Services from './pages/Services.jsx';
import Projects from './pages/Projects.jsx';
import Clients from './components/Clients/Clients.jsx';
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute.jsx";
import Contact from "./components/Contact/Contact.jsx";
import NotFound from './components/NotFound/NotFound.jsx';
import Quality from './components/Quality/Quality.jsx';
import Geotechnical from './components/Geotechnical/Geotechnical.jsx';
import Soil from './components/Soil/Soil.jsx';
import Chemical from './components/Chemical/Chemical.jsx';
import Building from './components/Building/Building.jsx';
import Architectural from './components/Architectural/Architectural.jsx';
import Hydrological from './components/Hydrological/Hydrological.jsx';
import Gis from './components/Gis/Gis.jsx';
import Supervision from './components/Supervision/Supervision.jsx';
import Infrastructure from './components/Infrastructure/Infrastructure.jsx';
import Transport from './components/Transport/Transport.jsx';
import Electrical from './components/Electrical/Electrical.jsx';
import Evaluation from './components/Evaluation/Evaluation.jsx';
import CompanyRoute from "./components/CompanyRoute/CompanyRoute.jsx";
import LabsRoute from "./components/LabsRoute/LabsRoute.jsx";
import Workshops from './components/Workshops/Workshops';
import Blogs from './components/Blogs/Blogs';
import Project from './components/Project/Project';
import Studies from './components/Studies/Studies';
import Examples from './components/Examples/Examples';
import ProjectsCategories from "./components/ProjectsCategories/ProjectsCategories.jsx";

const routes = createBrowserRouter([
    { path: '', element: <Layout />, children: [
      { path: 'select-site', element: <SelectionPage /> },
      { index: true, element: <ProtectedRoute><Home /></ProtectedRoute> },
      { path: 'about', element: <ProtectedRoute><About /></ProtectedRoute> },
      { path: 'services', element: <ProtectedRoute><Services /></ProtectedRoute> },
      { path: 'services/geotechnical', element: <ProtectedRoute><LabsRoute><Geotechnical /></LabsRoute></ProtectedRoute> },
      { path: 'services/soil', element: <ProtectedRoute><LabsRoute><Soil /></LabsRoute></ProtectedRoute> },
      { path: 'services/chemical-analysis', element: <ProtectedRoute><LabsRoute><Chemical /></LabsRoute></ProtectedRoute> },
      { path: 'services/building', element: <ProtectedRoute><LabsRoute><Building /></LabsRoute></ProtectedRoute> },
      { path: 'services/soil-studies', element: <ProtectedRoute><LabsRoute><Studies /></LabsRoute></ProtectedRoute> },
      { path: 'services/architectural', element: <ProtectedRoute><CompanyRoute><Architectural /></CompanyRoute></ProtectedRoute> },
      { path: 'services/hydrological', element: <ProtectedRoute><CompanyRoute><Hydrological /></CompanyRoute></ProtectedRoute> },
      { path: 'services/GIS', element: <ProtectedRoute><CompanyRoute><Gis /></CompanyRoute></ProtectedRoute> },
      { path: 'services/supervision', element: <ProtectedRoute><CompanyRoute><Supervision /></CompanyRoute></ProtectedRoute> },
      { path: 'services/infrastructure', element: <ProtectedRoute><CompanyRoute><Infrastructure /></CompanyRoute></ProtectedRoute> },
      { path: 'services/transport', element: <ProtectedRoute><CompanyRoute><Transport /></CompanyRoute></ProtectedRoute> },
      { path: 'services/electrical', element: <ProtectedRoute><CompanyRoute><Electrical /></CompanyRoute></ProtectedRoute> },
      { path: 'services/evaluation', element: <ProtectedRoute><CompanyRoute><Evaluation /></CompanyRoute></ProtectedRoute> },
      { path: 'projects', element: <ProtectedRoute><CompanyRoute><ProjectsCategories /></CompanyRoute></ProtectedRoute> },
      // { path: 'projects', element: <Navigate to="/projects/all" /> },
      { path: 'projects/:projectsId', element: <ProtectedRoute><Projects /></ProtectedRoute> },
      { path: 'projectDetails/:id', element: <ProtectedRoute><Project /></ProtectedRoute> },
      { path: 'projectDetails/examples/:id', element: <ProtectedRoute><Examples /></ProtectedRoute> },
      { path: 'clients', element: <ProtectedRoute><Clients /></ProtectedRoute> },
      { path: 'quality', element: <ProtectedRoute><Quality /></ProtectedRoute> },
      { path: 'workshops', element: <ProtectedRoute><Workshops /></ProtectedRoute> },
      { path: 'trainingLabs', element: <ProtectedRoute><Workshops /></ProtectedRoute> },
      { path: 'contact-us', element: <ProtectedRoute><Contact /></ProtectedRoute> },
      { path: 'comingSoon', element: <ProtectedRoute><ComingSoon /></ProtectedRoute> },
      { path: 'media-center', element: <ProtectedRoute><Blogs /></ProtectedRoute> },
      { path: '*', element: <NotFound /> },
    ]}
  ])
export default routes
