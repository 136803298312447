import React, { useContext } from 'react'
// import style from './Building.module.css'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../context/LangContext'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import first1 from '../../assets/images/services/المختبرات/المباني/1-أخذ عينات القلب الخرسانية/019.webp'
import first2 from '../../assets/images/services/المختبرات/المباني/1-أخذ عينات القلب الخرسانية/024.webp'
import first3 from '../../assets/images/services/المختبرات/المباني/1-أخذ عينات القلب الخرسانية/027.webp'
import first4 from '../../assets/images/services/المختبرات/المباني/1-أخذ عينات القلب الخرسانية/C2 BF 1.webp'
import first5 from '../../assets/images/services/المختبرات/المباني/1-أخذ عينات القلب الخرسانية/taking cores.webp'
import second1 from '../../assets/images/services/المختبرات/المباني/2-اختبار المطرقة الإرتدادية اختبار مطرقة شميدت/Shm - RFST F 2-1.webp'
import second2 from '../../assets/images/services/المختبرات/المباني/2-اختبار المطرقة الإرتدادية اختبار مطرقة شميدت/Shm-GF -6-1.webp'
import second3 from '../../assets/images/services/المختبرات/المباني/2-اختبار المطرقة الإرتدادية اختبار مطرقة شميدت/Shm-GF 2 1.webp'
import second4 from '../../assets/images/services/المختبرات/المباني/2-اختبار المطرقة الإرتدادية اختبار مطرقة شميدت/تقييم مباني5.webp'
import third1 from '../../assets/images/services/المختبرات/المباني/3-الكشف عن حديد التسليح/7 (2).webp'
import third2 from '../../assets/images/services/المختبرات/المباني/3-الكشف عن حديد التسليح/INSPECTION T.P 1 -1.webp'
import third3 from '../../assets/images/services/المختبرات/المباني/3-الكشف عن حديد التسليح/تقييم مباني2.webp'
import third4 from '../../assets/images/services/المختبرات/المباني/3-الكشف عن حديد التسليح/كفر ميتر.webp'
import fourth1 from '../../assets/images/services/المختبرات/المباني/4-اختبار نصف الخلية/half cell FST F 1-1.webp'
import fourth2 from '../../assets/images/services/المختبرات/المباني/4-اختبار نصف الخلية/half cell G F 1-1.webp'
import fourth3 from '../../assets/images/services/المختبرات/المباني/4-اختبار نصف الخلية/اختبار نصف الخلية Half cell test.webp'
import fourth4 from '../../assets/images/services/المختبرات/المباني/4-اختبار نصف الخلية/تقييم منشأت8.webp'
import fourth5 from '../../assets/images/services/المختبرات/المباني/4-اختبار نصف الخلية/تقييم منشأت9.webp'
import fifth1 from '../../assets/images/services/المختبرات/المباني/5-اختبار الامواج فوق الصوتية/Ultra sonic 2-1.webp'
import fifth2 from '../../assets/images/services/المختبرات/المباني/5-اختبار الامواج فوق الصوتية/ألتراسونك.webp'
import fifth3 from '../../assets/images/services/المختبرات/المباني/5-اختبار الامواج فوق الصوتية/تقييم المباني4.webp'
import fifth4 from '../../assets/images/services/المختبرات/المباني/5-اختبار الامواج فوق الصوتية/تقييم مباني1.webp'
import fifth5 from '../../assets/images/services/المختبرات/المباني/5-اختبار الامواج فوق الصوتية/تقييم مباني6.webp'
import sixth1 from '../../assets/images/services/المختبرات/المباني/6-تجربة تحميل السقف (Slab Load Test)/load slab1.webp'
import sixth2 from '../../assets/images/services/المختبرات/المباني/6-تجربة تحميل السقف (Slab Load Test)/load slab2.webp'
import sixth3 from '../../assets/images/services/المختبرات/المباني/6-تجربة تحميل السقف (Slab Load Test)/load slab3.webp'

export default function Building() {

  const { t } = useTranslation()
  let {language}= useContext(LangContext)

  const firstImages = [
    first1, first2, first3, first4, first5
  ]
  const secondImages = [
    second1, second2, second3, second4
  ]
  const thirdImages = [
    third1, third2, third3, third4
  ]
  const fourthImages = [
    fourth1, fourth2, fourth3, fourth4, fourth5
  ]
  const fifthImages = [
    fifth1, fifth2, fifth3, fifth4, fifth5
  ]
  const sixthImages = [
    sixth1, sixth2, sixth3
  ]

  return <>
  <div dir={language==='en'? '':'rtl'}>
    <div className="header-img py-5">
      <div className='w-90 mx-auto d-flex h-100 flex-column align-items-center justify-content-center'>
        <p className='position-relative line fs-2 fw-bold text-black d-flex align-items-center text-center justify-content-center pb-3 mt-3 mb-5'>{t('navBuilding')}</p>
      </div>
    </div> 
    <div className='container-md w-90 mx-auto pb-5'>
      <p className='pt-5 mb-4 fs-4 text-main2 fw-bold position-relative w-fit line3'>{t('buildingsService.1.header')}</p> 
      <p className='py-3 lh-lg fs-5'>{t('buildingsService.1.text')}<br />{t('buildingsService.1.text2')}</p>
      <Slider
        dots={true}
        autoplay={true}
        infinite={true}
        speed={2000}
        autoplaySpeed= {4000}
        initialSlide= {0}
        slidesToShow={4}
        slidesToScroll={1}
        rtl={language==='en'? false:true}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
            },
          },
        ]}
      >
        {firstImages.map((image, index) => <div className='px-2 cursor-grab' key={index}>
          <img loading='lazy' className='w-100 rounded-3' src={image} alt={t('buildingsService.5.header')} />
        </div>)}
      </Slider>
    </div>
    <div className="bg-light">
      <div className='container-md w-90 mx-auto pb-5 fs-5'>
        <p className='pt-5 mb-4 fs-4 text-main2 fw-bold position-relative w-fit line3'>{t('buildingsService.2.header')}</p>
        <p className="py-3 lh-lg">{t('buildingsService.2.text')}<br />{t('buildingsService.2.text2')}</p>
        <Slider
          autoplay= {4}
          infinite={false}
          arrows={false}
          dots={false}
          speed={2000}
          autoplaySpeed= {4000}
          initialSlide= {0}
          slidesToShow={4}
          slidesToScroll={1}
          rtl={language==='en'? false:true}
          responsive={[
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 3,
                arrows: true,
                dots: true,
                infinite: true,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                arrows: true,
                dots: true,
                infinite: true,
              },
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
                arrows: true,
                dots: true,
                infinite: true,
              },
            },
          ]}
        >
          {secondImages.map((image, index) => <div className='px-2 cursor-grab' key={index}>
            <img loading='lazy' className='w-100 rounded-3' src={image} alt={t('buildingsService.5.header')} />
          </div>)}
        </Slider>
      </div>
    </div>
    <div className='container-md w-90 mx-auto pb-5 fs-5'>
      <p className='pt-5 mb-4 fs-4 text-main2 fw-bold position-relative w-fit line3'>{t('buildingsService.3.header')}</p>
      <p className='py-3'>{t('buildingsService.3.text')}</p>
      <ul className={`list-style-disc ${language==='en'? 'ps-sm-5 ps-4 pe-0':'pe-sm-5 pe-4 ps-0'}`}>
        <li className="py-2">{t('buildingsService.3.list.1')}</li>
        <li className="py-2">{t('buildingsService.3.list.2')}</li>
        <li className="py-2">{t('buildingsService.3.list.3')}</li>
        <li className="py-2">{t('buildingsService.3.list.4')}</li>
      </ul>
      <Slider
        autoplay= {4}
        infinite={false}
        arrows={false}
        dots={false}
        speed={2000}
        autoplaySpeed= {4000}
        initialSlide= {0}
        slidesToShow={4}
        slidesToScroll={1}
        rtl={language==='en'? false:true}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
              arrows: true,
              dots: true,
              infinite: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              arrows: true,
              dots: true,
              infinite: true,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              arrows: true,
              dots: true,
              infinite: true,
            },
          },
        ]}
      >
        {thirdImages.map((image, index) => <div className='px-2 cursor-grab' key={index}>
          <img loading='lazy' className='w-100 rounded-3' src={image} alt={t('buildingsService.5.header')} />
        </div>)}
      </Slider>
    </div>
    <div className="bg-light">
      <div className='container-md w-90 mx-auto pb-5 fs-5'>
        <p className='pt-5 mb-4 fs-4 text-main2 fw-bold position-relative w-fit line3'>{t('buildingsService.4.header')}</p>
        <p className="py-3 lh-lg">{t('buildingsService.4.text')}<br />{t('buildingsService.4.text2')}</p>
        <Slider
          dots={true}
          autoplay={true}
          infinite={true}
          speed={2000}
          autoplaySpeed= {4000}
          initialSlide= {0}
          slidesToShow={4}
          slidesToScroll={1}
          rtl={language==='en'? false:true}
          responsive={[
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
        >
          {fourthImages.map((image, index) => <div className='px-2 cursor-grab' key={index}>
            <img loading='lazy' className='w-100 rounded-3' src={image} alt={t('buildingsService.5.header')} />
          </div>)}
        </Slider>
      </div>
    </div>
    <div className='container-md w-90 mx-auto pb-5 fs-5'>
      <p className='pt-5 mb-4 fs-4 text-main2 fw-bold position-relative w-fit line3'>{t('buildingsService.5.header')}</p>
      <p className="py-3 lh-lg">{t('buildingsService.5.text')}<br />{t('buildingsService.5.text2')}</p>
      <Slider
        dots={true}
        autoplay={true}
        infinite={true}
        speed={2000}
        autoplaySpeed= {4000}
        initialSlide= {0}
        slidesToShow={4}
        slidesToScroll={1}
        rtl={language==='en'? false:true}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
            },
          },
        ]}
      >
        {fifthImages.map((image, index) => <div className='px-2 cursor-grab' key={index}>
          <img loading='lazy' className='w-100 rounded-3' src={image} alt={t('buildingsService.5.header')} />
        </div>)}
      </Slider>
    </div>
    <div className="bg-light">
      <div className='container-md w-90 mx-auto pb-5 fs-5'>
        <p className='pt-5 mb-4 fs-4 text-main2 fw-bold position-relative w-fit line3'>{t('buildingsService.6.header')}</p> 
        <p className='py-3 lh-lg'>{t('buildingsService.6.text')}<br />{t('buildingsService.6.text2')}</p>
        <Slider
          autoplay= {3}
          infinite={false}
          arrows={false}
          dots={false}
          speed={2000}
          autoplaySpeed= {4000}
          initialSlide= {0}
          slidesToShow={3}
          slidesToScroll={1}
          rtl={language==='en'? false:true}
          responsive={[
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
              arrows: true,
              dots: true,
              infinite: true,
              },
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
              arrows: true,
              dots: true,
              infinite: true,
              },
            },
          ]}
        >
          {sixthImages.map((image, index) => <div className='px-2 cursor-grab' key={index}>
            <img loading='lazy' className='w-100 rounded-3' src={image} alt={t('buildingsService.5.header')} />
          </div>)}
        </Slider>
      </div>
    </div>
  </div> 
  </>
}
