import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../components/context/LangContext'
import { Link } from 'react-router-dom'
import serviceLab1_1 from '../assets/images/services/المختبرات/فحص وتقييم المباني صورة 2.webp'
import serviceLab1_2 from '../assets/images/services/المختبرات/مشروع تبوك صورة 4.webp'
import serviceLab2_1 from '../assets/images/services/المختبرات/اختبار عينات كور خرساني.webp'
import serviceLab2_2 from '../assets/images/services/المختبرات/أخذ كور من طبقة اسفلتية 1.webp'
import serviceLab3_1 from '../assets/images/services/المختبرات/حفارات9.webp'
import serviceLab3_2 from '../assets/images/services/المختبرات/حفارات 8.webp'
import serviceLab4_1 from '../assets/images/services/المختبرات/pic1.webp'
import serviceLab4_2 from '../assets/images/services/المختبرات/pic3.webp'
import serviceLab5_1 from '../assets/images/services/المختبرات/دراسات فنية/فقرة الدرسات الفنية 2.webp'
import serviceLab5_2 from '../assets/images/services/المختبرات/دراسات فنية/فقرة الدرسات الفنية الخاصة.webp'
import serviceLab4_3 from '../assets/images/services/المختبرات/pic7.webp'
import serviceCompany1_1 from '../assets/images/services/الاستشارات/5.webp'
import serviceCompany1_2 from '../assets/images/services/الاستشارات/ابوبندر_page-0001.webp'
import serviceCompany2_1 from '../assets/images/services/الاستشارات/WhatsApp Image 2024-07-25 at 9.13.18 AM.webp'
import serviceCompany2_2 from '../assets/images/services/الاستشارات/WhatsApp Image 2024-07-25 at 9.00.05 AM.webp'
import serviceCompany3_1 from '../assets/images/services/الاستشارات/IMG-20230417-WA0026.webp'
import serviceCompany3_2 from '../assets/images/services/الاستشارات/WhatsApp Image 2023-04-01 at 15.30.45.webp'
import serviceCompany4_1 from '../assets/images/services/الاستشارات/IMG-20241121-WA0020.webp'
import serviceCompany4_2 from '../assets/images/services/الاستشارات/DSCN1225.webp'
import serviceCompany5_1 from '../assets/images/services/الاستشارات/WhatsApp Image 2024-03-05 at 7.40.00 AM (1).webp'
import serviceCompany5_2 from '../assets/images/services/الاستشارات/pan and profile road0_page-0001.webp'
import serviceCompany6_1 from '../assets/images/services/الاستشارات/WhatsApp Image 2024-07-29 at 9.19.40 AM.webp'
import serviceCompany6_2 from '../assets/images/services/الاستشارات/WhatsApp Image 2024-09-30 at 11.16.56_65601b4d.webp'
import serviceCompany7_1 from '../assets/images/services/الاستشارات/WhatsApp Image 2024-09-22 at 16.51.39_0cd91c02.webp'
import serviceCompany7_2 from '../assets/images/services/الاستشارات/WhatsApp Image 2024-07-28 at 8.49.54 PM.webp'
import serviceCompany8_1 from '../assets/images/services/الاستشارات/WhatsApp Image 2022-06-23 at 1.09.57 PM.webp'
import serviceCompany8_2 from '../assets/images/services/الاستشارات/مشروع تبوك صورة 4.webp'
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/autoplay"
import 'swiper/css/pagination'
import 'swiper/swiper-bundle.css';
import { Pagination } from 'swiper/modules'

export default function Services() {

  const { t } = useTranslation()
  let {language}= useContext(LangContext)
  const site = sessionStorage.getItem('desiredPage')

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return <>
  
    <div dir={language==='en'? '':'rtl'}>
                                        {/* header */}
      <div className="header-img py-5">
        <div className='container-md w-95 mx-auto d-flex h-100 flex-column align-items-center justify-content-center'>
          <p className='position-relative line fs-2 fw-bold text-black d-flex align-items-center justify-content-center pb-3 mt-3 mb-5'>{t('servicesHeader')}</p>
          <div className='d-flex'>
            <Link to={'/'}className='text-hover trans3'>{t('navHome')}</Link>
            <span className='text-black px-2'>/</span>
            <p className='text-secondary'>{t('services')}</p>
          </div>
        </div>
      </div>  

      {/* contents */}
      <div className='container-md w-95 py-5 mx-auto'>
      {site==='company'? '':<p className={`text-light-emphasis mt-5 mb-3 text-uppercase fs-6 text-center`}>{t('servicesText1')}</p>}
        <p className='text-center fs-4 fw-bold mx-auto container-lg px-lg-5 px-0'>{site==='company'? t('servicesText1Company'):t('servicesText2')}</p>
      </div>
      {site==='company'? <div className='container-md w-90 pb-5 mx-auto row g-3 px-0 justify-content-center'>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className='w-100 h-100 scale2 cardd text-center border border-light-subtle shadow-hover bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                <hr className='top-line position-absolute z-2 m-0 top-0 end-0 start-0 opacity-100' />
                <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                  <div className="carousel-inner">
                    <div className="carousel-item w-100 rem-15 overflow-hidden active">
                      <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany1_1:serviceLab3_1} alt="service" />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany1_2:serviceLab3_2} alt="service" />
                    </div>
                  </div>
                </div>
                <h4 className='py-xl-4 py-2 my-2 px-4'>{site==='company'? t('navArchitectural'):t('navGeotechnical')}</h4>
                <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                <p className='p-4 my-2 text-secondary'>{site==='company'? t('service1CompanySentence'):t('service1LabSentence')}</p>
                <Link to={site==='company'? '/services/architectural':'/services/geotechnical'} className={`btn d-flex align-items-center justify-content-center`}><p className='text-body-secondary trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
            <div className='w-100 h-100 scale2 cardd text-center border border-light-subtle shadow-hover bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                <hr className='top-line position-absolute z-2 m-0 top-0 end-0 start-0 opacity-100' />
                <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                  <div className="carousel-inner">
                    <div className="carousel-item w-100 rem-15 overflow-hidden active">
                      <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany2_1:serviceLab2_2} alt="service" />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany2_2:serviceLab2_1} alt="service" />
                    </div>
                  </div>
                </div>
                <h4 className='py-xl-4 py-2 my-2 px-4'>{site==='company'? t('navHydrological'):t('navSoil')}</h4>
                <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                <p className='p-4 my-2 text-secondary'>{site==='company'? t('service2CompanySentence'):t('service3LabSentence')}</p>
                <Link to={site==='company'? '/services/hydrological':'/services/soil'} className={`btn d-flex align-items-center justify-content-center`}><p className='text-body-secondary trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
            <div className='w-100 h-100 scale2 cardd text-center border border-light-subtle shadow-hover bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                <hr className='top-line position-absolute z-2 m-0 top-0 end-0 start-0 opacity-100' />
                <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                  <div className="carousel-inner">
                    <div className="carousel-item w-100 rem-15 overflow-hidden active">
                      <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany4_1:serviceLab4_1} alt={site==='company'? t('navSupervision'):t('navChemical')} />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany4_2:serviceLab4_2} alt={site==='company'? t('navSupervision'):t('navChemical')} />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceLab4_3} alt={site==='company'? t('navSupervision'):t('navChemical')} />
                    </div>
                  </div>
                </div>
                <h4 className='py-xl-4 py-2 my-2 px-4'>{site==='company'? t('navGIS'):t('navChemical')}</h4>
                <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                <p className='p-4 my-2 text-secondary'>{site==='company'? t('service3CompanySentence'):t('service2LabSentence')}</p>
                <Link to={site==='company'? '/services/GIS':'/services/chemical-analysis'} className={`btn d-flex align-items-center justify-content-center`}><p className='text-body-secondary trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className='w-100 h-100 scale2 cardd text-center border border-light-subtle shadow-hover bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                <hr className='top-line position-absolute z-2 m-0 top-0 end-0 start-0 opacity-100' />
                <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                  <div className="carousel-inner">
                    <div className="carousel-item w-100 rem-15 overflow-hidden active">
                      <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany3_1:serviceLab1_1} alt="service" />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany3_2:serviceLab1_2} alt="service" />
                    </div>
                  </div>
                </div>
                <h4 className='py-xl-4 py-2 my-2 px-4'>{site==='company'? t('navSupervision'):t('navBuilding')}</h4>
                <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                <p className='p-4 my-2 text-secondary'>{site==='company'? t('service4CompanySentence'):t('service4LabSentence')}</p>
                <Link to={site==='company'? '/services/supervision':'/services/building'} className={`btn d-flex align-items-center justify-content-center`}><p className='text-body-secondary trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className='w-100 h-100 scale2 cardd text-center border border-light-subtle shadow-hover bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                <hr className='top-line position-absolute z-2 m-0 top-0 end-0 start-0 opacity-100' />
                <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                  <div className="carousel-inner">
                    <div className="carousel-item w-100 rem-15 overflow-hidden active">
                      <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany5_1:serviceLab5_1} alt="service" />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany5_2:serviceLab5_2} alt="service" />
                    </div>
                  </div>
                </div>
                <h4 className='py-xl-4 py-2 my-2 px-4'>{site==='company'? t('navInfrastructure'):t('navStudies')}</h4>
                <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                <p className='p-4 my-2 text-secondary'>{site==='company'? t('service5CompanySentence'):t('service5LabSentence')}</p>
                <Link to={site==='company'? '/services/infrastructure':'/services/soil-studies'} className={`btn d-flex align-items-center justify-content-center`}><p className='text-body-secondary trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className='w-100 h-100 scale2 cardd text-center border border-light-subtle shadow-hover bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                <hr className='top-line position-absolute z-2 m-0 top-0 end-0 start-0 opacity-100' />
                <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                  <div className="carousel-inner">
                    <div className="carousel-item w-100 rem-15 overflow-hidden active">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany6_1} alt="service" />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany6_2} alt="service" />
                    </div>
                  </div>
                </div>
                <h4 className='py-xl-4 py-2 my-2 px-4'>{t('navTransport')}</h4>
                <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                <p className='p-4 my-2 text-secondary'>{t('service6CompanySentence')}</p>
                <Link to={'/services/transport'} className={`btn d-flex align-items-center justify-content-center`}><p className='text-body-secondary trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className='w-100 h-100 scale2 cardd text-center border border-light-subtle shadow-hover bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                <hr className='top-line position-absolute z-2 m-0 top-0 end-0 start-0 opacity-100' />
                <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                  <div className="carousel-inner">
                    <div className="carousel-item w-100 rem-15 overflow-hidden active">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany7_1} alt="service" />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany7_2} alt="service" />
                    </div>
                  </div>
                </div>
                <h4 className='py-xl-4 py-2 my-2 px-4'>{t('navElectrical')}</h4>
                <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                <p className='p-4 my-2 text-secondary'>{t('service7CompanySentence')}</p>
                <Link to={'/services/electrical'} className={`btn d-flex align-items-center justify-content-center`}><p className='text-body-secondary trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className='w-100 h-100 scale2 cardd text-center border border-light-subtle shadow-hover bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                <hr className='top-line position-absolute z-2 m-0 top-0 end-0 start-0 opacity-100' />
                <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                  <div className="carousel-inner">
                    <div className="carousel-item w-100 rem-15 overflow-hidden active">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany8_1} alt="service" />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany8_2} alt="service" />
                    </div>
                  </div>
                </div>
                <h4 className='py-xl-4 py-2 my-2 px-4'>{t('navEvaluation')}</h4>
                <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                <p className='p-4 my-2 text-secondary'>{t('service8CompanySentence')}</p>
                <Link to={'/services/evaluation'} className={`btn d-flex align-items-center justify-content-center`}><p className='text-body-secondary trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
              </div>
            </div>
        </div>:
      <div className="row align-items-center mx-0 gx-0 gy-3 px-lg-0 px-md-5 px-3 py-5">
        <div className="col-xl-5 col-lg-4">
          <div className='container-lg w-95 px-xl-5 px-lg-3 px-0'>
            <h3 className='position-relative line2 pb-3'>{site==='company'? t('aboutHeaderEngineering'):t('aboutHeaderLaboratories')}</h3>
            <p className='pt-5 pb-3 text-secondary'>{site==='company'? t('aboutSentenceEngineering'):t('aboutSentenceLaboratories')}</p>
            <div className="d-flex my-3">
              <div className={`bg-main2 h-fit rounded-circle ${language==='en'? 'me-3':'ms-3'}`}>
                <i className="fa-solid fa-check small-circle-icon d-flex align-items-center justify-content-center text-white small"></i>
              </div>
              <p>{site==='company'? t('navArchitectural'):t('navGeotechnical')}</p>
            </div>
            <div className="d-flex my-3">
              <div className={`bg-main2 h-fit rounded-circle ${language==='en'? 'me-3':'ms-3'}`}>
                <i className="fa-solid fa-check small-circle-icon d-flex align-items-center justify-content-center text-white small"></i>
              </div>
              <p>{site==='company'? t('navHydrological'):t('navSoil')}</p>
            </div>
            <div className="d-flex my-3">
              <div className={`bg-main2 h-fit rounded-circle ${language==='en'? 'me-3':'ms-3'}`}>
                <i className="fa-solid fa-check small-circle-icon d-flex align-items-center justify-content-center text-white small"></i>
              </div>
              <p>{site==='company'? t('navGIS'):t('navChemical')}</p>
            </div>
            <div className="d-flex my-3">
              <div className={`bg-main2 h-fit rounded-circle ${language==='en'? 'me-3':'ms-3'}`}>
                <i className="fa-solid fa-check small-circle-icon d-flex align-items-center justify-content-center text-white small"></i>
              </div>
              <p>{site==='company'? t('navSupervision'):t('navBuilding')}</p>
            </div>
            <div className="d-flex my-3">
              <div className={`bg-main2 h-fit rounded-circle ${language==='en'? 'me-3':'ms-3'}`}>
                <i className="fa-solid fa-check small-circle-icon d-flex align-items-center justify-content-center text-white small"></i>
              </div>
              <p>{site==='company'? t('navInfrastructure'):t('navStudies')}</p>
            </div>
            {site==='company'? <>
            <div className="d-flex my-3">
              <div className={`bg-main2 h-fit rounded-circle ${language==='en'? 'me-3':'ms-3'}`}>
                <i className="fa-solid fa-check small-circle-icon d-flex align-items-center justify-content-center text-white small"></i>
              </div>
              <p>{t('navTransport')}</p>
            </div>
              <div className="d-flex my-3">
                <div className={`bg-main2 h-fit rounded-circle ${language==='en'? 'me-3':'ms-3'}`}>
                  <i className="fa-solid fa-check small-circle-icon d-flex align-items-center justify-content-center text-white small"></i>
                </div>
                <p>{t('navElectrical')}</p>
            </div>
              <div className="d-flex my-3">
                <div className={`bg-main2 h-fit rounded-circle ${language==='en'? 'me-3':'ms-3'}`}>
                  <i className="fa-solid fa-check small-circle-icon d-flex align-items-center justify-content-center text-white small"></i>
                </div>
                <p>{t('navEvaluation')}</p>
            </div>
            </>
            :''}
          </div>
        </div>
        <div className="col-xl-7 col-lg-8">
        <div>
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper pb-5 services-wrapper cursor-grab"
            breakpoints={{
              992: {
                slidesPerView: 'auto',
              },
              768: {
                slidesPerView: 2,
              },
            }}
            dir='rtl'
          >
              <SwiperSlide className='scale2 cardd text-center border border-light-subtle bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                <hr className='top-line position-absolute z-3 m-0 top-0 end-0 start-0 opacity-100' />
                <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                  <div className="carousel-inner">
                    <div className="carousel-item w-100 rem-15 overflow-hidden active">
                      <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany1_1:serviceLab3_1} alt="service" />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany1_2:serviceLab3_2} alt="service" />
                    </div>
                  </div>
                </div>
                <h4 className='py-xl-4 py-2 my-2 px-4'>{site==='company'? t('navArchitectural'):t('navGeotechnical')}</h4>
                <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                <p className='p-4 my-2 text-secondary'>{site==='company'? t('service1CompanySentence'):t('service1LabSentence')}</p>
                <Link to={site==='company'? '/services/architectural':'/services/geotechnical'} className={`btn d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><p className='text-body-secondary trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
              </SwiperSlide>
              <SwiperSlide className='scale2 cardd text-center border border-light-subtle bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                <hr className='top-line position-absolute z-3 m-0 top-0 end-0 start-0 opacity-100' />
                <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                  <div className="carousel-inner">
                    <div className="carousel-item w-100 rem-15 overflow-hidden active">
                      <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany2_1:serviceLab2_2} alt="service" />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany2_2:serviceLab2_1} alt="service" />
                    </div>
                  </div>
                </div>
                <h4 className='py-xl-4 py-2 my-2 px-4'>{site==='company'? t('navHydrological'):t('navSoil')}</h4>
                <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                <p className='p-4 my-2 text-secondary'>{site==='company'? t('service2CompanySentence'):t('service3LabSentence')}</p>
                <Link to={site==='company'? '/services/hydrological':'/services/soil'} className={`btn d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><p className='text-body-secondary trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
              </SwiperSlide>
              <SwiperSlide className='scale2 cardd text-center border border-light-subtle bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                <hr className='top-line position-absolute z-3 m-0 top-0 end-0 start-0 opacity-100' />
                <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                  <div className="carousel-inner">
                    <div className="carousel-item w-100 rem-15 overflow-hidden active">
                      <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany4_1:serviceLab4_1} alt={site==='company'? t('navSupervision'):t('navChemical')} />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany4_2:serviceLab4_2} alt={site==='company'? t('navSupervision'):t('navChemical')} />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={serviceLab4_3} alt={site==='company'? t('navSupervision'):t('navChemical')} />
                    </div>
                  </div>
                </div>
                <h4 className='py-xl-4 py-2 my-2 px-4'>{site==='company'? t('navGIS'):t('navChemical')}</h4>
                <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                <p className='p-4 my-2 text-secondary'>{site==='company'? t('service3CompanySentence'):t('service2LabSentence')}</p>
                <Link to={site==='company'? '/services/GIS':'/services/chemical-analysis'} className={`btn d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><p className='text-body-secondary trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
              </SwiperSlide>
              <SwiperSlide className='scale2 cardd text-center border border-light-subtle bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                <hr className='top-line position-absolute z-3 m-0 top-0 end-0 start-0 opacity-100' />
                <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                  <div className="carousel-inner">
                    <div className="carousel-item w-100 rem-15 overflow-hidden active">
                      <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany3_1:serviceLab1_1} alt="service" />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany3_2:serviceLab1_2} alt="service" />
                    </div>
                  </div>
                </div>
                <h4 className='py-xl-4 py-2 my-2 px-4'>{site==='company'? t('navSupervision'):t('navBuilding')}</h4>
                <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                <p className='p-4 my-2 text-secondary'>{site==='company'? t('service4CompanySentence'):t('service4LabSentence')}</p>
                <Link to={site==='company'? '/services/supervision':'/services/building'} className={`btn d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><p className='text-body-secondary trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
              </SwiperSlide>
              <SwiperSlide className='scale2 cardd text-center border border-light-subtle bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                <hr className='top-line position-absolute z-3 m-0 top-0 end-0 start-0 opacity-100' />
                <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                  <div className="carousel-inner">
                    <div className="carousel-item w-100 rem-15 overflow-hidden active">
                      <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany5_1:serviceLab5_1} alt="service" />
                    </div>
                    <div className="carousel-item w-100 rem-15 overflow-hidden">
                      <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany5_2:serviceLab5_2} alt="service" />
                    </div>
                  </div>
                </div>
                <h4 className='py-xl-4 py-2 my-2 px-4'>{site==='company'? t('navInfrastructure'):t('navStudies')}</h4>
                <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                <p className='p-4 my-2 text-secondary'>{site==='company'? t('service5CompanySentence'):t('service5LabSentence')}</p>
                <Link to={site==='company'? '/services/infrastructure':'/services/soil-studies'} className={`btn d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><p className='text-body-secondary trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
              </SwiperSlide>
              {site==='company'? <>
                <SwiperSlide className='scale2 cardd text-center border border-light-subtle bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                    <hr className='top-line position-absolute z-3 m-0 top-0 end-0 start-0 opacity-100' />
                    <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                      <div className="carousel-inner">
                        <div className="carousel-item w-100 rem-15 overflow-hidden active">
                          <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany6_1} alt="service" />
                        </div>
                        <div className="carousel-item w-100 rem-15 overflow-hidden">
                          <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany6_2} alt="service" />
                        </div>
                      </div>
                    </div>
                    <h4 className='py-xl-4 py-2 my-2 px-4'>{t('navTransport')}</h4>
                    <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                    <p className='p-4 my-2 text-secondary'>{t('service6CompanySentence')}</p>
                    <Link to={'/services/transport'} className={`btn d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><p className='text-body-secondary trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
                </SwiperSlide>
                <SwiperSlide className='scale2 cardd text-center border border-light-subtle bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                  <hr className='top-line position-absolute z-3 m-0 top-0 end-0 start-0 opacity-100' />
                  <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                    <div className="carousel-inner">
                      <div className="carousel-item w-100 rem-15 overflow-hidden active">
                        <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany7_1} alt="service" />
                      </div>
                      <div className="carousel-item w-100 rem-15 overflow-hidden">
                        <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany7_2} alt="service" />
                      </div>
                    </div>
                  </div>
                  <h4 className='py-xl-4 py-2 my-2 px-4'>{t('navElectrical')}</h4>
                  <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                  <p className='p-4 my-2 text-secondary'>{t('service7CompanySentence')}</p>
                  <Link to={'/services/electrical'} className={`btn d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><p className='text-body-secondary trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
              </SwiperSlide>
                <SwiperSlide className='scale2 cardd text-center border border-light-subtle bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
                  <hr className='top-line position-absolute z-3 m-0 top-0 end-0 start-0 opacity-100' />
                  <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                    <div className="carousel-inner">
                      <div className="carousel-item w-100 rem-15 overflow-hidden active">
                        <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany8_1} alt="service" />
                      </div>
                      <div className="carousel-item w-100 rem-15 overflow-hidden">
                        <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany8_2} alt="service" />
                      </div>
                    </div>
                  </div>
                  <h4 className='py-xl-4 py-2 my-2 px-4'>{t('navEvaluation')}</h4>
                  <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
                  <p className='p-4 my-2 text-secondary'>{t('service8CompanySentence')}</p>
                  <Link to={'/services/evaluation'} className={`btn d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><p className='text-body-secondary trans3 small text-hover fw-bold'>{t('readMore')}</p><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
              </SwiperSlide>
              </>:''}
          </Swiper>
        </div>
        </div>
      </div>}
    </div>
  </>
}
