import React, { useContext, useEffect } from 'react'
import './assets/App.css'
import { RouterProvider } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { LangContext } from './components/context/LangContext.js'
import routes from './routes.js'
// import { DesiredPageContext } from './components/context/DesiredPageContext.js'


export default function App() {

  let {language}= useContext(LangContext)
  const site = sessionStorage.getItem('desiredPage')

  const whatPage = ()=> {
    if(site === 'company'){
      document.documentElement.classList.add('company')      
    }
    else if(site === 'labs'){
      document.documentElement.classList.remove('company')
    }
  }

  useEffect(() => {
      whatPage()
  },[site])

  return <>
  <Helmet>
    <meta charSet="utf-8" />
    <title>{language === 'en' ? 'AlMekyal AlSaudi' : 'المكيال السعودي'}</title>
  </Helmet>
  <RouterProvider router={routes}></RouterProvider>
  </>
}