import React, { useContext, useEffect, useState } from 'react'
import style from './Quality.module.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../context/LangContext'
import iso1 from '../../assets/images/quality/download.webp'
import iso2 from '../../assets/images/quality/images.webp'
import iso3 from '../../assets/images/quality/snapedit_1736670961138.webp'
import iso4 from '../../assets/images/quality/1675598209557.webp'
import sacLogo from '../../assets/images/quality/Sac_logo.webp'
import certificate1 from '../../assets/images/quality/ISO_9001[1].webp'
import certificate2 from '../../assets/images/quality/ISO_45001[1].webp'
import certificate3 from '../../assets/images/quality/ISO_14001[1].webp'
import certificate4Riyadh from '../../assets/images/quality/شهادة_المركز_السعودي_للاعتماد_-_مختبر_الرياض[1].webp'
import certificate4Khobar from '../../assets/images/quality/شهادة_المركز_السعودي_ساك_-_مختبر_الخبر[1].webp'

export default function Quality() {
  
    const { t } = useTranslation()
    let {language}= useContext(LangContext)
    const site = sessionStorage.getItem('desiredPage')
    const [certificate, setCertificate] = useState(null)

    const close = () =>{
        setCertificate(null)
    }

  return <>
    <div dir={language==='en'? '':'rtl'}>
                                        {/* header */}
      <div className="header-img py-5">
        <div className='w-90 mx-auto d-flex h-100 flex-column align-items-center justify-content-center'>
          <p className='position-relative line text-center fs-2 fw-bold text-black d-flex align-items-center justify-content-center pb-3 mt-3 mb-5'>{t('qualityHeader')}</p>
          <div className='d-flex'>
            <Link to={'/'}className='text-hover trans3'>{t('navHome')}</Link>
            <span className='text-black px-2'>/</span>
            <p className='text-secondary'>{site==='company'? t('navSafety'):t('navQualityLabs')}</p>
          </div>
        </div>
      </div>  
      
                                        {/* content */}
      <div className="container-lg w-90 mx-auto">
        <div className='pt-sm-5 pt-3 mt-3 fs-5 mb-5 fw-medium'>
          <h3 className='text-main2 position-relative line3 text-center w-fit fs-3 fw-bold mb-5'>{t('quality.quality')}</h3>
          <p>{t('quality.text1')}<br />{t('quality.text2')}</p>
          <div className="row align-items-center justify-content-center gy-4 mx-0 pt-3">
            <div className="col-md-10 col-sm-9">
              <div>
                <p className='fw-normal'>
                  <i className={`fa-solid fa-check text-main ${language==='en'? 'pe-2':'ps-2'}`}></i>
                  <span className='fw-bold text-decoration-underline'>{t('quality.iso1Name')}</span>{t('quality.iso1Sentence')}
                </p>
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-5">
              <div>
                <img loading='lazy' className='w-100' src={iso1} alt="ISO 9001:2015" />
              </div>
            </div>
            <div className="col-md-10 col-sm-9">
              <div>
                <p className='fw-normal'>
                  <i className={`fa-solid fa-check text-main ${language==='en'? 'pe-2':'ps-2'}`}></i>
                  <span className='fw-bold text-decoration-underline'>{t('quality.iso2Name')}</span>{t('quality.iso2Sentence')}
                </p>
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-5">
              <div>
                <img loading='lazy' className='w-100' src={iso2} alt="ISO45001-2018" />
              </div>
            </div>
            <div className="col-md-10 col-sm-9">
              <div>
                <p className='fw-normal'>
                  <i className={`fa-solid fa-check text-main ${language==='en'? 'pe-2':'ps-2'}`}></i>
                  <span className='fw-bold text-decoration-underline'>{t('quality.iso3Name')}</span>{t('quality.iso3Sentence')}
                </p>
              </div>
            </div>
            <div className="col-md-2 col-sm-3 col-5">
              <div>
                <img loading='lazy' className='w-100' src={iso3} alt="ISO 14001" />
              </div>
            </div>
          </div>
          {site==='company'? '':<>
            <div className='py-4'>
              <p>
                <i className={`fa-solid fa-check text-main ${language==='en'? 'pe-2':'ps-2'}`}></i>
                <span className='fw-bold text-decoration-underline'>{t('quality.iso4Name')}</span>
              </p>
              <p className='fw-normal'>
                <i className='fa-solid fa-check opacity-0 d-sm-inline d-none'></i>
                <span className='fw-bold text-decoration-underline'>{t('quality.iso4Text')}</span>{t('quality.iso4Sentence')}
              </p>
            </div>
            <div className="row align-items-center justify-content-around gx-5">
              <div className="col-sm-5">
                <div>
                  <img loading='lazy' className='w-100' src={iso4} alt="ISO 17025 -2017" />
                </div>
              </div>
              <div className="col-sm-4">
                <div>
                  <img loading='lazy' className='w-100' src={sacLogo} alt="sac logo" />
                </div>
              </div>
            </div>
          </>}
        </div>
      </div> 
      <div className="bg-light">
        <div className="container-lg w-90 mx-auto py-5 fs-5 lh-lg fw-medium d-flex flex-column">
          <p className='text-main2 position-relative line3 text-center w-fit fs-3 fw-bold mb-5'>{t('quality.qualitySystem')}</p>
          <Link to={'/FR-QM-04_QUALITY OBJECTIVE_2024.pdf'} target='_blank' className='text-hover trans3'>
            <i className={`fa-solid fa-check text-main ${language==='en'? 'pe-2':'ps-2'}`}></i>
            <span>{t('quality.objectives')}</span>
          </Link>
          <Link to={language==='en'? '/FR-QM-01_QUALITY POLICY - en.pdf':'/FR-QM-01_QUALITY POLICY.pdf'} target='_blank' className='text-hover trans3'>
            <i className={`fa-solid fa-check text-main ${language==='en'? 'pe-2':'ps-2'}`}></i>
            <span>{t('quality.policy')}</span>
          </Link>
          <Link to={'/FR-QM-03_CODE OF ETHICS_2024.pdf'} target='_blank' className='text-hover trans3'>
            <i className={`fa-solid fa-check text-main ${language==='en'? 'pe-2':'ps-2'}`}></i>
            <span>{t('quality.code')}</span>
          </Link>
        </div>
      </div> 

      <div className="container-lg w-90 mx-auto py-5 fs-5 fw-semibold">
          <p className='text-main2 position-relative line3 text-center w-fit fs-3 fw-bold mb-5'>{t('quality.certificates')}</p>
          <div className="row g-md-5 gy-4">
            <div className="col-md-4">
              <button onClick={ () =>setCertificate(certificate1)} className='btn btn-main2 px-2 py-4 w-100 h-100'>
                <i className={`fa-solid fa-check ${language==='en'? 'pe-2':'ps-2'}`}></i>
                <span>{t('quality.certificate1')}</span>
              </button>
            </div>
            <div className="col-md-4">
              <button onClick={ () =>setCertificate(certificate2)} className='btn btn-main2 px-2 py-4 w-100 h-100'>
                <i className={`fa-solid fa-check ${language==='en'? 'pe-2':'ps-2'}`}></i>
                <span>{t('quality.certificate2')}</span>
              </button>
            </div>
            <div className="col-md-4">
              <button onClick={ () =>setCertificate(certificate3)} className='btn btn-main2 px-2 py-4 w-100 h-100'>
                <i className={`fa-solid fa-check ${language==='en'? 'pe-2':'ps-2'}`}></i>
                <span>{t('quality.certificate3')}</span>
              </button>
            </div>
          </div>
          {site==='company'? '':<>
            <p className='text-black fw-bold pt-5'>
              <i className={`fa-solid fa-check ${language==='en'? 'pe-2':'ps-2'}`}></i>
              <span>{t('quality.certificateSac.text')}</span>
            </p>
            <div className="row g-md-5 gy-4 pt-3">
              <div className="col-md-4">
                <button onClick={ () =>setCertificate(certificate4Riyadh)} className='btn btn-main2 px-2 py-4 w-100 h-100'>{t('quality.certificateSac.riyadh')}</button>
              </div>
              <div className="col-md-4">
                <button onClick={ () =>setCertificate(certificate4Khobar)} className='btn btn-main2 px-2 py-4 w-100 h-100'>{t('quality.certificateSac.khobar')}</button>
              </div>
              <div className="col-md-4">
                <button className='btn btn-main2 px-2 py-4 w-100 h-100'>{t('quality.certificateSac.madinah')}</button>
              </div>
            </div>
          </>}
        </div>
    </div>  
    {certificate===null? '':<div className={`position-fixed py-3 top-0 bottom-0 start-0 end-0 bg-black-70 ${style.zhightest}`}>
      <div className="container-lg mx-auto px-lg-0 px-md-3 px-0 h-100">
        <button type="button" onClick={close} class={`btn-close btn-close-white shadow-none position-absolute top-0 p-3 ${language==='en'? 'start-0':'end-0'}`} aria-label="Close"></button>
        <div className='row align-items-center justify-content-center h-100'>
          <div className="col-xl-7 col-lg-8 col-md-9 col-11 h-100 pt-4 pb-2">
            <img className='w-100 h-100' src={certificate} alt="certificate" />
          </div>
        </div>
      </div>
    </div>}
  </>
}
