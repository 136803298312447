import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import project1_1 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع دراسة التربة لمخططات المحور الجنوبي/03.webp'
import project1_2 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع دراسة التربة لمخططات المحور الجنوبي/اختبار test pit.webp'
import project1_3 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع دراسة التربة لمخططات المحور الجنوبي/حفارات 8.webp'
import project2_1 from '../assets/images/صور صفحة المشاريع والأعمال/صور مشروع الوديان/حفارات 5.webp'
import project2_2 from '../assets/images/صور صفحة المشاريع والأعمال/صور مشروع الوديان/حفارات 6.webp'
import project2_3 from '../assets/images/صور صفحة المشاريع والأعمال/صور مشروع الوديان/مشروع الوديان.webp'
import project3_1 from '../assets/images/صور صفحة المشاريع والأعمال/دراسة التربة للمشاريع التعليمية بمدينة جدة/00108.webp'
import project3_2 from '../assets/images/صور صفحة المشاريع والأعمال/دراسة التربة للمشاريع التعليمية بمدينة جدة/مشروع جدة 1.webp'
import project3_3 from '../assets/images/صور صفحة المشاريع والأعمال/دراسة التربة للمشاريع التعليمية بمدينة جدة/مشروع جدة.webp'
import project4_1 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع أعمال اختبارات وفحص التربة سدرة 2/اختبار قرص التحميل في مشروع سدرة 2.webp'
import project4_2 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع أعمال اختبارات وفحص التربة سدرة 2/روشن - سدرة 2.webp'
import project4_3 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع أعمال اختبارات وفحص التربة سدرة 2/روشن 2- سدرة 2.webp'
import project5_1 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع اختبارات المواد في مدينة الجبيل الصناعية/اختبارات معملية 2.webp'
import project5_2 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع اختبارات المواد في مدينة الجبيل الصناعية/الجبيل1 (1).webp'
import project5_3 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع اختبارات المواد في مدينة الجبيل الصناعية/الجبيل1 (2).webp'
import project6_1 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع اختبارات التربة والمواد واعمال ضبط جودة لمشاريع البنية التحتية/S4.webp'
import project6_2 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع اختبارات التربة والمواد واعمال ضبط جودة لمشاريع البنية التحتية/أعمال ضبط جودة لأحد مشاريع البنية التحتية.webp'
import project6_3 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع اختبارات التربة والمواد واعمال ضبط جودة لمشاريع البنية التحتية/حرض 3.webp'
import project7_1 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع اختبارات دراسة وتقييم الحالة الإنشائية لقصر الملك خالد والمباني/احتبارات -قصر الملك خالد.webp'
import project7_2 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع اختبارات دراسة وتقييم الحالة الإنشائية لقصر الملك خالد والمباني/احتبارات 3 - قصر الملك خالد.webp'
import project7_3 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع اختبارات دراسة وتقييم الحالة الإنشائية لقصر الملك خالد والمباني/اختبارات 2 - قصر الملك خالد.webp'
import project8_1 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع اختبارات دراسة الهيكل الإنشائي في تبوك/ألتراسونك.webp'
import project8_2 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع اختبارات دراسة الهيكل الإنشائي في تبوك/كور اسفلت2.webp'
import project8_3 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع اختبارات دراسة الهيكل الإنشائي في تبوك/مشروع تبوك صورة 4.webp'
import project9_1 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع تقييم مبنى الهيئة العامة للمياه  التحلية-/عينات مشروع مبنى التحلية 2.webp'
import project9_2 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع تقييم مبنى الهيئة العامة للمياه  التحلية-/مبنى التحلية.webp'
import project9_3 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع تقييم مبنى الهيئة العامة للمياه  التحلية-/مشروع مبنى التحلية.webp'
import projectDesign1_1 from '../assets/images/services/الاستشارات/architectural/1/1/15.webp'
import projectDesign1_2 from '../assets/images/services/الاستشارات/architectural/1/1/10.webp'
import projectDesign1_3 from '../assets/images/services/الاستشارات/architectural/1/1/16.webp'
import projectDesign1_4 from '../assets/images/services/الاستشارات/architectural/1/1/5.webp'
import projectDesign2_1 from '../assets/images/services/الاستشارات/architectural/1/2/ELEVATION 1.webp'
import projectDesign2_2 from '../assets/images/services/الاستشارات/architectural/1/2/ELEVATION 2.webp'
import projectDesign3_1 from '../assets/images/services/الاستشارات/architectural/1/3/9.webp'
import projectDesign3_2 from '../assets/images/services/الاستشارات/architectural/1/3/1.webp'
import projectDesign3_3 from '../assets/images/services/الاستشارات/architectural/1/3/اخر تعديل_2 - Ext Main View.webp'
import projectDesign3_4 from '../assets/images/services/الاستشارات/architectural/1/3/تعديل_22 - Photo.webp'
import projectDesign4_1 from '../assets/images/services/الاستشارات/architectural/1/4/مبنى الحياة الفطرية_3 - Photo.webp'
import projectDesign4_2 from '../assets/images/services/الاستشارات/architectural/1/4/مبنى الحياة الفطرية 3_25 - Photo.webp'
import projectDesign4_3 from '../assets/images/services/الاستشارات/architectural/1/4/مبنى الحياة الفطرية_2 - Photo.webp'
import projectDesign4_4 from '../assets/images/services/الاستشارات/architectural/1/4/واجهة.webp'
import projectDesign5_1 from '../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 2/00001.webp'
import projectDesign6_1 from '../assets/images/services/الاستشارات/architectural/1/6/فيلا 3/2.webp'
import projectUrban1_1 from '../assets/images/services/الاستشارات/architectural/2/1/المخطط المعتمد نهائي 3943 بتاريخ 19-17-2023-Model.webp'
import projectUrban2_1 from '../assets/images/services/الاستشارات/architectural/2/2/مخطط ركاز حي السعادة المعدل نهااائي-Model.webp'
import projectUrban3_1 from '../assets/images/services/الاستشارات/architectural/2/3/نهائي مخطط رقم 3978 بتاريخ 04 - 08 - 2024 (2)-Model.webp'
import projectUrban4_1 from '../assets/images/services/الاستشارات/architectural/2/4/ابوبندر.webp'
import projectUrban5_1 from '../assets/images/services/الاستشارات/architectural/2/5/تصميم حضري - حدائق هارد اسكيب.webp'
import projectUrban5_2 from '../assets/images/services/الاستشارات/architectural/2/5/تصميم حضري - حدائق-Layout1.webp'
import projectUrban5_3 from '../assets/images/services/الاستشارات/architectural/2/5/مخطط الجديدة نهائي-Model.webp'
import projectHydro1_1 from '../assets/images/services/الاستشارات/hydrological/projects/1/elahmar.webp'
import projectHydro1_2 from '../assets/images/services/الاستشارات/hydrological/projects/1/helw.webp'
import projectHydro1_3 from '../assets/images/services/الاستشارات/hydrological/projects/1/log2.webp'
import projectHydro2_1 from '../assets/images/services/الاستشارات/hydrological/projects/2/PHOTO-2023-06-18-15-39-08.webp'
import projectHydro2_2 from '../assets/images/services/الاستشارات/hydrological/projects/2/image00004.webp'
import projectHydro3_1 from '../assets/images/services/الاستشارات/hydrological/projects/3/01.webp'
import projectHydro3_2 from '../assets/images/services/الاستشارات/hydrological/projects/3/02.webp'
import projectHydro3_3 from '../assets/images/services/الاستشارات/hydrological/projects/3/03.webp'
import projectHydro3_4 from '../assets/images/services/الاستشارات/hydrological/projects/3/04.webp'
import projectHydro4_1 from '../assets/images/services/الاستشارات/hydrological/projects/4/Pr-Sloution.webp'
import projectHydro4_2 from '../assets/images/services/الاستشارات/hydrological/projects/4/210.webp'
import projectHydro4_3 from '../assets/images/services/الاستشارات/hydrological/projects/4/43.webp'
import projectHydro4_4 from '../assets/images/services/الاستشارات/hydrological/projects/4/97.webp'
import projectHydro5_1 from '../assets/images/services/الاستشارات/hydrological/projects/5/ااااااااااااااااا_page-0046.webp'
import projectHydro5_2 from '../assets/images/services/الاستشارات/hydrological/projects/5/ااااااااااااااااا_page-0047.webp'
import projectHydro5_3 from '../assets/images/services/الاستشارات/hydrological/projects/5/ااااااااااااااااا_page-0051.webp'
import projectGis1_1 from '../assets/images/services/الاستشارات/gis/المشاريع - الأعمال المساحية/01- أعمال الرفع المساحي لوكالة الثروة السمكية لوكالة الزراعة بمنطقة مكه المكرمة/WhatsApp Image 2023-04-01 at 15.30.45.webp'
import projectGis1_2 from '../assets/images/services/الاستشارات/gis/المشاريع - الأعمال المساحية/01- أعمال الرفع المساحي لوكالة الثروة السمكية لوكالة الزراعة بمنطقة مكه المكرمة/WhatsApp Image 2024-09-30 at 13.20.34_3a0b641b.webp'
import projectGis2_1 from '../assets/images/services/الاستشارات/gis/المشاريع - الأعمال المساحية/02- أعمال الرفع المساحي لمركز القتال بالحرس الوطني/WhatsApp Image 2023-04-01 at 15.50.49.webp'
import projectGis2_2 from '../assets/images/services/الاستشارات/gis/المشاريع - الأعمال المساحية/02- أعمال الرفع المساحي لمركز القتال بالحرس الوطني/100100001000.webp'
import projectGis2_3 from '../assets/images/services/الاستشارات/gis/المشاريع - الأعمال المساحية/02- أعمال الرفع المساحي لمركز القتال بالحرس الوطني/WhatsApp Image 2023-04-01 at 15.26.58.webp'
import projectGis2_4 from '../assets/images/services/الاستشارات/gis/المشاريع - الأعمال المساحية/02- أعمال الرفع المساحي لمركز القتال بالحرس الوطني/WhatsApp Image 2023-04-11 at 10.14.32.webp'
import projectGis3_1 from '../assets/images/services/الاستشارات/gis/المشاريع - الأعمال المساحية/03- أعمال التخطيط والرفع المساحي لأعمال خاصة بوزارة البلديات والإسكان/IMG-20230420-WA0097.webp'
import projectGis3_2 from '../assets/images/services/الاستشارات/gis/المشاريع - الأعمال المساحية/03- أعمال التخطيط والرفع المساحي لأعمال خاصة بوزارة البلديات والإسكان/WhatsApp Image 2023-04-11 at 10.17.56.webp'
import projectSuper1_1 from '../assets/images/services/الاستشارات/supervision/المشاريع - الإشراف على التنفيذ/1/IMG-20230802-WA0103.webp'
import projectSuper1_2 from '../assets/images/services/الاستشارات/supervision/المشاريع - الإشراف على التنفيذ/1/IMG-20230802-WA0068.webp'
import projectSuper1_3 from '../assets/images/services/الاستشارات/supervision/المشاريع - الإشراف على التنفيذ/1/IMG-20230802-WA0094.webp'
import projectSuper1_4 from '../assets/images/services/الاستشارات/supervision/المشاريع - الإشراف على التنفيذ/1/WhatsApp Image 2023-10-05 at 9.51.18 AM (1).webp'
// import projectSuper2_ from '../assets/images/services/الاستشارات/supervision/المشاريع - الإشراف على التنفيذ/'
import projectSuper3_1 from '../assets/images/services/الاستشارات/supervision/المشاريع - الإشراف على التنفيذ/3/IMG-20241121-WA0042.webp'
import projectSuper3_2 from '../assets/images/services/الاستشارات/supervision/المشاريع - الإشراف على التنفيذ/3/IMG-20241121-WA0039.webp'
import projectSuper3_3 from '../assets/images/services/الاستشارات/supervision/المشاريع - الإشراف على التنفيذ/3/IMG-20241121-WA0050.webp'
import projectSuper3_4 from '../assets/images/services/الاستشارات/supervision/المشاريع - الإشراف على التنفيذ/3/WhatsApp Image 2024-09-30 at 11.06.58_cc08f816.webp'
// import projectSuper4_ from '../assets/images/services/الاستشارات/supervision/المشاريع - الإشراف على التنفيذ/'
// import projectSuper5_ from '../assets/images/services/الاستشارات/supervision/المشاريع - الإشراف على التنفيذ/'
// import projectSuper6_ from '../assets/images/services/الاستشارات/supervision/المشاريع - الإشراف على التنفيذ/'
import projectSuper7_1 from '../assets/images/services/الاستشارات/supervision/المشاريع - الإشراف على التنفيذ/7/اعتماد المخططات لخزان الغويري_Page_01.webp'
import projectSuper7_2 from '../assets/images/services/الاستشارات/supervision/المشاريع - الإشراف على التنفيذ/7/اعتماد المخططات لخزان الغويري_Page_03.webp'
import projectSuper7_3 from '../assets/images/services/الاستشارات/supervision/المشاريع - الإشراف على التنفيذ/7/اعتماد المخططات لخزان الغويري_Page_04.webp'
import projectSuper7_4 from '../assets/images/services/الاستشارات/supervision/المشاريع - الإشراف على التنفيذ/7/اعتماد المخططات لخزان الغويري_Page_10.webp'
import projectInfra1_1 from '../assets/images/services/الاستشارات/infrastructure/1/WhatsApp Image 2024-03-05 at 7.39.58 AM.webp'
import projectInfra1_2 from '../assets/images/services/الاستشارات/infrastructure/1/WhatsApp Image 2024-03-05 at 7.39.59 AM (1).webp'
import projectInfra1_3 from '../assets/images/services/الاستشارات/infrastructure/1/WhatsApp Image 2024-03-05 at 7.40.00 AM (1).webp'
import projectInfra1_4 from '../assets/images/services/الاستشارات/infrastructure/1/WhatsApp Image 2024-03-05 at 7.40.00 AM.webp'
import projectInfra2_1 from '../assets/images/services/الاستشارات/infrastructure/2/حرمة-Main.webp'
import projectInfra3_1 from '../assets/images/services/الاستشارات/infrastructure/3/100.webp'
import projectInfra4_1 from '../assets/images/services/الاستشارات/infrastructure/4/العنود.webp'
import projectInfra4_2 from '../assets/images/services/الاستشارات/infrastructure/4/بي.webp'
import projectInfra5_1 from '../assets/images/services/الاستشارات/infrastructure/5/5.webp'
import projectInfra5_2 from '../assets/images/services/الاستشارات/infrastructure/5/7.webp'
import projectInfra5_3 from '../assets/images/services/الاستشارات/infrastructure/5/8.webp'
import projectInfra6_1 from '../assets/images/services/الاستشارات/infrastructure/6/10.webp'
import projectInfra6_2 from '../assets/images/services/الاستشارات/infrastructure/6/5.webp'
import projectInfra6_3 from '../assets/images/services/الاستشارات/infrastructure/6/69.webp'
import projectInfra6_4 from '../assets/images/services/الاستشارات/infrastructure/6/71.webp'
import projectInfra7_1 from '../assets/images/services/الاستشارات/infrastructure/7/4.webp'
import projectTrans1_1 from '../assets/images/services/الاستشارات/transport/1/21.webp'
import projectTrans1_2 from '../assets/images/services/الاستشارات/transport/1/22.webp'
import projectTrans1_3 from '../assets/images/services/الاستشارات/transport/1/226.webp'
import projectTrans1_4 from '../assets/images/services/الاستشارات/transport/1/25.webp'
import projectTrans2_1 from '../assets/images/services/الاستشارات/transport/2/124.webp'
import projectTrans2_2 from '../assets/images/services/الاستشارات/transport/2/238.webp'
import projectTrans2_3 from '../assets/images/services/الاستشارات/transport/2/252.webp'
import projectTrans2_4 from '../assets/images/services/الاستشارات/transport/2/5.webp'
import projectTrans3_1 from '../assets/images/services/الاستشارات/transport/3/110.webp'
import projectTrans3_2 from '../assets/images/services/الاستشارات/transport/3/5.webp'
import projectTrans3_3 from '../assets/images/services/الاستشارات/transport/3/6.webp'
import projectTrans3_4 from '../assets/images/services/الاستشارات/transport/3/60.webp'
import projectElectrical1_1 from '../assets/images/services/الاستشارات/electrical/1/WhatsApp Image 2024-07-28 at 8.49.54 PM.webp'
import projectElectrical1_2 from '../assets/images/services/الاستشارات/electrical/1/WhatsApp Image 2024-07-28 at 8.51.18 PM.webp'
import projectElectrical1_3 from '../assets/images/services/الاستشارات/electrical/1/WhatsApp Image 2024-07-28 at 8.53.11 PM.webp'
import projectElectrical1_4 from '../assets/images/services/الاستشارات/electrical/1/WhatsApp Image 2024-09-22 at 16.51.35_4b6a3ffc.webp'
import projectEvaluation1_1 from '../assets/images/services/الاستشارات/evaluation/1/020.webp'
import projectEvaluation1_2 from '../assets/images/services/الاستشارات/evaluation/1/WhatsApp Image 2022-12-03 at 11.09.24 AM (1).webp'
import projectEvaluation1_3 from '../assets/images/services/الاستشارات/evaluation/1/WhatsApp Image 2022-12-03 at 11.09.40 AM.webp'
import projectEvaluation1_4 from '../assets/images/services/الاستشارات/evaluation/1/WhatsApp Image 2022-12-03 at 11.10.18 AM.webp'
import projectEvaluation2_1 from '../assets/images/services/الاستشارات/evaluation/2/مشروع تبوك صورة 4.webp'
import projectEvaluation2_2 from '../assets/images/services/الاستشارات/evaluation/2/WhatsApp Image 2024-10-02 at 17.51.43_88522000.webp'
import projectEvaluation2_3 from '../assets/images/services/الاستشارات/evaluation/2/مشروع تبوك صورة 1.webp'
import projectEvaluation2_4 from '../assets/images/services/الاستشارات/evaluation/2/مشروع تبوك صورة 2.webp'
import projectEvaluation3_1 from '../assets/images/services/الاستشارات/evaluation/3/WhatsApp Image 2022-06-23 at 1.09.57 PM.webp'
import projectEvaluation3_2 from '../assets/images/services/الاستشارات/evaluation/3/WhatsApp Image 2022-06-15 at 1.12.36 PM (5).webp'
import projectEvaluation3_3 from '../assets/images/services/الاستشارات/evaluation/3/photo_2024-07-25_11-10-56.webp'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../components/context/LangContext'


export default function Projects() {

  const { t } = useTranslation()
  let {language}= useContext(LangContext)
  const site = sessionStorage.getItem('desiredPage')
  const [activeLink, setActiveLink] = useState('all')
  const location = useLocation()

  useEffect(() => {
    const pathParts = location.pathname.split('/')
    const currentProjects = pathParts[pathParts.length - 1]
    setActiveLink(currentProjects || 'all')
  }, [location])

  const projectsName = [
    { id: 'all', name: t("allProjects") },
    { id: 'geotechnicalProjects', name: t("navGeotechnicalProject")},
    { id: 'qualityProjects', name: t("navQualityProject")},
    { id: 'structuralProjects', name: t("navBuildingProject")},
  ]
  const projectsName2 = [
    { id: 'all', name: t("allProjects") },
    { id: 'architecturalProjects', name: t("navArchitectural")},
    { id: 'hydrologicalProjects', name: t("navHydrological")},
    { id: 'gisProjects', name: t("navGIS")},
    { id: 'supervisionProjects', name: t("navSupervision")},
    { id: 'infrastructureProjects', name: t("navInfrastructure")},
    { id: 'transportProjects', name: t("navTransport")},
    { id: 'electricalProjects', name: t("navElectrical")},
    { id: 'evaluationProjects', name: t("navEvaluation")},
  ]

  const companyProjects = [
    {
      id: '1',
      name:'تصميم مبنى الدفاع المدني بالمدينة المنورة',
      category: { id: 'architecturalProjects', name: t("navArchitectural")},
      customer: 'الدفاع المدني بالمدينة المنورة',
      text: '',
      text2: '',
      workScope: 'تصميم جميع المخططات المعمارية والإنشائية والإلكتروميكانيكية واستخراج رخصة بناء',
      workScopePoints: [],
      points: [],
      executionYear:'2019',
      startDate:'',
      images: [projectDesign1_1,projectDesign1_2,projectDesign1_3,projectDesign1_4]
    },
    {
      id: '2',
      name:'إعادة تصميم واجهات مباني الجمارك بمعبر البطحاء',
      category: { id: 'architecturalProjects', name: t("navArchitectural")},
      customer: 'هيئة الزكاة والضريبة والجمارك',
      text: '',
      text2: '',
      workScope: 'إعادة تصميم واجهات لمباني قائمة عدد 18 مبنى متعددة الأدوار بالإضافة إلي أعمال الرفع المساحي',
      workScopePoints: [],
      points: [],
      executionYear:'2021',
      startDate:'',
      images: [projectDesign2_1,projectDesign2_2]
    },
    {
      id: '3',
      name:'تصميم جامع الأمير سلطان بن عبد العزيز آل سعود وملحقاته ',
      category: { id: 'architecturalProjects', name: t("navArchitectural")},
      customer: 'جمعية الأمير سلطان بن عبد العزيز آل سعود',
      text: '',
      text2: '',
      workScope: 'يشمل نطاق العمل تصميم مسجد بطاقة استيعابية تصل إلى 900 مصلٍ، بمساحة إجمالية تبلغ 500 متر مربع، إضافة إلى الإشراف الكامل على تنفيذ المشروع منذ بدايته وحتى تسليمه، ويشمل:',
      workScopePoints: [
        { text: 'إعداد جميع المخططات المعمارية، الإنشائية، والإلكتروميكانيكية وفق أعلى معايير الجودة.' },
        { text: 'تصميم البنية التحتية للمشروع، بما يشمل أنظمة الصرف الصحي، شبكات المياه والكهرباء، وأنظمة التكييف والتهوية.' },
        { text: 'تصميم العناصر الجمالية للمشروع، مثل الواجهات الخارجية والزخارف الداخلية، مع مراعاة الطابع الإسلامي التقليدي الممزوج بالحداثة.' },
        { text: 'إعداد المخططات التفصيلية للمبنى الرئيسي وعدد 2 فيلا ملحقة بالمسجد، تتضمن المساحات السكنية والخدمات.' },
        { text: 'التخطيط لتوفير المرافق الخدمية مثل مواقف السيارات، المساحات الخضراء، وأماكن الوضوء.' },
        { text: 'الإشراف على تنفيذ المشروع بجميع مراحله، بما يضمن تحقيق جودة الأعمال ومطابقتها للتصاميم والاشتراطات الفنية والهندسية.' },
        { text: 'التنسيق مع جميع الأطراف المعنية، بما في ذلك المقاولين والموردين، لضمان سير العمل وفق الجداول الزمنية المحددة.' },
        { text: 'اعتماد استخدام تقنيات التصميم المستدام لتحسين كفاءة الطاقة وتقليل التأثير البيئي للمشروع.' },
      ],
      points: [],
      executionYear:'',
      startDate:'2022',
      images: [projectDesign3_1,projectDesign3_2,projectDesign3_3,projectDesign3_4]
    },
    {
      id: '4',
      name:'تصميم مكاتب إدارة التراخيص للحياة الفطرية (مركز الإيواء المؤقت بمنطقة جازان)',
      category: { id: 'architecturalProjects', name: t("navArchitectural")},
      customer: 'المركز الوطني لتنمية الحياة الفطرية',
      text:'قام المكيال السعودي للاستشارات الهندسية بتصميم مكاتب إدارة التراخيص للاتجار بالكائنات الفطرية في أسواق منطقة جازان. ',
      workScope: 'شمل نطاق العمل تصميم المكاتب والمرافق المتصلة بها مع مراعاة تحقيق أعلى مستويات الكفاءة التشغيلية. تمحور التصميم حول:',
      workScopePoints: [
        { text: 'تصميم المكاتب الإدارية: تطوير تصاميم مكاتب تتسم بالمرونة والفاعلية لضمان سير العمل بكفاءة عالية، مع توفير بيئة ملائمة لموظفي الإدارة.' },
      ],
      points : [
        {point:'تخطيط المساحات',
          text:'تخصيص المساحات المناسبة لتسهيل عمليات تقديم الطلبات والتراخيص، فضلاً عن مواقف السيارات، وحلول التخزين الآمنة للوثائق.'
        },
      ],
      executionYear:'2023',
      startDate:'',
      images: [projectDesign4_1,projectDesign4_2,projectDesign4_3,projectDesign4_4]
    },
    {
      id: 'examples/1',
      name:'تصميم مجموعة من محطات الوقود',
      category: { id: 'architecturalProjects', name: t("navArchitectural")},
      customer: 'محطات الدريس، بترومترك،  Seven Plus، وعدد من الأفراد بمختلف مدن منطقة الرياض',
      text: '',
      text2: '',
      workScope: 'تصميم جميع المخططات المعمارية والإنشائية والإلكتروميكانيكية واعتماد واستخراج رخص بناء.',
      workScopePoints: [],
      points: [],
      executionYear:'',
      startDate:'',
      images: [projectDesign5_1]
    },
    {
      id: 'examples/2',
      name:'تصميم العديد من الفلل والعمائر السكنية',
      category: { id: 'architecturalProjects', name: t("navArchitectural")},
      customer: 'أفراد ومطورون عقاريون',
      text: '',
      text2: '',
      workScope: 'إعداد الرفع المساحي والمخططات المعمارية والإنشائية واستخراج رخص البناء',
      workScopePoints: [],
      points: [],
      executionYear:'',
      startDate:'',
      images: [projectDesign6_1]
    },
    {
      id: '7',
      name:'تخطيط واعتماد نهائي للمخطط رقم 3978 بحي الرمال بالرياض',
      category: { id: 'architecturalProjects', name: t("navArchitectural")},
      customer: 'شركة بذل العقارية',
      text: '',
      text2: '',
      workScope: 'اعتماد نهائي وأعمال فرز للمخطط',
      workScopePoints: [],
      points: [],
      executionYear:'2024',
      startDate:'',
      images: [projectUrban1_1]
    },
    {
      id: '8',
      name:'تخطيط واعتماد نهائي للمخطط رقم 3957 بحي السعادة بالرياض ',
      category: { id: 'architecturalProjects', name: t("navArchitectural")},
      customer: 'شركة ركاز العقارية',
      text: '',
      text2: '',
      workScope: 'اعتماد نهائي من أمانة منطقة الرياض وأعمال الفرز للمخطط',
      workScopePoints: [],
      points: [],
      executionYear:'2023',
      startDate:'',
      images: [projectUrban2_1]
    },
    {
      id: '9',
      name:'تخطيط واعتماد نهائي للمخطط رقم 3943 بحي الجنادرية بالرياض',
      category: { id: 'architecturalProjects', name: t("navArchitectural")},
      customer: 'شركة تساوي العقارية ',
      text: '',
      text2: '',
      workScope: 'اعتماد نهائي من أمانة منطقة الرياض وأعمال الهوية العقارية والفرز للمخطط',
      workScopePoints: [],
      points: [],
      executionYear:'2023',
      startDate:'',
      images: [projectUrban3_1]
    },
    {
      id: '10',
      name:'تخطيط مدينة صناعية بمحافظة الخرج',
      category: { id: 'architecturalProjects', name: t("navArchitectural")},
      customer: 'مطور عقاري',
      text: '',
      text2: '',
      workScope: 'تخطيط وتصميم الفكرة التخطيطية وإعتمادها وأعمال الفرز',
      workScopePoints: [],
      points: [],
      executionYear:'2024',
      startDate:'',
      images: [projectUrban4_1]
    },
    {
      /////////////////////////////////////////////
      id: '11',
      name:'التصميم الحضري',
      category: { id: 'architecturalProjects', name: t("navArchitectural")},
      customer: '',
      text: '',
      text2: '',
      workScope: '',
      workScopePoints: [],
      points: [],
      executionYear:'',
      startDate:'',
      images: [projectUrban5_1,projectUrban5_2,projectUrban5_3]
    },
    {
      id: '12',
      name:'إعداد الدراسات الهيدرولوجية لبلديات المحور الجنوبي بمنطقة الرياض',
      category: { id: 'hydrologicalProjects', name: t("navHydrological")},
      customer: 'أمانة منطقة الرياض الوكالة المساعدة لمشاريع بلديات منطقة الرياض',
      text: 'تولى المكيال السعودي للاستشارات الهندسية إعداد الدراسات الهيدرولوجية المتخصصة لعدد من الأراضي والمخططات الواقعة ضمن نطاق بلديات المحور الجنوبي في منطقة الرياض. هدفت هذه الدراسات إلى تحليل الموارد المائية ومجاري الأودية، وتقييم مخاطر السيول، ووضع الحلول الهندسية المستدامة لحماية المناطق المستهدفة وتعزيز الاستفادة المثلى من الموارد الطبيعية.',
      text2:'تمحور العمل حول تحقيق التكامل بين تخطيط الأراضي وإدارة الموارد المائية، مع مراعاة المعايير البيئية والتنموية، مما ساهم في تقديم حلول فعالة لدعم استدامة المنطقة وتلبية احتياجاتها المستقبلية.',
      workScope: '',
      workScopePoints: [],
      points: [
        {point:'النطاق الجغرافي لعمل المشروع والبلدات المستفيدة من المشروع',
        points: [
          {point:'بلدية الخرج'},
          {point:'بلدية الدلم'},
          {point:'بلدية حوطة بني تميم'},
          {point:'بلدية الحلوة'},
          {point:'بلدية الحريق'},
          {point:'بلدية الأفلاج'},
          {point:'بلدية البديع الأحمر'},
          {point:'بلدية السليل'},
          {point:'بلدية وادي الدواسر'},
        ]
        }],
      executionYear:'',
      startDate:'',
      images: [projectHydro1_1,projectHydro1_2,projectHydro1_3]
    },
    {
      id: '13',
      name:'دراسة وإعداد وتطوير الكود السعودي لمصادر المياه واستخداماتها',
      category: { id: 'hydrologicalProjects', name: t("navHydrological")},
      customer: 'وزارة البيئة والمياه والزراعة',
      text: 'تولى المكيال السعودي للاستشارات الهندسية إعداد وتطوير استراتيجيات مستدامة تهدف إلى تحسين إدارة مصادر المياه وتعزيز كفاءة استخدامها في مختلف القطاعات. يأتي هذا المشروع كجزء من الجهود الوطنية الرامية إلى تحقيق الاستدامة البيئية وترشيد استهلاك الموارد المائية بما يتماشى مع رؤية المملكة 2030.',
      text2: 'ساهم المشروع في تمكين القطاعات المختلفة من اتباع ممارسات مستدامة، ما أدى إلى تحسين كفاءة استهلاك المياه ودعم الجهود الوطنية لمواجهة التحديات المتعلقة بندرة المياه وضمان استدامة هذا المورد الحيوي للأجيال القادمة.',
      workScope: 'تمحور المشروع حول تطوير الكود السعودي لمصادر المياه واستخداماتها، والذي يُعد أداة تنظيمية ورؤية استراتيجية تضع معايير واضحة وشاملة لإدارة المياه بكفاءة. وشمل العمل:',
      workScopePoints: [
        { text:'وضع السياسات والمبادئ التوجيهية لتقييم مصادر المياه وإعادة استخدامها.'},
        { text:'تقديم حلول مبتكرة وتقنيات متطورة لتحسين كفاءة استخدام المياه في القطاعات الزراعية، والصناعية، والحضرية.'},
        { text:'مراعاة الجوانب البيئية والاقتصادية لتحقيق التوازن بين الطلب على المياه والحفاظ على الموارد الطبيعية.'},
      ],
      points: [],
      executionYear:'',
      startDate:'',
      images: [projectHydro2_1,projectHydro2_2]
    },
    {
      id: '14',
      name:'العديد من الدراسات الجزئية مع أمانة منطقة الرياض ووكالة الأمانة ووزارة النقل وغيرها من الجهات ',
      category: { id: 'hydrologicalProjects', name: t("navHydrological")},
      customer: '',
      text: '',
      text2: '',
      workScope: '',
      workScopePoints: [],
      points: [],
      executionYear:'',
      startDate:'',
      images: [projectHydro5_1,projectHydro5_2,projectHydro5_3]
    },
    {
      id: '15',
      name:'الدراسة الهيدرولوجية لمشروع تصميم وتنفيذ تحويلة طريق الملك خالد التابع لبوابة الدرعية',
      category: { id: 'hydrologicalProjects', name: t("navHydrological")},
      customer: '',
      text: 'قام المكيال السعودي للاستشارات الهندسية بتنفيذ دراسة هيدرولوجية لمشروع تصميم وتنفيذ تحويلة طريق الملك خالد. تهدف الدراسة إلى تحليل جريان المياه وتحديد الحلول المثلى للتصريف المائي على طول الطريق، بما يضمن سلامة الطريق وتقليل المخاطر الناتجة عن الأمطار والسيول.',
      text2:'ساهمت هذه الدراسة في تأمين تصميم هندسي يعالج تحديات التصريف المائي بشكل شامل، مما يسهم في تحسين استدامة الطريق وضمان سلامته على المدى الطويل.',
      workScope: '',
      workScopePoints: [
        {text:'تحليل جريان المياه: دراسة وتحليل تأثيرات الأمطار على المنطقة المحيطة بطريق الملك خالد، وتحديد مناطق التجمع المائي لتصميم حلول تصريف فعّالة.'},
        {text:'دراسة التأثيرات الهيدرولوجية: تحديد كيفية تأثير تحويلة الطريق على تدفق المياه في المنطقة، بما في ذلك التغيرات المحتملة في اتجاهات الجريان وأثرها على التصميم العام.'},
        {text:'اقتراح حلول التصريف: وضع حلول هندسية مبتكرة للتصريف الفعّال للمياه عبر شبكة من الخنادق، المجاري المائية، وحلول محطات ضخ المياه، لضمان استمرارية الحركة على الطريق في مختلف الظروف المناخية.'},
        {text:'التصميم البيئي: ضمان أن التصميم يتماشى مع المعايير البيئية ويقلل من التأثيرات السلبية على البيئة المحيطة.'},
      ],
      points: [],
      executionYear:'',
      startDate:'',
      images: [projectHydro3_1,projectHydro3_2,projectHydro3_3,projectHydro3_4]
    },
    {
      id: '16',
      name:'تقديم خدمات الدراسة الهيدرولوجية والأعمال المساحية لمواقع متفرقة بالرياض ',
      category: { id: 'hydrologicalProjects', name: t("navHydrological")},
      customer: '',
      text: 'قام المكيال السعودي للاستشارات الهندسية بتنفيذ المرحلة الثانية من مشروع تطوير البنية التحتية لمنطقة الرياض التي تشمل الدراسات الهيدرولوجية والأعمال المساحية لمواقع متفرقة على الطرق الرئيسية والفرعية. تم تنفيذ هذه الدراسات بهدف اقتراح الحلول المثلى ووسائل التصريف الفعالة للمياه، بما يساهم في تعزيز السلامة البيئية وتحسين شبكة الطرق في المناطق المستهدفة.',
      workScope: 'شمل نطاق المشروع المواقع التالية ضمن منطقة الرياض: (الرياض – القرين – الخرج- وادي الدواسر – الدلم - الأحمر – الرين - الأفلاج – الحصاة - شقراء – نفي- الطريف).',
      workScopePoints: [
        {text:'الدراسات الهيدرولوجية: تحليل جريان المياه في المناطق المختلفة، وتحديد مصادر الأمطار والمجاري المائية، وتقديم حلول هندسية لمعالجة مشاكل تجمع المياه وتوجيهها بشكل فعال.'},
        {text:'الأعمال المساحية: إجراء مسوحات دقيقة لتحديد مواقع التصريف وتقييم المخاطر المحتملة، بما يضمن توفير تصريف مناسب وفقًا للطبيعة الجغرافية للمنطقة.'},
      ],
      points: [
        {point:'اقتراح الحلول',
          text:'تصميم أنظمة تصريف المياه بما يتماشى مع المعايير الفنية الحديثة لضمان التخفيف من تأثيرات الفيضانات والسيول على الطرق والبنية التحتية.'
        },
      ],
      executionYear:'',
      startDate:'',
      images: [projectHydro4_1,projectHydro4_2,projectHydro4_3,projectHydro4_4]
    },
    {
      id: '17',
      name:'أعمال رفع المساحة وتثبيت بتر حدودية لوكالة الثروة السمكية التابعة لوكالة الزراعة بمنطقة مكة المكرمة',
      category: { id: 'gisProjects', name: t("navGIS")},
      customer: '',
      text: '',
      text2: '',
      workScope: '',
      workScopePoints: [],
      points: [],
      executionYear:'',
      startDate:'',
      images: [projectGis1_1,projectGis1_2]
    },
    {
      id: '18',
      name:'اعمل الرفع المساحي وإنشاء بتر حدودية لتحديد ميدان تدريب للحرس الوطني',
      category: { id: 'gisProjects', name: t("navGIS")},
      customer: '',
      text: '',
      text2: '',
      workScope: '',
      workScopePoints: [],
      points: [],
      executionYear:'',
      startDate:'',
      images: [projectGis2_1,projectGis2_2,projectGis2_3,projectGis2_4]
    },
    {
      id: '19',
      name:'أعمال التخطيط والرفع المساح وتبتير الأراضي للمخططات السكنية لأعمال خاصة بوزارة البلديات والإسكان',
      category: { id: 'gisProjects', name: t("navGIS")},
      customer: '',
      text: '',
      text2: '',
      workScope: '',
      workScopePoints: [],
      points: [],
      executionYear:'',
      startDate:'',
      images: [projectGis3_1,projectGis3_2]
    },
    {
      id: '20',
      name:'الأشراف على أعمال البنية التحتية للمخطط رقم 4002',
      category: { id: 'supervisionProjects', name: t("navSupervision")},
      customer: '',
      text: '',
      text2: '',
      workScope: '',
      workScopePoints: [],
      points: [],
      executionYear:'',
      startDate:'',
      images: [projectSuper1_1,projectSuper1_2,projectSuper1_3,projectSuper1_4]
    },
    {
      id: '21',
      name:'الأشراف على أعمال البنية التحتية للمخطط رقم 3926',
      category: { id: 'supervisionProjects', name: t("navSupervision")},
      customer: '',
      text: '',
      text2: '',
      workScope: '',
      workScopePoints: [],
      points: [],
      executionYear:'',
      startDate:'',
      images: []
    },
    {
      id: '22',
      name:'الأشراف على أعمال البنية التحتية للمخطط رقم 3978',
      category: { id: 'supervisionProjects', name: t("navSupervision")},
      customer: '',
      text: '',
      text2: '',
      workScope: '',
      workScopePoints: [],
      points: [],
      executionYear:'',
      startDate:'',
      images: [projectSuper3_1,projectSuper3_2,projectSuper3_3,projectSuper3_4]
    },
    {
      id: '23',
      name:'الأشراف على أعمال البنية التحتية للمخطط رقم 3079',
      category: { id: 'supervisionProjects', name: t("navSupervision")},
      customer: '',
      text: '',
      text2: '',
      workScope: '',
      workScopePoints: [],
      points: [],
      executionYear:'',
      startDate:'',
      images: []
    },
    {
      id: '24',
      name: 'اتفاقية إطارية مع وزارة الاستثمار لتقديم الخدمات الهندسية الاستشارية',
      category: { id: 'supervisionProjects', name: t("navSupervision")},
      customer: '',
      text: 'أبرمت شركة المكيال السعودي للاستشارات الهندسية اتفاقية إطارية مع وزارة الاستثمار لتقديم مجموعة من الخدمات الهندسية الاستشارية التي تسهم في تحقيق رؤية الوزارة لتطوير مشاريعها وتعزيز البنية التحتية الوطنية.',
      text2: 'الإشراف الهندسي: متابعة وتنفيذ الأعمال الهندسية للمشاريع، مع ضمان الالتزام بالخطط الزمنية والمعايير الإنشائية.',
      workScope: '',
      workScopePoints: [
        {text:'تصميم مشاريع الوزارة: تطوير التصاميم الهندسية وفق أعلى المعايير الفنية العالمية، مع مراعاة متطلبات الجودة والابتكار لتلبية احتياجات المشاريع.'},
        {text:'الإشراف الهندسي: متابعة وتنفيذ الأعمال الهندسية للمشاريع، مع ضمان الالتزام بالخطط الزمنية والمعايير الإنشائية.'},
      ],
      points: [],
      executionYear:'',
      startDate:'',
      images: []
    },
    {
      id: '25',
      name: 'توفير طاقم دعم وإشراف فني للمكتب الإقليمي في المنطقة الشرقية شركة تطوير للمباني (TBC)',
      category: { id: 'supervisionProjects', name: t("navSupervision")},
      customer: '',
      text: 'قامت شركة المكيال السعودي للاستشارات الهندسية بتوفير طاقم دعم وإشراف فني متخصص للعمل في المكتب الإقليمي بالمنطقة الشرقية. جاء ذلك في إطار تعزيز قدرة الشركة على تقديم خدمات استشارية متميزة ودعم المشاريع الهندسية في المنطقة، بما يضمن تنفيذ الأعمال بكفاءة عالية ووفق المعايير الفنية.',
      text2: '',
      workScope: '',
      workScopePoints: [
        {text:'تقديم الاستشارات الفنية المتخصصة لدعم فرق العمل المحلية.'},
        {text:'الإشراف على تنفيذ الأعمال والمشاريع لضمان التزامها بالجداول الزمنية والميزانيات المحددة.'},
        {text:'دعم التعاون المستمر مع العملاء والمقاولين المحليين لتسهيل العمليات الهندسية.'},
      ],
      points: [],
      executionYear:'',
      startDate:'',
      images: []
    },
    {
      id: '26',
      name: 'مشروع تنفيذ خزان مياه عالي بسعة 200 م³',
      category: { id: 'supervisionProjects', name: t("navSupervision")},
      customer: 'الجمعية الخيرية لخدمات المياه (إرواء)',
      text: 'الإشراف على تنفيذ خزان مياه عالي بسعة 200 م³ في مركز عقلة الغويري بمحافظة الدوادمي، ضمن منطقة الرياض. ',
      text2: '',
      workScope: '',
      workScopePoints: [
        {text:'الإشراف الهندسي الكامل على المشروع لضمان الالتزام بالمواصفات الفنية والمعايير الهندسية.'},
        {text:'تقديم الدعم الفني اللازم خلال جميع مراحل تنفيذ المشروع.'},
        {text:'تنفيذ الأعمال الإنشائية والكهروميكانيكية للخزان.'},
        {text:'التنسيق مع جميع الأطراف المعنية لضمان سير العمل وفق الجدول الزمني المخطط له.'},
        {text:'الالتزام بأعلى معايير السلامة والجودة أثناء التنفيذ.'},
      ],
      points: [
        {point:'أهداف المشروع',
          points:[
            {point:'تحسين خدمات المياه في المنطقة بشكل مستدام.'},
            {point:'دعم جهود الجمعية الخيرية لخدمات المياه (إرواء) في تحقيق رسالتها لخدمة المجتمع.'},
          ]
        }
      ],
      executionYear:'',
      startDate:'',
      images: [projectSuper7_1,projectSuper7_2,projectSuper7_3,projectSuper7_4]
    },
    {
      id: '27',
      name: 'الرياض الخضراء',
      category: { id: 'infrastructureProjects', name: t("navInfrastructure")},
      customer: 'الهيئة الملكية لمدينة الرياض ',
      text: '',
      text2: '',
      workScope: ' تصميم شبكات الري وأعمال الهندسية الكاملة للبنية التحتية مع نظام الإسكادا',
      workScopePoints: [],
      points: [],
      executionYear:'',
      startDate:'',
      images: [projectInfra1_1,projectInfra1_2,projectInfra1_3,projectInfra1_4]
    },
    {
      id: '28',
      name: 'مخطط حرمة السكني',
      category: { id: 'infrastructureProjects', name: t("navInfrastructure")},
      customer: 'الشركة الوطنية للإسكان',
      text: '',
      text2: '',
      workScope: 'تصميم جميع خدمات البنية التحتية فيه ويشمل ذلك تصميم الطرق، شبكات السيول، شبكة الصرف الصحي، شبكة المياه وشبكة الكهرباء',
      workScopePoints: [],
      points: [],
      executionYear:'',
      startDate:'',
      images: [projectInfra2_1]
    },
    {
      id: '29',
      name: 'النخيل مول',
      category: { id: 'infrastructureProjects', name: t("navInfrastructure")},
      customer: 'شركة أوج القابضة',
      text: '',
      text2: '',
      workScope: 'تصميم خدمات البنية التحتية من تصميم الطرق، شبكات السيول، شبكة الصرف الصحي، شبكة المياه وشبكة الكهرباء.',
      workScopePoints: [],
      points: [],
      executionYear:'',
      startDate:'',
      images: [projectInfra3_1]
    },
    {
      id: '30',
      name: 'مجمع العنود السكني',
      category: { id: 'infrastructureProjects', name: t("navInfrastructure")},
      customer: 'شركة دور للضيافة',
      text: '',
      text2: '',
      workScope: 'تصميم أعمال البنية التحتية طرق صرف صحي شبكة مياه شبكة كهرباء.',
      workScopePoints: [],
      points: [],
      executionYear:'',
      startDate:'',
      images: [projectInfra4_1,projectInfra4_2]
    },
    {
      id: '31',
      name: 'تصميم ري وزراعة نظام التحكم للرمال 1 والعريضة 2 وحدائق بدر',
      category: { id: 'infrastructureProjects', name: t("navInfrastructure")},
      customer: '',
      text: 'قام المكيال السعودي بتنفيذ أعمال تصميم شبكات الري وزراعة نظام التحكم لمجموعة من الحدائق في منطقة الرياض في إطار تحسين البيئة الحضرية وتطوير المساحات الخضراء.',
      text2: '',
      workScope: 'تمحور المشروع حول تطوير حلول ري متكاملة ومستدامة تساهم في تحقيق إدارة فعالة للمياه وضمان استدامة المساحات الخضراء. وقد شمل نطاق العمل:',
      workScopePoints: [
        {text:'تصميم شبكات الري: تطوير وتصميم شبكات ري متطورة تتماشى مع احتياجات كل من الحدائق، مع مراعاة كفاءة استهلاك المياه وتحقيق أقصى استفادة من الموارد المتاحة.'},
        {text:'خزانات الري: تصميم خزانات لتخزين المياه اللازمة للري مع ضمان قدرتها على تلبية الاحتياجات طويلة المدى.'},
        {text:'أنظمة التحكم: تقديم حزمة متكاملة من أنظمة التحكم الخاصة بإدارة وتنظيم توزيع المياه بما يضمن التشغيل الأمثل للشبكة في جميع الأوقات.'},
        {text:'محطات معالجة التناضح العكسي: تصميم محطات معالجة مياه التناضح العكسي لتحلية المياه وإعادة استخدامها في الري، بما يعزز الاستدامة البيئية.'},
      ],
      points: [
        {point:'الخزانات لثلاث حدائق',
          text:'تصميم وتطوير خزانات المياه لثلاث حدائق هي: الرمال 1، العريضة 2، وحدائق بدر، لضمان استمرارية توفير المياه دون انقطاع'
        }
      ],
      executionYear:'',
      startDate:'',
      images: [projectInfra5_1,projectInfra5_2,projectInfra5_3]
    },
    {
      id: '32',
      name: 'تصميم رأسي وهندسي لطريق الوصول إلى ضبا وشقري واتصاله بالطريق السريع المجاور في نيوم',
      category: { id: 'infrastructureProjects', name: t("navInfrastructure")},
      customer: '',
      text: 'قام المكيال السعودي للاستشارات الهندسية بتقديم خدمات التصميم الهندسي والرأسي لطريق الوصول إلى ضبا وشقري، مع تصميم شامل يهدف إلى تحقيق التواصل الفعال بين المنطقة والطريق السريع المجاور. تمحور هذا المشروع حول توفير مسار آمن وفعال يربط بين المناطق المستهدفة والطريق السريع، ويعزز من سهولة التنقل وتدفق الحركة المرورية في المنطقة.',
      text2: '',
      workScope: '',
      workScopePoints: [
        {text:'التصميم الرأسي للطريق: وضع التصاميم الخاصة بتحديد المنحدرات والأبعاد الطولية للطريق بما يتناسب مع طبيعة المنطقة الجغرافية ويسهم في تحسين سلامة القيادة.'},
        {text:'التصميم الهندسي للطريق: تقديم حلول هندسية شاملة تضمن سلاسة الحركة المرورية على الطريق، مع دراسة التوزيع الأمثل للمسارات وتحديد المواقع المناسبة للإشارات والعلامات المرورية.'},
        {text:'التصميم الهندسي للاتصال بالطريق السريع: تطوير الحلول الهندسية لربط الطريق مع الطريق السريع المجاور بشكل يضمن التنقل السلس بين الطرق مع تقليل التأثيرات على حركة المرور الحالية.'},
      ],
      points: [
        {point:'الاعتبارات البيئية',
          text:'مراعاة التأثيرات البيئية خلال التصميم لضمان الحفاظ على البيئة الطبيعية في منطقة نيوم بما يتماشى مع رؤية المشروع الساعية للتنمية المستدامة.',
        }
      ],
      executionYear:'',
      startDate:'',
      images: [projectInfra6_1,projectInfra6_2,projectInfra6_3,projectInfra6_4]
    },
    {
      id: '33',
      name: 'تصميم شبكات الري والبنية التحتية لمشروع الرياض الخضراء (المنطقة الثالثة)',
      category: { id: 'infrastructureProjects', name: t("navInfrastructure")},
      customer: '',
      text: 'في إطار مشاركتنا في مشروع الرياض الخضراء، قام فريق عمل المكيال السعودي للاستشارات الهندسية بتقديم حلول هندسية متكاملة لتصميم شبكات الري وأعمال البنية التحتية للمنطقة الثالثة من المشروع. تمحور عمل الفريق حول تطوير أنظمة ري مبتكرة ومستدامة لدعم الخضرة والنمو النباتي.',
      text2: 'يساهم هذا العمل في دعم أهداف مشروع الرياض الخضراء من خلال توفير حلول هندسية مبتكرة تعزز من استدامة البيئة الحضرية وتدعم رؤية العاصمة في التحول إلى مدينة أكثر اخضرارًا.',
      workScope: '',
      workScopePoints: [
        {text:'تصميم شبكات الري: تطوير وتصميم شبكات ري حديثة وفعالة لتغطية المناطق الخضراء بما يضمن استدامة الري مع مراعاة كفاءة استهلاك المياه'},
        {text:'أعمال الهندسة الكاملة للبنية التحتية: تصميم البنية التحتية اللازمة لدعم تنفيذ الشبكات، بما في ذلك الأنابيب والمكونات المختلفة لضمان الأداء الأمثل.'},
        {text:'نظام الإسكادا: تطوير وتطبيق نظام التحكم الآلي (SCADA) لمراقبة وإدارة أنظمة الري عن بعد، مما يضمن تحسين الكفاءة التشغيلية وسهولة التحكم في العمليات.'},
        {text:'التصميم التخطيطي لخزانات الري: إعداد التصاميم الهندسية لخزانات الري الرئيسية والثانوية لتوفير مصادر مياه متجددة بشكل مستمر ودون انقطاع.'},
        {text:'تصميم الحدائق المستقبلية: تقديم حلول تصميم هندسي لحدائق ومسطحات خضراء مستقبلية، تضمن التنوع البيئي والجمالي للمنطقة.'},
      ],
      points: [],
      executionYear:'',
      startDate:'',
      images: [projectInfra7_1]
    },
    {
      id: '34',
      name:'دراسة التأثير المروري لمستشفيي سليمان الحبيب بحي الحمراء',
      category: { id: 'transportProjects', name: t("navTransport")},
      customer: '',
      text: '',
      text2: '',
      workScope: '',
      workScopePoints: [],
      points: [],
      executionYear:'',
      startDate:'',
      images: [projectTrans1_1,projectTrans1_2,projectTrans1_3,projectTrans1_4]
    },
    {
      id: '35',
      name:'دراسة التأثير المروري لمجمع مدارس جلوري العالمية بحى بدر',
      category: { id: 'transportProjects', name: t("navTransport")},
      customer: '',
      text: '',
      text2: '',
      workScope: '',
      workScopePoints: [],
      points: [],
      executionYear:'',
      startDate:'',
      images: [projectTrans2_1,projectTrans2_2,projectTrans2_3,projectTrans2_4]
    },
    {
      id: '36',
      name:'دراسة التأثير المروري لمشروع يمام بحي السفارات.',
      category: { id: 'transportProjects', name: t("navTransport")},
      customer: '',
      text: '',
      text2: '',
      workScope: '',
      workScopePoints: [],
      points: [],
      executionYear:'',
      startDate:'',
      images: [projectTrans3_1,projectTrans3_2,projectTrans3_3,projectTrans3_4]
    },
    {
      id: '37',
      name:'العقد الموحد للأشراف على أعمال إنشاء وصيانة شبكات التوزيع حتى جهد 36 ك.ف',
      category: { id: 'electricalProjects', name: t("navElectrical")},
      customer: 'الشركة السعودية للكهرباء',
      text: 'في إطار التعاون الاستراتيجي بين المكيال السعودي للاستشارات الهندسية والشركة السعودية للكهرباء، يقوم فريق عمل المكيال السعودي للاستشارات الهندسية بالإشراف على أعمال إنشاء وصيانة شبكات التوزيع حتى جهد 36 ك.ف في مناطق متعددة. يشمل العمل إشرافًا هندسيًا عالي المستوى لضمان تنفيذ المشاريع بكفاءة وجودة وفقًا لأعلى المعايير الفنية.',
      text2:'',
      workScope: '',
      workScopePoints: [],
      points: [
        {point:'يتضمن النطاق الجغرافي للمشروع المناطق التالية',
        points: [
          {point:'إدارة منطقة الرياض'},
          {point:'إدارة كهرباء الخرج'},
          {point:'إدارة كهرباء الطائف'},
          {point:'القطاع الأوسط'},
          {point:'القطاع الغربي'},
        ]},
        {point:'تمحور عمل الفريق حول',
        points: [
          {point:'إشراف هندسي على إنشاء شبكات التوزيع: ضمان بناء الشبكات وفقًا للمواصفات الفنية المعتمدة، مع التركيز على السلامة والكفاءة في تنفيذ الأعمال'},
          {point:'إشراف على صيانة الشبكات: متابعة أعمال الصيانة الدورية والإصلاحات المطلوبة للحفاظ على استمرارية الخدمة وجودتها، وتقليل أي انقطاعات محتملة في الشبكة'},
        ]},
        {point:'التنسيق مع الشركة السعودية للكهرباء',
        text: 'التعاون المستمر مع فرق العمل المختلفة في الشركة لضمان التكامل بين الأنظمة والمشاريع، ودعم تلبية احتياجات الطاقة المتزايدة في تلك المناطق.'},
      ],
      executionYear:'',
      startDate:'',
      images: [projectElectrical1_1,projectElectrical1_2,projectElectrical1_3,projectElectrical1_4]
    },
    {
      id: '38',
      name:'دراسة وتقييم الحالة الإنشائية لقصر الملك خالد والمباني المحيطة به في محمية الملك خالد الملكية',
      category: { id: 'evaluationProjects', name: t("navEvaluation")},
      customer: 'هيئة تطوير محمية الإمام عبد العزيز بن محمد الملكية',
      text: 'في إطار الحفاظ على التراث المعماري والتاريخي، قام المكيال السعودي للاستشارات الهندسية بتنفيذ دراسة شاملة لتقييم الحالة الإنشائية لقصر الملك خالد والمباني المحيطة به داخل محمية الملك خالد الملكية. يهدف المشروع إلى تحديد حالة المباني من الناحية الإنشائية والتأكد من سلامتها الهيكلية، مع وضع التوصيات اللازمة للحفاظ عليها وترميمها بما يتماشى مع المعايير الفنية والبيئية.',
      text2:'',
      workScope: 'إجراء الاختبارات والفحوصات لتقييم الحالة الإنشائية لقصر الملك خالد والمباني والملحقات المحيطة به في محمية الملك خالد',
      workScopePoints: [
        {text:'التقييم الإنشائي لقصر الملك خالد: فحص الهيكل الإنشائي للقصر باستخدام أحدث تقنيات الفحص والتقييم لتحديد مدى قوة واستدامة الأساسات والجدران، بالإضافة إلى التحقق من تعرضه لأي مشاكل هيكلية.'},
        {text:'دراسة المباني المحيطة: تحليل حالة المباني والمرافق المحيطة بالقصر في المحمية لضمان عدم تأثير أي تدهور على البيئة المحيطة أو على البنية التحتية المعمارية.'},
        {text:'التوصيات والترميم: وضع خطة ترميم شاملة تشمل الإجراءات اللازمة لتقوية المباني وحمايتها من أي تدهور مستقبلي، بما في ذلك المواد والأساليب المناسبة للحفاظ على الطابع المعماري الفريد للموقع.'},
      ],
      points: [
        {point:'الحفاظ على البيئة',
          text:'مراعاة المعايير البيئية في تنفيذ أعمال التقييم والتوصيات، لضمان الحفاظ على المحمية الطبيعية وحمايتها من أي تأثيرات سلبية'
        }
      ],
      executionYear:'',
      startDate:'',
      images: [projectEvaluation1_1,projectEvaluation1_2,projectEvaluation1_3,projectEvaluation1_4]
    },
    {
      id: '39',
      name:'دراسة الهيكل الإنشائي لمواقع متعددة بمدينة الملك عبد العزيز العسكرية بتبوك',
      category: { id: 'evaluationProjects', name: t("navEvaluation")},
      customer: 'الأشغال العسكرية – وزارة الدفاع ',
      text: '',
      text2:'',
      workScope: 'إجراء اختبارات وفحوصات وعمل دراسة للهيكل الإنشاء لمواقع متعددة بمدينة الملك عبد العزيز العسكرية بتبوك',
      workScopePoints: [],
      points: [],
      executionYear:'',
      startDate:'',
      images: [projectEvaluation2_1,projectEvaluation2_2,projectEvaluation2_3,projectEvaluation2_4]
    },
    {
      id: '40',
      name:'تقييم إنشائي لمبنى العزاب بالمركز الرئيسي للمؤسسة العامة لتحلية المياه المالحة والمبنى الإداري بالتخصصي في الرياض',
      category: { id: 'evaluationProjects', name: t("navEvaluation")},
      customer: 'الهيئة السعودية للمياه (المؤسسة العامة لتحلية المياه المالحة سابقاً)',
      text: '',
      text2:'',
      workScope: 'تقييم إنشائي وأعداد دراسة بالحالة الإنشائية',
      workScopePoints: [],
      points: [],
      executionYear:'',
      startDate:'',
      images: [projectEvaluation3_1,projectEvaluation3_2,projectEvaluation3_3]
    },
  ]
  
  const data = [
    {
      id: '1',
      category: { id: 'geotechnicalProjects', name: t("navGeotechnicalProject")},
      name: t('projects1.1.name'),
      customer: t('projects1.6.customer'),
      workScope: t('projects1.6.workScope'),
      geographicalScope: t('projects1.1.geographicalScope'),
      executionYear:'2024',
      startDate:'',
      status:'',
      description: t('projects1.6.description'),
      images: [project1_1,project1_2,project1_3]
    },
    {
      id: '2',
      category: { id: 'geotechnicalProjects', name: t("navGeotechnicalProject")},
      name: t('projects1.2.name'),
      customer: t('projects1.6.customer'),
      workScope: t('projects1.6.workScope'),
      geographicalScope: t('projects1.2.geographicalScope'),
      executionYear:'2020',
      startDate:'',
      status:'',
      description: t('projects1.6.description'),
      images: [project2_1,project2_2,project2_3]
    },
    {
      id: '3',
      category: { id: 'geotechnicalProjects', name: t("navGeotechnicalProject")},
      name: t('projects1.3.name'),
      customer: t('projects1.6.customer'),
      workScope: t('projects1.6.workScope'),
      geographicalScope:'',
      executionYear:'2023',
      startDate:'',
      status:'',
      description: t('projects1.6.description'),
      images: [project3_1,project3_2,project3_3]
    },
    {
      id: '4',
      category: { id: 'geotechnicalProjects', name: t("navGeotechnicalProject")},
      name: t('projects1.4.name'),
      customer: t('projects1.6.customer'),
      workScope: t('projects1.6.workScope'),
      geographicalScope:'',
      executionYear:'2024',
      startDate:'',
      status:'',
      description: t('projects1.6.description'),
      images: [project4_1,project4_2,project4_3]
    },
    {
      id: '5',
      category: { id: 'qualityProjects', name: t("navQualityProject")},
      name: t('projects1.5.name'),
      customer: t('projects1.6.customer'),
      workScope: t('projects1.6.workScope'),
      geographicalScope:'',
      executionYear:'',
      startDate:'22/05/2024',
      status: t('projects1.5.status'),
      description: t('projects1.6.description'),
      images: [project5_1,project5_2,project5_3]
    },
    {
      id: '6',
      category: { id: 'qualityProjects', name: t("navQualityProject")},
      name: t('projects1.6.name'),
      customer: t('projects1.6.customer'),
      workScope: t('projects1.6.workScope'),
      geographicalScope:'',
      executionYear:'',
      startDate:'01/08/2024',
      status: t('projects1.6.status'),
      description: t('projects1.6.description'),
      images: [project6_1,project6_2,project6_3]
    },
    {
      id: '7',
      category: { id: 'structuralProjects', name: t("navBuildingProject")},
      name: t('projects1.7.name'),
      customer: t('projects1.6.customer'),
      workScope:  t('projects1.6.workScope'),
      geographicalScope:'',
      executionYear:'2022',
      startDate:'',
      status:'',
      description: t('projects1.6.description'),
      images: [project7_1,project7_2,project7_3]
    },
    {
      id: '8',
      category: { id: 'structuralProjects', name: t("navBuildingProject")},
      name: t('projects1.8.name'),
      customer: t('projects1.6.customer'),
      workScope:  t('projects1.6.workScope'),
      geographicalScope: t('projects1.8.geographicalScope'),
      executionYear:'2021',
      startDate:'',
      status:'',
      description: t('projects1.6.description'),
      images: [project8_1,project8_2,project8_3]
    },
    {
      id: '9',
      category: { id: 'structuralProjects', name: t("navBuildingProject")},
      name: t('projects1.9.name'),
      customer: t('projects1.6.customer'),
      workScope:  t('projects1.6.workScope'),
      geographicalScope: t('projects1.9.geographicalScope'),
      executionYear:'2023',
      startDate:'',
      status:'',
      description: t('projects1.6.description'),
      images: [project9_1,project9_2,project9_3]
    },
  ]

  const linkClick = (linkId) => {
    setActiveLink(linkId)
  }


  return <>
  {site==='company'? '':<div className="header-img py-5">
      <div className='w-90 mx-auto d-flex h-100 flex-column align-items-center justify-content-center'>
        <p className='fs-5 fw-medium text-black d-flex align-items-center text-center justify-content-center py-3 lh-lg'>{t('projectsIntro1')}<br />{t('projectsIntro2')}</p>
      </div>
    </div>}
    <div className={`container-md w-90 ${site==='company'? 'px-0 py-5 mt-lg-4':'pb-5 d-flex flex-column align-items-center'} mx-auto`}>
      {site==='company'? '':<ul className={`nav text- my-5 ${site==='company'? 'pt-4 w-100 justify-content-center':'justify-content-center'} ${language==='en'? '':'flex-row-reverse' } d-none d-md-flex`}>
      {projectsName.map((project) => (
          <li className="nav-item" key={project.id}>
            <Link to={`/projects/${project.id}`} className={`nav-link nav-link-collapse text-decoration-none border-bottom text-center py-3 ${site==='company'? 'px-2 font-small':'px-4 fs-6'} ${activeLink === project.id ? 'active' : ''}`}>{project.name}</Link>
          </li>
        ))}
      </ul>}
      <div className={`d-md-none text-center mt-md-5 mt-3 py-4`}>
        <select className={`rounded-2 border-0 bg-body-secondary p-3 dropdown-select w-100 ${language === 'en' ? '' : 'text-end'} cursor-pointer`}
          value={activeLink}
          onChange={(e) => linkClick(e.target.value)}
        >
          <option value="" disabled>{t('select')}</option>
          {(site==='company'? projectsName2:projectsName).map((project) => (
            <option key={project.id} value={project.id}>
              {project.name}
            </option>
          ))}
        </select>
      </div>
      <div className={`row w-100 g-5 px-0 py-2 ${language==='en'? '':'flex-row-reverse'}`}>
        {(site==='company'? companyProjects:data).filter((item) => (site==='company'? item.category.id === activeLink : activeLink === 'all' || item.category.id === activeLink))
          .map((item) => (
            <div id={item.category.id} key={item.id} className={`${site==='company'?'col-xxxl-3 col-xxl-4':''} col-lg-6`}>
                <Link to={`/projectDetails/${item.id}`} className='position-relative project trans3'>
                  <div className='vw-20'>
                    <img loading='lazy' className='w-100 h-100 object-center object-cover' src={item.images[0]} alt={item.name} />
                  </div>
                  <div className={ `position-absolute py-3 overflow-hidden linear-gradient-card z-1 top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-end ${language==='en'? '':'text-end'}`}>
                    <h5 className='text-white px-sm-5 px-3 pb-2 fs-6 project-name fw-semibold'>{item.name}</h5>
                    {/* <p className='text-white px-sm-5 px-3 pt-4 d-sm-flex d-none'>{item.description}</p> */}
                    <button className={`btn text-white text-hover px-sm-5 px-3 py-sm-4 py-2 d-flex w-100 ${language === 'en'? 'flex-row-reverse':''} justify-content-end align-items-center`}><i className={`fa-solid fa-arrow-left ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i><p>{t('details')}</p></button>
                  </div>
                </Link>
              </div>
          ))}
      </div>
    </div>
  </>
}
