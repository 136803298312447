import React, { useContext, useEffect, useState } from 'react'
// import style from './Examples.module.css'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../context/LangContext'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import example1_1 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 1/000تاثير جديد.webp'
import example1_2 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 1/020.webp'
import example1_3 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 1/3.webp'
import example1_4 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 1/5.webp'
import example1_5 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 1/56.webp'
import example1_6 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 1/5_16 - Photo.webp'
import example1_7 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 1/خدمة السيارت 1.webp'
import example1_8 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 1/كوفي.webp'
import example1_9 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 1/واجهة عامة.webp'
import example2_1 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 2/00001.webp'
import example2_2 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 2/03.webp'
import example2_3 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 2/ال مترك_10 - Photo.webp'
import example2_4 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 2/ال مترك_13 - Photo.webp'
import example2_5 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 2/ال مترك_17 - Photo.webp'
import example2_6 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 2/ال مترك_220 - Photo.webp'
import example2_7 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 2/ال مترك_6 - Photo.webp'
import example2_8 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 2/ال مترك_7 - Photo.webp'
import example2_9 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 2/ال مترك_8 - Photo.webp'
import example3_1 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 3/1.webp'
import example3_2 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 3/ديزل.webp'
import example3_3 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 3/محطة بندر دباس_10 - Photo.webp'
import example3_4 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 3/محطة بندر دباس_11 - Photo.webp'
import example3_5 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 3/محطة بندر دباس_15 - Photo.webp'
import example3_6 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 3/محطة بندر دباس_2 - Photo.webp'
import example3_7 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 3/محطة بندر دباس_6 - Photo.webp'
import example3_8 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 3/محطة بندر دباس_8 - Photo.webp'
import example3_9 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 3/محطة بندر دباس_9 - Photo.webp'
import example4_1 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 4/010.webp'
import example4_2 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 4/013.webp'
import example4_3 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 4/06.webp'
import example4_4 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 4/07.webp'
import example4_5 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 4/08.webp'
import example4_6 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 4/09.webp'
import example4_7 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 4/59.webp'
import example4_8 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 4/61.webp'
import example4_9 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 4/62.webp'
import example5_1 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 5/14.webp'
import example5_2 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 5/15.webp'
import example5_3 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 5/6.webp'
import example5_4 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 5/63.webp'
import example5_5 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 5/64.webp'
import example5_6 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 5/65.webp'
import example5_7 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 5/66.webp'
import example5_8 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 5/67.webp'
import example5_9 from '../../assets/images/services/الاستشارات/architectural/1/5/محطة وقود 5/69.webp'
import exampleVilla1_1 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 1/0001.webp'
import exampleVilla1_2 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 1/03.webp'
import exampleVilla1_3 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 1/27.webp'
import exampleVilla1_4 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 1/28.webp'
import exampleVilla1_5 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 1/30.webp'
import exampleVilla2_1 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 2/00_2 - Ext Main View.webp'
import exampleVilla2_2 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 2/00_6 - Ext Detail.webp'
import exampleVilla2_3 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 2/فيلا عبدالله التوم_2 - Ext Main View.webp'
import exampleVilla2_4 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 2/فيلا عبدالله التوم_3 - Ext Detail.webp'
import exampleVilla2_5 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 2/فيلا عبدالله التوم_4 - Ext Detail.webp'
import exampleVilla2_6 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 2/فيلا عبدالله التوم_5 - Ext Crossing.webp'
import exampleVilla3_1 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 3/1.webp'
import exampleVilla3_2 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 3/2.webp'
import exampleVilla3_3 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 3/3.webp'
import exampleVilla3_4 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 3/4.webp'
import exampleVilla3_5 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 3/7.webp'
import exampleVilla3_6 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 3/8.webp'
import exampleVilla3_7 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 3/ابوفيصل_7 - Photo.webp'
import exampleVilla4_1 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 4/11.webp'
import exampleVilla4_2 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 4/24.webp'
import exampleVilla4_3 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 4/25.webp'
import exampleVilla4_4 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 4/26.webp'
import exampleVilla4_5 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 4/9.webp'
import exampleVilla5_1 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 5/005.webp'
import exampleVilla5_2 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 5/35.webp'
import exampleVilla5_3 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 5/36.webp'
import exampleVilla5_4 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 5/37.webp'
import exampleVilla6_1 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 6/48.webp'
import exampleVilla6_2 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 6/49.webp'
import exampleVilla6_3 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 6/50.webp'
import exampleVilla6_4 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 6/51.webp'
import exampleVilla6_5 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 6/فيلا المبيرك_22 - Photo.webp'
import exampleVilla7_1 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 7/1000.webp'
import exampleVilla7_2 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 7/20.webp'
import exampleVilla7_3 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 7/نهائي 1_Photo -0 7.webp'
import exampleVilla7_4 from '../../assets/images/services/الاستشارات/architectural/1/6/فيلا 7/نهائي 1_Photo -08.webp'
import exampleVilla8_1 from '../../assets/images/services/الاستشارات/architectural/1/6/نموذج 8/10.webp'
import exampleVilla8_2 from '../../assets/images/services/الاستشارات/architectural/1/6/نموذج 8/30.webp'
import exampleVilla8_3 from '../../assets/images/services/الاستشارات/architectural/1/6/نموذج 8/40.webp'
import exampleVilla8_4 from '../../assets/images/services/الاستشارات/architectural/1/6/نموذج 8/60.webp'
import exampleVilla8_5 from '../../assets/images/services/الاستشارات/architectural/1/6/نموذج 8/70.webp'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

export default function Examples() {

    const [examples, setExamples] = useState('')
    const { t } = useTranslation()
    let {language}= useContext(LangContext)
    const site = sessionStorage.getItem('desiredPage')
    let {id} = useParams()

    const examplesDesign = [
      {
        id:'1',
        name:'تصميم مجموعة من محطات الوقود',
        category: { id: 'architecturalProjects', name: t("navArchitectural")},
        projects:[
          {
            name:'محطة وقود 1',
            images:[example1_1,example1_2,example1_3,example1_4,example1_5,example1_6,example1_7,example1_8,example1_9]
          },
          {
            name:'محطة وقود 2',
            images:[example2_1,example2_2,example2_3,example2_4,example2_5,example2_6,example2_7,example2_8,example2_9]
          },
          {
            name:'محطة وقود 3',
            images:[example3_1,example3_2,example3_3,example3_4,example3_5,example3_6,example3_7,example3_8,example3_9]
          },
          {
            name:'محطة وقود 4',
            images:[example4_1,example4_2,example4_3,example4_4,example4_5,example4_6,example4_7,example4_8,example4_9]
          },
          {
            name:'محطة وقود 5',
            images:[example5_1,example5_2,example5_3,example5_4,example5_5,example5_6,example5_7,example5_8,example5_9]
          },
        ]
      },
      {
        id:'2',
        name:'تصميم العديد من الفلل والعمائر السكنية',
        category: { id: 'architecturalProjects', name: t("navArchitectural")},
        projects:[
          {
            name:'فيلا 1',
            images:[exampleVilla1_1,exampleVilla1_2,exampleVilla1_3,exampleVilla1_4,exampleVilla1_5]
          },
          {
            name:'فيلا 2',
            images:[exampleVilla2_1,exampleVilla2_2,exampleVilla2_3,exampleVilla2_4,exampleVilla2_5,exampleVilla2_6]
          },
          {
            name:'فيلا 3',
            images:[exampleVilla3_1,exampleVilla3_2,exampleVilla3_3,exampleVilla3_4,exampleVilla3_5,exampleVilla3_6,exampleVilla3_7]
          },
          {
            name:'فيلا 4',
            images:[exampleVilla4_1,exampleVilla4_2,exampleVilla4_3,exampleVilla4_4,exampleVilla4_5]
          },
          {
            name:'فيلا 5',
            images:[exampleVilla5_1,exampleVilla5_2,exampleVilla5_3,exampleVilla5_4]
          },
          {
            name:'فيلا 6',
            images:[exampleVilla6_1,exampleVilla6_2,exampleVilla6_3,exampleVilla6_4,exampleVilla6_5]
          },
          {
            name:'فيلا 7',
            images:[exampleVilla7_1,exampleVilla7_2,exampleVilla7_3,exampleVilla7_4]
          },
          {
            name:'نموذج 8',
            images:[exampleVilla8_1,exampleVilla8_2,exampleVilla8_3,exampleVilla8_4,exampleVilla8_5]
          },
        ]
      }
    ]

    const getExamples = () =>{
      setExamples(examplesDesign[id-1])
    }

    useEffect(()=>{
      getExamples()
    },[])

  return <>
  <div dir={language==='en'? '':'rtl'} className="header-img py-5">
    <div className='w-90 mx-auto d-flex h-100 flex-column align-items-center justify-content-center'>
      <div className={`flex-wrap justify-content-center ${site==='company'? 'lh-md fw-bold d-flex':'d-md-flex d-none'}`}>
        <Link to={'/'}className='text-hover trans3'>{t('navHome')}<span className='text-black'>&nbsp;/&nbsp;</span></Link>
        <Link to={`/projects/${examples.category?.id}`} className='text-hover trans3 text-nowrap'>{examples.category?.name}<span className='text-black'>&nbsp;/&nbsp;</span></Link>
        <p className='text-secondary text-center'>{examples.name}</p>
      </div>
    </div>
  </div> 
  <div dir={language==='en'? '':'rtl'} className='container-md w-90 mx-auto pb-5'>
    {examples.projects?.map((project, index) =><div key={index}>
      <p className='pt-5 mb-4 fs-5 text-main2 fw-bold position-relative w-fit line3'>{project.name}</p>
      <Slider
        dots={true}
        autoplay={true}
        infinite={true}
        speed={2000}
        autoplaySpeed= {4500}
        initialSlide= {0}
        slidesToShow={3}
        slidesToScroll={3}
        rtl={language==='en'? false:true}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]}
      >
      {project.images.map((image) => (
          <div key={image.id} className='px-2'>
            <img loading='lazy' className='w-100' src={image} alt={project.name} />
          </div>
        ))}
        </Slider>
    </div>)}
  </div>
  </>
}
