import React, { useContext, useEffect, useState } from 'react'
import { LangContext } from '../components/context/LangContext'
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/autoplay"
import 'swiper/css/pagination'
import 'swiper/swiper-bundle.css';
import { EffectFade , Autoplay ,Pagination } from 'swiper/modules'
import Marquee from 'react-fast-marquee';
import serviceLab1_1 from '../assets/images/services/المختبرات/فحص وتقييم المباني صورة 2.webp'
import serviceLab1_2 from '../assets/images/services/المختبرات/مشروع تبوك صورة 4.webp'
import serviceLab2_1 from '../assets/images/services/المختبرات/اختبار عينات كور خرساني.webp'
import serviceLab2_2 from '../assets/images/services/المختبرات/أخذ كور من طبقة اسفلتية 1.webp'
import serviceLab3_1 from '../assets/images/services/المختبرات/حفارات9.webp'
import serviceLab3_2 from '../assets/images/services/المختبرات/حفارات 8.webp'
import serviceLab4_1 from '../assets/images/services/المختبرات/pic1.webp'
import serviceLab4_2 from '../assets/images/services/المختبرات/pic3.webp'
import serviceLab5_1 from '../assets/images/services/المختبرات/دراسات فنية/فقرة الدرسات الفنية 2.webp'
import serviceLab5_2 from '../assets/images/services/المختبرات/دراسات فنية/فقرة الدرسات الفنية الخاصة.webp'
import serviceCompany1_1 from '../assets/images/services/الاستشارات/5.webp'
import serviceCompany1_2 from '../assets/images/services/الاستشارات/ابوبندر_page-0001.webp'
import serviceCompany2_1 from '../assets/images/services/الاستشارات/WhatsApp Image 2024-07-25 at 9.13.18 AM.webp'
import serviceCompany2_2 from '../assets/images/services/الاستشارات/WhatsApp Image 2024-07-25 at 9.00.05 AM.webp'
import serviceCompany3_1 from '../assets/images/services/الاستشارات/IMG-20230417-WA0026.webp'
import serviceCompany3_2 from '../assets/images/services/الاستشارات/WhatsApp Image 2023-04-01 at 15.30.45.webp'
import serviceCompany4_1 from '../assets/images/services/الاستشارات/IMG-20241121-WA0020.webp'
import serviceCompany4_2 from '../assets/images/services/الاستشارات/DSCN1225.webp'
import serviceCompany5_1 from '../assets/images/services/الاستشارات/WhatsApp Image 2024-03-05 at 7.40.00 AM (1).webp'
import serviceCompany5_2 from '../assets/images/services/الاستشارات/pan and profile road0_page-0001.webp'
import serviceCompany6_1 from '../assets/images/services/الاستشارات/WhatsApp Image 2024-07-29 at 9.19.40 AM.webp'
import serviceCompany6_2 from '../assets/images/services/الاستشارات/WhatsApp Image 2024-09-30 at 11.16.56_65601b4d.webp'
import serviceCompany7_1 from '../assets/images/services/الاستشارات/WhatsApp Image 2024-09-22 at 16.51.39_0cd91c02.webp'
import serviceCompany7_2 from '../assets/images/services/الاستشارات/WhatsApp Image 2024-07-28 at 8.49.54 PM.webp'
import serviceCompany8_1 from '../assets/images/services/الاستشارات/WhatsApp Image 2022-06-23 at 1.09.57 PM.webp'
import serviceCompany8_2 from '../assets/images/services/الاستشارات/مشروع تبوك صورة 4.webp'
import bgCompany1 from '../assets/images/Screenshot 2024-11-14 105010.webp'
import bgCompany2 from '../assets/images/skyline-1925943.webp'
import bgCompany3 from '../assets/images/pexels-kindelmedia-9716235.webp'
import bgCompany4 from '../assets/images/pexels-thirdman-8482551.webp'
import bgCompany5 from '../assets/images/pexels-mike-van-schoonderwalt-1884800-5505119.webp'
import bgCompany7 from '../assets/images/pexels-sash2s-17184744.webp'
import bgCompany8 from '../assets/images/skyline-1925943.webp'
import bgCompany9 from '../assets/images/pexels-thirdman-8482551.webp'
import bgCompany10 from '../assets/images/pexels-jeshoots-com-147458-834892 (1).webp'
import bgLab1 from '../assets/images/صور الواجهة الرئيسية/03.webp'
import bgLab2 from '../assets/images/صور الواجهة الرئيسية/أعمال جسات تربة بجانب مسجد قباء  بالمدينة المنورة.webp'
import bgLab3 from '../assets/images/صور الواجهة الرئيسية/أحدى حفارات المكيال السعودي.webp'
import bgLab4 from '../assets/images/صور الواجهة الرئيسية/اختبار دك التربة بالجهاز النووي.webp'
import bgLab5 from '../assets/images/صور الواجهة الرئيسية/اختبار قياس مقاومة الضغط للعينات الخرسانة1.webp'
import bgLab6 from '../assets/images/صور الواجهة الرئيسية/اختبار مقاومة الضغط للخرسانة.webp'
import bgLab7 from '../assets/images/صور الواجهة الرئيسية/اخذ قياسات عينة كور صخرية.webp'
import bgLab8 from '../assets/images/صور الواجهة الرئيسية/الخبر 6.webp'
import bgLab9 from '../assets/images/صور الواجهة الرئيسية/04.webp'
import bgLab10 from '../assets/images/صور الواجهة الرئيسية/الرياض1.webp'
import bgLab11 from '../assets/images/صور الواجهة الرئيسية/تجهيز عينة تربة لاختبار حدود أتربرغ.webp'
import bgLab12 from '../assets/images/صور الواجهة الرئيسية/صورة جماعية 1.webp'
import bgLab13 from '../assets/images/صور الواجهة الرئيسية/فني مختبر 7.webp'
import bgLab14 from '../assets/images/صور الواجهة الرئيسية/WhatsApp Image 2025-02-11 at 9.07.19 PM.jpeg'
import bgLab15 from '../assets/images/صور الواجهة الرئيسية/WhatsApp Image 2025-02-11 at 9.11.04 PM (1).jpeg'
import bgLab16 from '../assets/images/صور الواجهة الرئيسية/WhatsApp Image 2025-02-11 at 9.14.50 PM.jpeg'
import buildings from '../assets/images/buildings1.webp'
import city from '../assets/images/city.webp'
import government from '../assets/images/bank1.webp'
import crane from '../assets/images/crane1.webp'
import land from '../assets/images/spatial1.webp'
import water from '../assets/images/waste-water1.webp'
import electricity from '../assets/images/electrical 1.webp'
import road from '../assets/images/road (1).webp'
import beam from '../assets/images/beam1.webp'
import supervision from '../assets/images/supervision 1.webp'
import rain from '../assets/images/graph 1.webp'
import statistic from '../assets/images/statistic.webp'
import government2 from '../assets/images/government (1).webp'
import companies from '../assets/images/secure-shield.webp'
import plan from '../assets/images/urban-planning.webp'
import residential from '../assets/images/home-interface-symbol-with-a-window-of-squares.webp'
import report from '../assets/images/writing.webp'
import city2 from '../assets/images/city1.webp'
import bridge from '../assets/images/golden-gate-bridge.webp'
import studies from '../assets/images/requirement.webp'
import geotechnical from '../assets/images/construction.webp'
import control from '../assets/images/settings (1).webp'
import ground from '../assets/images/search.webp'
import { useTranslation } from 'react-i18next'
import AOS from 'aos';
import project1_1 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع دراسة التربة لمخططات المحور الجنوبي/03.webp'
import project1_2 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع دراسة التربة لمخططات المحور الجنوبي/اختبار test pit.webp'
import project1_3 from '../assets/images/صور صفحة المشاريع والأعمال/مشروع دراسة التربة لمخططات المحور الجنوبي/حفارات 8.webp'
import project2_1 from '../assets/images/صور صفحة المشاريع والأعمال/صور مشروع الوديان/حفارات 5.webp'
import project2_2 from '../assets/images/صور صفحة المشاريع والأعمال/صور مشروع الوديان/حفارات 6.webp'
import project2_3 from '../assets/images/صور صفحة المشاريع والأعمال/صور مشروع الوديان/مشروع الوديان.webp'
import projectDesign1_1 from '../assets/images/services/الاستشارات/architectural/1/1/15.webp'
import projectHydro1_1 from '../assets/images/services/الاستشارات/hydrological/projects/1/elahmar.webp'
import projectGis1_1 from '../assets/images/services/الاستشارات/gis/المشاريع - الأعمال المساحية/01- أعمال الرفع المساحي لوكالة الثروة السمكية لوكالة الزراعة بمنطقة مكه المكرمة/WhatsApp Image 2023-04-01 at 15.30.45.webp'
import projectSuper1_1 from '../assets/images/services/الاستشارات/supervision/المشاريع - الإشراف على التنفيذ/1/IMG-20230802-WA0103.webp'
import projectInfra1_1 from '../assets/images/services/الاستشارات/infrastructure/1/WhatsApp Image 2024-03-05 at 7.39.58 AM.webp'
import projectTrans1_1 from '../assets/images/services/الاستشارات/transport/1/21.webp'
import projectElectrical1_1 from '../assets/images/services/الاستشارات/electrical/1/WhatsApp Image 2024-07-28 at 8.49.54 PM.webp'
import projectEvaluation1_1 from '../assets/images/services/الاستشارات/evaluation/1/020.webp'
import new1 from '../assets/images/blogs/الخبر الأول.jpeg.webp'
import new2 from '../assets/images/blogs/الخبر الثاني.webp'
import new3 from '../assets/images/blogs/الهيئة السعودية للمدن الصناعية ومناطق التقنية.webp'
import clientLabsG1 from '../assets/images/مختبرات الجهات الحكومية/الهيئة السعودية للمياه.webp'
import clientLabsG2 from '../assets/images/مختبرات الجهات الحكومية/مدن.webp'
import clientLabsG3 from '../assets/images/مختبرات الجهات الحكومية/وزارة الشؤون البلدية والاسكان.webp'
import clientLabsG4 from '../assets/images/مختبرات الجهات الحكومية/وزارة البيئة والزراعة.webp'
import clientLabsG5 from '../assets/images/مختبرات الجهات الحكومية/وزارة النقل.webp'
import clientLabsG6 from '../assets/images/مختبرات الجهات الحكومية/وزارة الدفاع.webp'
import clientLabsG7 from '../assets/images/مختبرات الجهات الحكومية/وزارة التعليم.webp'
import clientLabsG8 from '../assets/images/مختبرات الجهات الحكومية/امانة منطقة الرياض.webp'
import clientLabsG9 from '../assets/images/مختبرات الجهات الحكومية/امانة المدينة المنورة.webp'
import clientLabsG10 from '../assets/images/مختبرات الجهات الحكومية/امانة المنطقة الشرقية.webp'
import clientLabsG11 from '../assets/images/مختبرات الجهات الحكومية/العاصمة المقدسة.webp'
import clientLabsG12 from '../assets/images/مختبرات الجهات الحكومية/قوات+أمن+المنشآت+2.webp'
import clientLabsG13 from '../assets/images/مختبرات الجهات الحكومية/1200px-رئاسة_أمن_الدولة.webp'
import clientLabsG14 from '../assets/images/مختبرات الجهات الحكومية/الشركة الوطنية للاسكان.webp'
import clientLabsG15 from '../assets/images/مختبرات الجهات الحكومية/الكهرباء.webp'
import clientLabsG16 from '../assets/images/مختبرات الجهات الحكومية/شعار_شركة_المياه_الوطنية_2021.webp'
import clientLabsG17 from '../assets/images/مختبرات الجهات الحكومية/الحديدية.webp'
import clientLabsG18 from '../assets/images/مختبرات الجهات الحكومية/الجبيل وينبع.webp'
import clientLabsP1 from '../assets/images/مختبرات الجهات الخاصة/شعار روشن.webp'
import clientLabsP2 from '../assets/images/مختبرات الجهات الخاصة/شعار سيسترا.webp'
import clientLabsP3 from '../assets/images/مختبرات الجهات الخاصة/شعار ايجيك.webp'
import clientLabsP4 from '../assets/images/مختبرات الجهات الخاصة/شعار المشاريع الاولى.webp'
import clientLabsP5 from '../assets/images/مختبرات الجهات الخاصة/وثبة.webp'
import clientLabsP6 from '../assets/images/مختبرات الجهات الخاصة/الاعمار والتنسيق.webp'
import clientLabsP7 from '../assets/images/مختبرات الجهات الخاصة/كاب فرانس.webp'
import clientLabsP8 from '../assets/images/مختبرات الجهات الخاصة/فريسينيه.webp'
import clientLabsP9 from '../assets/images/مختبرات الجهات الخاصة/المهنا.webp'
import clientLabsP10 from '../assets/images/مختبرات الجهات الخاصة/مكتب البعد التصميمي.webp'
import clientLabsP11 from '../assets/images/مختبرات الجهات الخاصة/شركة-مجموعة-العمرو-للاستشارات-الهندس.webp'
import clientLabsP12 from '../assets/images/مختبرات الجهات الخاصة/مركز-الشرق-للاستشارات-الهندسية.webp'
import clientLabsP13 from '../assets/images/مختبرات الجهات الخاصة/ميثاق.webp'
import clientLabsP14 from '../assets/images/مختبرات الجهات الخاصة/مكتب المهندس سمير ابو غلية.webp'
import clientLabsP15 from '../assets/images/مختبرات الجهات الخاصة/الرشودي.webp'
import clientLabsP16 from '../assets/images/مختبرات الجهات الخاصة/عمرانيات.webp'
import clientLabsP17 from '../assets/images/مختبرات الجهات الخاصة/اساس ايناس.webp'
import clientLabsP18 from '../assets/images/مختبرات الجهات الخاصة/الدارة.webp'
import clientLabsP19 from '../assets/images/مختبرات الجهات الخاصة/مجموعة تعاهدات القابضة.webp'
import clientLabsP20 from '../assets/images/مختبرات الجهات الخاصة/زوايا العقارية.webp'
import clientLabsP21 from '../assets/images/مختبرات الجهات الخاصة/نجوم السلام.webp'
import clientLabsP22 from '../assets/images/مختبرات الجهات الخاصة/الشرفة.webp'
import clientLabsP23 from '../assets/images/مختبرات الجهات الخاصة/البواردي.webp'
import clientCompanyG1 from '../assets/images/استشارات الجهات الحكومية/أمانة المنطقة الشرقية.webp'
import clientCompanyG2 from '../assets/images/استشارات الجهات الحكومية/أمانة منطقة الرياض.webp'
import clientCompanyG3 from '../assets/images/استشارات الجهات الحكومية/أمانة منطقة المدينة المنورة.webp'
import clientCompanyG4 from '../assets/images/استشارات الجهات الحكومية/الجمارك السعودية.webp'
import clientCompanyG5 from '../assets/images/استشارات الجهات الحكومية/الخطوط الحديدية السعودية.webp'
import clientCompanyG6 from '../assets/images/استشارات الجهات الحكومية/الهيئة الملكية للجبيل وينبع.webp'
import clientCompanyG7 from '../assets/images/استشارات الجهات الحكومية/الهيئة الملكية لمدينة الرياض.webp'
import clientCompanyG8 from '../assets/images/استشارات الجهات الحكومية/رئاسة أمن الدولة.webp'
import clientCompanyG9 from '../assets/images/استشارات الجهات الحكومية/وزارة البلديات والإسكان.webp'
import clientCompanyG10 from '../assets/images/استشارات الجهات الحكومية/وزارة البيئة والمياه والزراعة.webp'
import clientCompanyG11 from '../assets/images/استشارات الجهات الحكومية/وزارة التجارة.webp'
import clientCompanyG12 from '../assets/images/استشارات الجهات الحكومية/وزارة التعليم.webp'
import clientCompanyG13 from '../assets/images/استشارات الجهات الحكومية/وزارة الثقافة.webp'
import clientCompanyG14 from '../assets/images/استشارات الجهات الحكومية/وزارة الحرس الوطني.webp'
import clientCompanyG15 from '../assets/images/استشارات الجهات الحكومية/وزارة الدفاع.webp'
import clientCompanyG16 from '../assets/images/استشارات الجهات الحكومية/وزارة النقل.webp'
import clientCompanyP1 from '../assets/images/استشارات الجهات الخاصة/البحر الأحمر العالمية.webp'
import clientCompanyP2 from '../assets/images/استشارات الجهات الخاصة/البيع أو التأجير على الخارطة.webp'
import clientCompanyP3 from '../assets/images/استشارات الجهات الخاصة/الشركة السعودية للكهرباء.webp'
import clientCompanyP4 from '../assets/images/استشارات الجهات الخاصة/الشركة السعودية للنقل الجماعي.webp'
import clientCompanyP5 from '../assets/images/استشارات الجهات الخاصة/القدية.webp'
import clientCompanyP6 from '../assets/images/استشارات الجهات الخاصة/الهيئة السعودية للمياه.webp'
import clientCompanyP7 from '../assets/images/استشارات الجهات الخاصة/جامعة شقراء.webp'
import clientCompanyP8 from '../assets/images/استشارات الجهات الخاصة/شركة الاتصالات السعودية.webp'
import clientCompanyP9 from '../assets/images/استشارات الجهات الخاصة/شركة المياه الوطنية.webp'
import clientCompanyP10 from '../assets/images/استشارات الجهات الخاصة/شركة بوابة الدرعية المحدودة.webp'
import clientCompanyP11 from '../assets/images/استشارات الجهات الخاصة/شركة تطوير للمباني (TBC).webp'
import clientCompanyP12 from '../assets/images/استشارات الجهات الخاصة/مجموعة روشن.webp'
import clientCompanyP13 from '../assets/images/استشارات الجهات الخاصة/مدن.webp'
import clientCompanyP14 from '../assets/images/استشارات الجهات الخاصة/مشروع الرياض الخضراء.webp'
import clientCompanyP15 from '../assets/images/استشارات الجهات الخاصة/نيوم.webp'
import clientCompanyP16 from '../assets/images/استشارات الجهات الخاصة/مصرف الإنماء.webp'
import partner1 from '../assets/images/شركاؤنا/sweco-removebg-preview.webp'
import partner2 from '../assets/images/شركاؤنا/snapedit_1736426469342-removebg-preview.webp'
import partner3 from '../assets/images/شركاؤنا/sbs.webp'
import partner4 from '../assets/images/شركاؤنا/snapedit_1736426476501-removebg-preview.webp'
import partner5 from '../assets/images/شركاؤنا/snapedit_1736426502155-removebg-preview (1).webp'
import architectural from '../assets/images/services/architec (1).png'
import hydrological from '../assets/images/services/water-level.png'
import gis from '../assets/images/services/tripod.png'
import supervisionService from '../assets/images/services/prototype.png'
import infrastructure from '../assets/images/services/blueprint (1).png'
import transport from '../assets/images/services/junction.png'
import electrical from '../assets/images/services/smart-meter.png'
import evaluation from '../assets/images/services/building.png'
import { Link } from 'react-router-dom'

export default function Home() {

  const { t } = useTranslation()
  let {language}= useContext(LangContext)
  const site = sessionStorage.getItem('desiredPage')
  const [clientsLab, setClientsLab] = useState([])

    useEffect(() => {
      AOS.init({ duration: 1000 })
    }, [])

    const bgCompanies = [
      bgCompany1, bgCompany2, bgCompany3, bgCompany4, bgCompany5, bgCompany7, bgCompany8, bgCompany9, bgCompany10,
    ]

    const bgLabs = [
      bgLab1, bgLab2, bgLab3, bgLab4, bgLab5, bgLab6, bgLab7, bgLab8, bgLab9, bgLab10,
      bgLab11, bgLab12, bgLab13,bgLab14, bgLab15, bgLab16
    ]

    const projects = [
      {
        id: '1',
        category: { id: 'geotechnicalProjects', name: t("navGeotechnicalProject")},
        name: t('projects1.1.name'),
        customer: t('projects1.6.customer'),
        workScope: t('projects1.6.workScope'),
        geographicalScope: t('projects1.1.geographicalScope'),
        executionYear:'2024',
        startDate:'',
        status:'',
        description: t('projects1.6.description'),
        images: [project1_1,project1_2,project1_3]
      },
      {
        id: '2',
        category: { id: 'geotechnicalProjects', name: t("navGeotechnicalProject")},
        name: t('projects1.2.name'),
        customer: t('projects1.6.customer'),
        workScope: t('projects1.6.workScope'),
        geographicalScope: t('projects1.2.geographicalScope'),
        executionYear:'2020',
        startDate:'',
        status:'',
        description: t('projects1.6.description'),
        images: [project2_1,project2_2,project2_3]
      },
    ]

    const companyProjects = [
      {
        id: 'architecturalProjects',
        name: t("navArchitectural"),
        images: [projectDesign1_1]
      },
      {
        id: 'hydrologicalProjects',
        name: t("navHydrological"),
        images: [projectHydro1_1]
      },
      {
        id: 'gisProjects',
        name: t("navGIS"),
        images: [projectGis1_1]
      },
      {
        id: 'supervisionProjects',
        name: t("navSupervision"),
        images: [projectSuper1_1]
      },
      {
        id: 'infrastructureProjects',
        name: t("navInfrastructure"),
        images: [projectInfra1_1]
      },
      {
        id: 'transportProjects',
        name: t("navTransport"),
        images: [projectTrans1_1]
      },
      {
        id: 'electricalProjects',
        name: t("navElectrical"),
        images: [projectElectrical1_1]
      },
      {
        id: 'evaluationProjects',
        name: t("navEvaluation"),
        images: [projectEvaluation1_1]
      },
    ]

    // const services = [
    //   {
    //     id: 'architectural',
    //     name: t("navArchitectural"),
    //     image: serviceCompany1_1
    //   },
    //   {
    //     id: 'hydrological',
    //     name: t("navHydrological"),
    //     image: serviceCompany2_1
    //   },
    //   {
    //     id: 'GIS',
    //     name: t("navGIS"),
    //     image: serviceCompany3_1
    //   },
    //   {
    //     id: 'supervision',
    //     name: t("navSupervision"),
    //     image: serviceCompany4_1
    //   },
    //   {
    //     id: 'infrastructure',
    //     name: t("navInfrastructure"),
    //     image: serviceCompany5_1
    //   },
    //   {
    //     id: 'transport',
    //     name: t("navTransport"),
    //     image: serviceCompany6_1
    //   },
    //   {
    //     id: 'electrical',
    //     name: t("navElectrical"),
    //     image: serviceCompany7_1
    //   },
    //   {
    //     id: 'evaluation',
    //     name: t("navEvaluation"),
    //     image: serviceCompany8_1
    //   },
    // ]
        
    const clientsLabsGov = [
      {
        name:t('clientsLabsGov.client1'),
        image:clientLabsG1
      },
      {
        name:t('clientsLabsGov.client2'),
        image:clientLabsG2
      },
      {
        name:t('clientsLabsGov.client3'),
        image:clientLabsG3
      },
      {
        name:t('clientsLabsGov.client4'),
        image:clientLabsG4
      },
      {
        name:t('clientsLabsGov.client5'),
        image:clientLabsG5
      },
      {
        name:t('clientsLabsGov.client6'),
        image:clientLabsG6
      },
      {
        name:t('clientsLabsGov.client7'),
        image:clientLabsG7
      },
      {
        name:t('clientsLabsGov.client8'),
        image:clientLabsG8
      },
      {
        name:t('clientsLabsGov.client9'),
        image:clientLabsG9
      },
      {
        name:t('clientsLabsGov.client10'),
        image:clientLabsG10
      },
      {
        name:t('clientsLabsGov.client11'),
        image:clientLabsG11
      },
      {
        name:t('clientsLabsGov.client12'),
        image:clientLabsG12
      },
      {
        name:t('clientsLabsGov.client13'),
        image:clientLabsG13
      },
      {
        name:t('clientsLabsGov.client14'),
        image:clientLabsG14
      },
      {
        name:t('clientsLabsGov.client15'),
        image:clientLabsG15
      },
      {
        name:t('clientsLabsGov.client16'),
        image:clientLabsG16
      },
      {
        name:t('clientsLabsGov.client17'),
        image:clientLabsG17
      },
      {
        name:t('clientsLabsGov.client18'),
        image:clientLabsG18
      },
    ]
    const clientsLabsPri = [
      {
        name:t('clientsLabsPri.client1'),
        image:clientLabsP1
      },
      {
        name:t('clientsLabsPri.client2'),
        image:clientLabsP2
      },
      {
        name:t('clientsLabsPri.client3'),
        image:clientLabsP3
      },
      {
        name:t('clientsLabsPri.client4'),
        image:clientLabsP4
      },
      {
        name:t('clientsLabsPri.client5'),
        image:clientLabsP5
      },
      {
        name:t('clientsLabsPri.client6'),
        image:clientLabsP6
      },
      {
        name:t('clientsLabsPri.client7'),
        image:clientLabsP7
      },
      {
        name:t('clientsLabsPri.client8'),
        image:clientLabsP8
      },
      {
        name:t('clientsLabsPri.client9'),
        image:clientLabsP9
      },
      {
        name:t('clientsLabsPri.client10'),
        image:clientLabsP10
      },
      {
        name:t('clientsLabsPri.client11'),
        image:clientLabsP11
      },
      {
        name:t('clientsLabsPri.client12'),
        image:clientLabsP12
      },
      {
        name:t('clientsLabsPri.client13'),
        image:clientLabsP13
      },
      {
        name:t('clientsLabsPri.client14'),
        image:clientLabsP14
      },
      {
        name:t('clientsLabsPri.client15'),
        image:clientLabsP15
      },
      {
        name:t('clientsLabsPri.client16'),
        image:clientLabsP16
      },
      {
        name:t('clientsLabsPri.client17'),
        image:clientLabsP17
      },
      {
        name:t('clientsLabsPri.client18'),
        image:clientLabsP18
      },
      {
        name:t('clientsLabsPri.client19'),
        image:clientLabsP19
      },
      {
        name:t('clientsLabsPri.client20'),
        image:clientLabsP20
      },
      {
        name:t('clientsLabsPri.client21'),
        image:clientLabsP21
      },
      {
        name:t('clientsLabsPri.client22'),
        image:clientLabsP22
      },
      {
        name:t('clientsLabsPri.client23'),
        image:clientLabsP23
      },
    ]

    const clientsCompanyGov = [
      {
        name:t('clientsCompanyGov.client1'),
        image:clientCompanyG1
      },{
        name:t('clientsCompanyGov.client2'),
        image:clientCompanyG2
      },{
        name:t('clientsCompanyGov.client3'),
        image:clientCompanyG3
      },{
        name:t('clientsCompanyGov.client4'),
        image:clientCompanyG4
      },{
        name:t('clientsCompanyGov.client5'),
        image:clientCompanyG5
      },{
        name:t('clientsCompanyGov.client6'),
        image:clientCompanyG6
      },{
        name:t('clientsCompanyGov.client7'),
        image:clientCompanyG7
      },{
        name:t('clientsCompanyGov.client8'),
        image:clientCompanyG8
      },{
        name:t('clientsCompanyGov.client9'),
        image:clientCompanyG9
      },{
        name:t('clientsCompanyGov.client10'),
        image:clientCompanyG10
      },{
        name:t('clientsCompanyGov.client11'),
        image:clientCompanyG11
      },{
        name:t('clientsCompanyGov.client12'),
        image:clientCompanyG12
      },{
        name:t('clientsCompanyGov.client13'),
        image:clientCompanyG13
      },{
        name:t('clientsCompanyGov.client14'),
        image:clientCompanyG14
      },{
        name:t('clientsCompanyGov.client15'),
        image:clientCompanyG15
      },{
        name:t('clientsCompanyGov.client16'),
        image:clientCompanyG16}
    ]
       
    const clientsCompanyPri = [
      {
        name:t('clientsCompanyPri.client1'),
        image:clientCompanyP1
      },{
        name:t('clientsCompanyPri.client2'),
        image:clientCompanyP2
      },{
        name:t('clientsCompanyPri.client3'),
        image:clientCompanyP3
      },{
        name:t('clientsCompanyPri.client4'),
        image:clientCompanyP4
      },{
        name:t('clientsCompanyPri.client5'),
        image:clientCompanyP5
      },{
        name:t('clientsCompanyPri.client6'),
        image:clientCompanyP6
      },{
        name:t('clientsCompanyPri.client7'),
        image:clientCompanyP7
      },{
        name:t('clientsCompanyPri.client8'),
        image:clientCompanyP8
      },{
        name:t('clientsCompanyPri.client9'),
        image:clientCompanyP9 
      },{
        name:t('clientsCompanyPri.client10'),
        image:clientCompanyP10 
      },{
        name:t('clientsCompanyPri.client11'),
        image:clientCompanyP11 
      },{
        name:t('clientsCompanyPri.client12'),
        image:clientCompanyP12 
      },{
        name:t('clientsCompanyPri.client13'),
        image:clientCompanyP13 
      },{
        name:t('clientsCompanyPri.client14'),
        image:clientCompanyP14 
      },{
        name:t('clientsCompanyPri.client15'),
        image:clientCompanyP15 
      },{
        name:t('clientsCompanyPri.client16'),
        image:clientCompanyP16}
    ]

  const getClients = () =>{
    if(site==='company'){
      const gov = clientsCompanyGov.map(({ image }) => image)
      const pri = clientsCompanyPri.map(({ image }) => image)
      setClientsLab([...gov,...pri])
    } else{
      const gov = clientsLabsGov.map(({ image }) => image)
      const pri = clientsLabsPri.map(({ image }) => image)
      setClientsLab([...gov,...pri])
    }
  }

  useEffect(()=>{
    getClients()
  })
  
  const partnersCompany = [
    {
      name:t('partners.1'),
      image:partner1,
    },
    {
      name:t('partners.2'),
      image:partner2,
    },
    {
      name:t('partners.3'),
      image:partner3,
    },
    {
      name:t('partners.4'),
      image:partner4,
    },
    {
      name:t('partners.5'),
      image:partner5,
    },
  ]

  return <>
    <div className='position-relative'>

      {/* header */}
      <div className={`${site==='company'? 'vh-91':'rem-61'} rounded-bottom-half position-relative overflow-hidden`}>
        <Swiper className="position-absolute top-0 bottom-0 start-0 end-0"
          modules={[EffectFade, Autoplay]}
          slidesPerView={1}
          effect="fade"
          loop={true}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
          }}
          speed={2000}
        >
          {(site === 'company' ? bgCompanies : bgLabs).map((bg, index) => <SwiperSlide key={index}>
            <img loading='lazy' className='w-100 h-100 object-center object-cover' src={bg} alt="AlMekyal AlSaudi" />
            </SwiperSlide>)}
        </Swiper>

        <div className='bg-black-70 position-absolute top-0 bottom-0 start-0 end-0 z-2'>
          <div data-aos="fade-up" data-aos-delay="300" className="container-lg mt-3 mx-auto d-flex flex-column align-items-center justify-content-center h-100 w-100 text-white">
            <div className={`d-flex align-items-center justify-content-center ${language==='en'? 'flex-row-reverse':''}`}>
              <p className='pe-lg-2 px-1 text-center header-sentence'>{t('headerSentence')}</p>
              <hr className='border border-2 my-auto border-main opacity-100 line-sentence' />
            </div>
            <p className={`display-4 fw-bold py-3 text-center line position-relative d-flex align-items-center justify-content-center flex-column lh-base header-name`}><span>{site === 'company'? t('headerCompanyEngineering'): t('lab1')}</span><span className='text-main'>{site==='company'? t('headerNameCompany'):t('lab2')}&nbsp;</span></p>
            {site==='company'? <div dir={language==='en'? '':'rtl'} data-aos="fade-up" data-aos-delay="700" className='fs-5 pt-5 shadowed-text text-center lh-lg fs-5 header-about'>
              <p>{t("headerAboutCompany1")}<br />{t('headerAboutCompany2')}<br />{t('headerAboutCompany3')}</p>
            </div>:<p dir={language==='en'? '':'rtl'} data-aos="fade-up" data-aos-delay="700" className='fs-5 pt-5 mt-sm-3 shadowed-text text-center lh-lg fs-4 header-about'>{t('headerAboutLabs')}</p>}
            {site==='company'?'':<div className='behind-swiper'></div>}
          </div>
        </div>
      </div>

      {/* services labs */}
      {site==='company'? '':<Swiper data-aos="fade-up" className="position-absolute float-swiper end-0 start-0 container-md w-90 mx-auto d-flex justify-content-between pb-5 z-2 cursor-grab"
          modules={[EffectFade, Autoplay, Pagination]}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          breakpoints={{
            992: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 2,
            },
          }}
          spaceBetween={40}
          direction="horizontal"
          speed={2000}
          pagination={{
            clickable: true,
          }}
        >
          <SwiperSlide className='scale2 cardd shadow-hover rem-35 text-center border border-light-subtle bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
            <hr className='top-line position-absolute z-3 m-0 top-0 end-0 start-0 opacity-100' />
            <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
              <div className="carousel-inner">
                <div className="carousel-item w-100 rem-15 overflow-hidden active">
                  <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany1_1:serviceLab3_1} alt="service" />
                </div>
                <div className="carousel-item w-100 rem-15 overflow-hidden">
                  <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany1_2:serviceLab3_2} alt="service" />
                </div>
              </div>
            </div>
            <h4 className='py-xl-4 py-2 my-2 px-4'>{site==='company'? t('navArchitectural'):t('navGeotechnical')}</h4>
            <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
            <p className='p-4 my-2 text-secondary'>{site==='company'? t('service1CompanySentence'):t('service1LabSentence')}</p>
            <Link to={site==='company'? '/services/architectural':'/services/geotechnical'} className={`btn d-flex ${language === 'en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i><p className='text-body-secondary trans3 text-hover fw-bold'>{t('more')}</p></Link>
          </SwiperSlide>
          <SwiperSlide className='scale2 cardd shadow-hover rem-35 text-center border border-light-subtle bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
            <hr className='top-line position-absolute z-3 m-0 top-0 end-0 start-0 opacity-100' />
            <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
              <div className="carousel-inner">
                <div className="carousel-item w-100 rem-15 overflow-hidden active">
                  <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany2_1:serviceLab2_1} alt="service" />
                </div>
                <div className="carousel-item w-100 rem-15 overflow-hidden">
                  <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany2_2:serviceLab2_2} alt="service" />
                </div>
              </div>
            </div>
            <h4 className='py-xl-4 py-2 my-2 px-4'>{site==='company'? t('navHydrological'):t('navSoil')}</h4>
            <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
            <p className='p-4 my-2 text-secondary'>{site==='company'? t('service2CompanySentence'):t('service3LabSentence')}</p>
            <Link to={site==='company'? '/services/hydrological':'/services/soil'} className={`btn d-flex ${language === 'en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i><p className='text-body-secondary trans3 text-hover fw-bold'>{t('more')}</p></Link>
          </SwiperSlide>
          <SwiperSlide className='scale2 cardd shadow-hover rem-35 text-center border border-light-subtle bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
            <hr className='top-line position-absolute z-3 m-0 top-0 end-0 start-0 opacity-100' />
            <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
              <div className="carousel-inner">
                <div className="carousel-item w-100 rem-15 overflow-hidden active">
                  <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany3_1:serviceLab1_1} alt="service" />
                </div>
                <div className="carousel-item w-100 rem-15 overflow-hidden">
                  <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany3_2:serviceLab1_2} alt="service" />
                </div>
              </div>
            </div>
            <h4 className='py-xl-4 py-2 my-2 px-4'>{site==='company'? t('navGIS'):t('navBuilding')}</h4>
            <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
            <p className='p-4 my-2 text-secondary'>{site==='company'? t('service3CompanySentence'):t('service4LabSentence')}</p>
            <Link to={site==='company'? '/services/GIS':'/services/building'} className={`btn d-flex ${language === 'en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i><p className='text-body-secondary trans3 text-hover fw-bold'>{t('more')}</p></Link>
          </SwiperSlide>
          <SwiperSlide className='scale2 cardd shadow-hover rem-35 text-center border border-light-subtle bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
            <hr className='top-line position-absolute z-3 m-0 top-0 end-0 start-0 opacity-100' />
            <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
              <div className="carousel-inner">
                <div className="carousel-item w-100 rem-15 overflow-hidden active">
                  <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany4_1:serviceLab4_1} alt={site==='company'? t('navSupervision'):t('navChemical')} />
                </div>
                <div className="carousel-item w-100 rem-15 overflow-hidden">
                  <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany4_2:serviceLab4_2} alt={site==='company'? t('navSupervision'):t('navChemical')} />
                </div>
              </div>
            </div>
            <h4 className='py-xl-4 py-2 my-2 px-4'>{site==='company'? t('navSupervision'):t('navChemical')}</h4>
            <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
            <p className='p-4 my-2 text-secondary'>{site==='company'? t('service4CompanySentence'):t('service2LabSentence')}</p>
            <Link to={site==='company'? '/services/supervision':'/services/chemical-analysis'} className={`btn d-flex ${language === 'en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i><p className='text-body-secondary trans3 text-hover fw-bold'>{t('more')}</p></Link>
          </SwiperSlide>
          <SwiperSlide className='scale2 cardd shadow-hover rem-35 text-center border border-light-subtle bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
            <hr className='top-line position-absolute z-3 m-0 top-0 end-0 start-0 opacity-100' />
            <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
              <div className="carousel-inner">
                <div className="carousel-item w-100 rem-15 overflow-hidden active">
                  <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany5_1:serviceLab5_1} alt={site==='company'? t('navInfrastructure'):t('navStudies')} />
                </div>
                <div className="carousel-item w-100 rem-15 overflow-hidden">
                  <img loading='lazy' className='scale2 w-100 h-100' src={site==='company'? serviceCompany5_2:serviceLab5_2} alt={site==='company'? t('navInfrastructure'):t('navStudies')} />
                </div>
              </div>
            </div>
            <h4 className='py-xl-4 py-2 my-2 px-4'>{site==='company'? t('navInfrastructure'):t('navStudies')}</h4>
            <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
            <p className='p-4 my-2 text-secondary'>{site==='company'? t('service5CompanySentence'):t('service5LabSentence')}</p>
            <Link to={site==='company'? '/services/infrastructure':'/services/soil-studies'} className={`btn d-flex ${language === 'en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i><p className='text-body-secondary trans3 text-hover fw-bold'>{t('more')}</p></Link>
          </SwiperSlide>
          {site==='company'? <>
            <SwiperSlide className='scale2 cardd shadow-hover rem-35 text-center border border-light-subtle bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
              <hr className='top-line position-absolute z-3 m-0 top-0 end-0 start-0 opacity-100' />
              <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
                <div className="carousel-inner">
                  <div className="carousel-item w-100 rem-15 overflow-hidden active">
                    <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany6_1} alt="service" />
                  </div>
                  <div className="carousel-item w-100 rem-15 overflow-hidden">
                    <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany6_2} alt="service" />
                  </div>
                </div>
              </div>
              <h4 className='py-xl-4 py-2 my-2 px-4'>{t('navTransport')}</h4>
              <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
              <p className='p-4 my-2 text-secondary'>{t('service6CompanySentence')}</p>
              <Link to={'/services/transport'} className={`btn d-flex ${language === 'en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i><p className='text-body-secondary trans3 text-hover fw-bold'>{t('more')}</p></Link>
            </SwiperSlide>
            <SwiperSlide className='scale2 cardd shadow-hover rem-35 text-center border border-light-subtle bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
            <hr className='top-line position-absolute z-3 m-0 top-0 end-0 start-0 opacity-100' />
            <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
              <div className="carousel-inner">
                <div className="carousel-item w-100 rem-15 overflow-hidden active">
                  <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany7_1} alt="service" />
                </div>
                <div className="carousel-item w-100 rem-15 overflow-hidden">
                  <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany7_2} alt="service" />
                </div>
              </div>
            </div>
            <h4 className='py-xl-4 py-2 my-2 px-4'>{t('navElectrical')}</h4>
            <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
            <p className='p-4 my-2 text-secondary'>{t('service7CompanySentence')}</p>
            <Link to={'/services/electrical'} className={`btn d-flex ${language === 'en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i><p className='text-body-secondary trans3 text-hover fw-bold'>{t('more')}</p></Link>
          </SwiperSlide>
            <SwiperSlide className='scale2 cardd shadow-hover rem-35 text-center border border-light-subtle bg-white pb-xl-5 pb-4 position-relative d-flex align-items-center justify-content-between flex-column'>
            <hr className='top-line position-absolute z-3 m-0 top-0 end-0 start-0 opacity-100' />
            <div className="carousel slide carousel-fade w-100" data-bs-ride="carousel" data-bs-pause="false" data-bs-interval="3000">
              <div className="carousel-inner">
                <div className="carousel-item w-100 rem-15 overflow-hidden active">
                  <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany8_1} alt="service" />
                </div>
                <div className="carousel-item w-100 rem-15 overflow-hidden">
                  <img loading='lazy' className='scale2 w-100 h-100' src={serviceCompany8_2} alt="service" />
                </div>
              </div>
            </div>
            <h4 className='py-xl-4 py-2 my-2 px-4'>{t('navEvaluation')}</h4>
            <hr className='my-0 border-light-subtle opacity-100 w-90 ms-auto' />
            <p className='p-4 my-2 text-secondary'>{t('service8CompanySentence')}</p>
            <Link to={'/services/evaluation'} className={`btn d-flex ${language === 'en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i><p className='text-body-secondary trans3 text-hover fw-bold'>{t('more')}</p></Link>
          </SwiperSlide>
          </>:''}
      </Swiper>}
    </div>
    
      {/* services company */}
    {site==='company'? <div dir={language==='en'? '':'rtl'} className="container-lg w-90 mx-auto py-lg-5 py-4">
      <p className='fs-2 fw-bold text-main text-center mt-3 mb-2'>{t('services')}</p>
      <p className='text-center mb-5 text-main2'>{t('servicesText2Company')}</p>
      <div className="row gx-5 gy-3 py-3 justify-content-center">
        <div className="col-lg-3 col-md-4 col-sm-6">
          <Link to={'/services/architectural'} className='card-service services-cards py-3'>
          <div class="overlay"></div>
            <div className='circle'>
              <img loading='lazy' width="71px" height="76px" src={architectural} alt={t('navArchitectural')} />
            </div>
            <p className='fw-bold text-main2 text-center my-3'>{t('navArchitectural')}</p>
            <p className={`btn d-flex align-items-center justify-content-center`}><span>{t('detailService')}</span><i className={`fa-solid fa-arrow-left text-main font-small ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></p>
          </Link>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6">
          <Link to={'/services/hydrological'} className='card-service services-cards py-3'>
          <div class="overlay"></div>
            <div className='circle'>
              <img loading='lazy' width="71px" height="76px" src={hydrological} alt={t('navHydrological')} />
            </div>
            <p className='fw-bold text-main2 text-center my-3'>{t('navHydrological')}</p>
            <p className={`btn d-flex align-items-center justify-content-center`}><span>{t('detailService')}</span><i className={`fa-solid fa-arrow-left text-main font-small ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></p>
          </Link>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6">
          <Link to={'/services/gis'} className='card-service services-cards py-3'>
          <div class="overlay"></div>
            <div className='circle'>
              <img loading='lazy' width="71px" height="76px" src={gis} alt={t('navGIS')} />
            </div>
            <p className='fw-bold text-main2 text-center my-3'>{t('navGIS')}</p>
            <p className={`btn d-flex align-items-center justify-content-center`}><span>{t('detailService')}</span><i className={`fa-solid fa-arrow-left text-main font-small ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></p>
          </Link>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6">
          <Link to={'/services/supervision'} className='card-service services-cards py-3'>
          <div class="overlay"></div>
            <div className='circle'>
              <img loading='lazy' width="71px" height="76px" src={supervisionService} alt={t('navSupervision')} />
            </div>
            <p className='fw-bold text-main2 text-center my-3'>{t('navSupervision')}</p>
            <p className={`btn d-flex align-items-center justify-content-center`}><span>{t('detailService')}</span><i className={`fa-solid fa-arrow-left text-main font-small ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></p>
          </Link>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6">
          <Link to={'/services/infrastructure'} className='card-service services-cards py-3'>
          <div class="overlay"></div>
            <div className='circle'>
              <img loading='lazy' width="71px" height="76px" src={infrastructure} alt={t('navInfrastructure')} />
            </div>
            <p className='fw-bold text-main2 text-center my-3'>{t('navInfrastructure')}</p>
            <p className={`btn d-flex align-items-center justify-content-center`}><span>{t('detailService')}</span><i className={`fa-solid fa-arrow-left text-main font-small ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></p>
          </Link>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6">
          <Link to={'/services/transport'} className='card-service services-cards py-3'>
          <div class="overlay"></div>
            <div className='circle'>
              <img loading='lazy' width="71px" height="76px" src={transport} alt={t('navTransport')} />
            </div>
            <p className='fw-bold text-main2 text-center my-3'>{t('navTransport')}</p>
            <p className={`btn d-flex align-items-center justify-content-center`}><span>{t('detailService')}</span><i className={`fa-solid fa-arrow-left text-main font-small ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></p>
          </Link>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6">
          <Link to={'/services/electrical'} className='card-service services-cards py-3'>
          <div class="overlay"></div>
            <div className='circle'>
              <img loading='lazy' width="71px" height="76px" src={electrical} alt={t('navElectrical')} />
            </div>
            <p className='fw-bold text-main2 text-center my-3'>{t('navElectrical')}</p>
            <p className={`btn d-flex align-items-center justify-content-center`}><span>{t('detailService')}</span><i className={`fa-solid fa-arrow-left text-main font-small ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></p>
          </Link>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6">
          <Link  to={'/services/evaluation'} className='card-service services-cards py-3'>
          <div class="overlay"></div>
            <div className='circle'>
              <img loading='lazy' width="71px" height="76px" src={evaluation} alt={t('navEvaluation')} />
            </div>
            <p className='fw-bold text-main2 text-center my-3'>{t('navEvaluation')}</p>
            <p className={`btn d-flex align-items-center justify-content-center`}><span>{t('detailService')}</span><i className={`fa-solid fa-arrow-left text-main font-small ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></p>
          </Link>
        </div>
      </div>
    </div>
    :<div className={`behind-swiper2 ${language ==='en' ? 'mb-5':''}`}></div>}

    {/* achievements */}
    <div className="bg-achievements-home w-100 position-relative d-flex align-items-center min-vh-100 py-5">
      <div className='position-absolute top-0 bottom-0 start-0 end-0 min-vh-100 z-n1 opacity-25 img-achievements-home'></div>
        <div className={`row px-0 g-4 justify-content-center ${language==='en'? '':'flex-row-reverse'} container-md w-90 mx-auto align-items-center h-100`}>
          {site==='company'? <> 
          {/* company achievements */}
            <div className="col-12 text-center">
            <div>
              <p className='position-relative line fs-1 fw-bold text-white d-flex align-items-center justify-content-center mt-3 mb-5'>{t('achievements')}</p>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>12+</span>
                    <span>{t('experience')}</span></p>
                  <p className='text-white'>{t('companyAchieve1')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={buildings} alt="buildings" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>42+</span>
                    <span>{t('entity')}</span></p>
                  <p className='text-white'>{t('companyAchieve2')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={government} alt="government" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>1862+</span>
                    <span>{t('buildings')}</span></p>
                  <p className='text-white'>{t('companyAchieve3')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={city} alt="city" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>189+</span>
                    <span>{t('million')}</span></p>
                  <p className='text-white'>{t('companyAchieve4')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={crane} alt="crane" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>98+</span>
                    <span>{t('land')}</span></p>
                  <p className='text-white'>{t('companyAchieve5')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={land} alt="land" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>1320+</span>
                    <span>{t('kilometer')}</span></p>
                  <p className='text-white'>{t('companyAchieve6')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={water} alt="water" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>7+</span>
                    <span>{t('achieveProjects')}</span></p>
                  <p className='text-white'>{t('companyAchieve7')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={electricity} alt="electricity" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>720+</span>
                    <span>{t('kilometer')}</span></p>
                  <p className='text-white'>{t('companyAchieve8')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={road} alt="road" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>128+</span>
                    <span>{t('buildings')}</span></p>
                  <p className='text-white'>{t('companyAchieve9')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={beam} alt="beam" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>153+</span>
                    <span>{t('project')}</span></p>
                  <p className='text-white'>{t('companyAchieve10')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={supervision} alt="supervision" />
              </div>
            </div>
          </div>
          <div className="col-xl-8">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>150+</span>
                    <span>{t('million')}</span></p>
                  <p className='text-white'>{t('companyAchieve11')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-50 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={rain} alt="rain" />
              </div>
            </div>
          </div>
          </>:
          <> {/* labs achievements */}
          <div className="col-12 text-center">
            <div>
              <p className='position-relative line fs-1 fw-bold text-white d-flex align-items-center justify-content-center mt-3 mb-5'>{t('achievements')}</p>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>8+</span>
                    <span>{t('years')}</span></p>
                  <p className='text-white'>{t('labsAchieve1')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={statistic} alt="statistic" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>46+</span>
                    <span>{t('entity')}</span></p>
                  <p className='text-white'>{t('labsAchieve2')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={government2} alt="government" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>280+</span>
                    <span>{t('companies')}</span></p>
                  <p className='text-white'>{t('labsAchieve3')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={companies} alt="companies" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>130+</span>
                    <span>{t('plans')}</span></p>
                  <p className='text-white'>{t('labsAchieve4')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={plan} alt="plan" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2':'fs-1'} lh-1`}>10000+</span></p>
                  <p className='text-white'>{t('labsAchieve5')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={residential} alt="residential" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>11980+</span>
                    <span>{t('reports')}</span></p>
                  <p className='text-white'>{t('labsAchieve6')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={report} alt="report" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>118+</span>
                    <span>{t('towers')}</span></p>
                  <p className='text-white'>{t('labsAchieve7')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={city2} alt="city" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>56+</span>
                    <span>{t('bridges')}</span></p>
                  <p className='text-white'>{t('labsAchieve8')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={bridge} alt="bridge" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>112+</span>
                    <span>{t('technical')}</span></p>
                  <p className='text-white'>{t('labsAchieve9')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={studies} alt="studies" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>156+</span>
                    <span>{t('million')}</span></p>
                  <p className='text-white'>{t('labsAchieve10')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={geotechnical} alt="geotechnical" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>79+</span>
                    <span>{t('project')}</span></p>
                  <p className='text-white'>{t('labsAchieve11')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={control} alt="control" />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div data-aos="zoom-in" className={`w-100 h-100 linear-gradient rounded-3 d-flex ${language==='en'? 'flex-row-reverse':''} align-items-center justify-content-around py-3 text-main-light`}>
              <div className={`border-white ${language==='en'? 'border-start':'text-end border-end'} mx-auto w-75 px-4`}>
                  <p className={`d-flex ${language==='en'? '':'flex-row-reverse'} align-items-center fw-bold pb-3`}>
                    <span className={`${language==='en'? 'fs-2 pe-1':'fs-1 ps-1'} lh-1`}>36+</span>
                    <span>{t('test')}</span></p>
                  <p className='text-white'>{t('labsAchieve12')}</p>
              </div>
              <div className="w-25 text-center">
                <img loading='lazy' className='w-75 mx-auto px-xl-3 px-md-2 px-sm-3 px-1' src={ground} alt="ground" />
              </div>
            </div>
          </div>
          </>}
      </div>
    </div>

    {/* projects */}
    <div className='py-5 container-md w-90 mx-auto d-flex flex-column align-items-center'>
      <p className='position-relative w-100 line fs-2 fw-bold text-main2 d-flex align-items-center justify-content-center mt-3 mb-5'>{t('ourProjects')}</p>
      <p className='fs-3 fw-semibold text-center'>{site==='company'? t('projectTextCompany'): t('projectTextLabs')}</p>
      <div dir={language==='en'? '':'rtl'} className="row w-100 justify-content-center g-xl-5 g-lg-4 g-3 px-0 mt-2 mb-5">
        {(site==='company'? companyProjects: projects).map((project) => <div key={project.id} className={site==='company'? 'col-lg-3 col-md-4 col-sm-6':'col-lg-6'}>
            <Link to={site==='company'? `/projects/${project.id}`:`/projectDetails/${project.id}`} className='position-relative project trans3'>
              <div className={site==='company'? 'square':'portrait'}>
                <img loading='lazy' className='w-100 h-100 object-center object-cover' src={project.images[0]} alt="project" />
              </div>
              <div className="position-absolute py-2 overflow-hidden linear-gradient-card z-1 top-0 bottom-0 start-0 end-0 d-flex align-items-end">
                <div className={`w-100 px-xxl-5 px-xl-4 px-3 ${language === 'en'? '':'text-end'}`}>
                  <h5 className='text-white fs-6 project-name fw-semibold'>{t('achieveProjects')}&nbsp;{project.name}</h5>
                  <button className={`btn text-white px-0 text-hover pt-xl-4 pb-sm-4 pt-2 pb-3 d-flex w-100 align-items-center`}><p>{site==='company'? t('all'):t('details')}</p><i className={`fa-solid fa-arrow-left ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></button>
                </div>
              </div>
            </Link>
          </div>)}
      </div>
      {site==='company'? '':<Link to={'/projects/all'} className={`btn px-5 py-4 fs-5 text-white btn-main2 fw-semibold d-flex ${language === 'en'? 'flex-row-reverse':''} justify-content-end align-items-center`}><i className={`fa-solid fa-arrow-left ${language==='en'? 'rotateY':''} pe-3 pt-1`}></i><p>{t('forMore')}</p></Link>}
    </div>

    {/* clients */}
      {site==='company'? <p className='container-md w-90 mx-auto position-relative line fs-2 fw-bold text-main2 d-flex align-items-center justify-content-center mt-3 mb-5'>{t('ourClients')}</p>:''}
    <Marquee gradient={false} direction="left" pauseOnHover={true} speed={100}>
      {clientsLab.map((client, index) => <div className="d-flex cardd filter-coloured overflow-y-hidden align-items-center justify-content-center h-100 px-lg-5 px-md-4 px-2" key={index}>
          <img loading='lazy' className='scale2 py-2' src={client} alt="Client" style={{ width: '7rem' }} />
        </div>)}
    </Marquee>
    {site==='company'?<>
      <p className='container-md w-90 mx-auto position-relative line fs-2 fw-bold text-main2 d-flex align-items-center justify-content-center mt-3 mb-5'>{t('ourPartners')}</p>
    <Marquee gradient={false} direction="right" pauseOnHover={true} speed={100}>
      {partnersCompany.map((client, index) => <div className="d-flex cardd filter-coloured overflow-y-hidden align-items-center justify-content-center h-100 px-lg-5 px-md-4 px-2 mx-xl-5" key={index}>
          <img loading='lazy' className='scale2 py-2' src={client.image} alt={client.name} style={{ width: '8rem' }} />
        </div>)}
    </Marquee></>:''}

    {/* news */}
    <div className="bg-light">
      <div className='container-md w-90 mx-auto mt-5 py-4'>
        <p className={`text-main mt-5 mb-2 text-uppercase ${language==='en'? 'small':'fs-6'} text-center`}>{t('newsText1')}</p>
        <p className='text-center fs-2 fw-semibold '>{t('newsText2')}</p>
        <div className={`mb-5 mt-1 row gx-lg-5 gy-5 gx-3 justify-content-center ${language === 'en' ? '': 'flex-row-reverse'}`}>
          <div className={`d-flex ${language==='en'? 'justify-content-end':''}`}>
            <Link to={'/media-center'} className={`text-main fw-medium text-hover2 trans3 py-2 border-bottom border-main border-2 pb-2 d-flex w-fit align-items-center ${language==='en'? 'flex-row-reverse':''}`}><i className={`fa-solid fa-arrow-left ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i>{t('seeMore')}</Link>
          </div>
          {site==='company'? '' :<><div data-aos="fade-up" className="col-md-4 col-sm-6 cardd">
            <div className='bg-white scale2 h-100 trans3 shadow-sm'>
              <div>
                <img loading='lazy' className='w-100' src={new1} alt="AlMekyal AlSaudi" />
              </div>
              <div className='p-3 pt-2'>
                <p className={`text-secondary small ${language === 'en' ? '': 'text-end'}`}>12/12/2024</p>
                <p className={`fw-medium py-2 lh-lg ${language === 'en' ? '': 'text-end'}`}>{t('latestNews.1')}</p>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" className="col-md-4 col-sm-6 cardd">
            <div className='bg-white scale2 h-100 trans3 shadow-sm'>
              <div>
                <img loading='lazy' className='w-100' src={new2} alt="AlMekyal AlSaudi" />
              </div>
              <div className='p-3 pt-2'>
                <p className={`text-secondary small ${language === 'en' ? '': 'text-end'}`}>29/12/2024</p>
                <p className={`fw-medium py-2 lh-lg ${language === 'en' ? '': 'text-end'}`}>{t('latestNews.2')}</p>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" className="col-md-4 col-sm-6 cardd">
            <div className='bg-white scale2 h-100 trans3 shadow-sm'>
              <div>
                <img loading='lazy' className='w-100' src={new3} alt="AlMekyal AlSaudi" />
              </div>
              <div className='p-3 pt-2'>
                <p className={`text-secondary small ${language === 'en' ? '': 'text-end'}`}>12/12/2024</p>
                <p className={`fw-medium py-2 lh-lg ${language === 'en' ? '': 'text-end'}`}>{t('latestNews.3')}</p>
              </div>
            </div>
          </div></>}
        </div>
      </div>
    </div>

    {/* Departments */}
    {site==='labs'? <div className='bg-white container-md w-90 mx-auto my-5 py-3'>
      <p className='position-relative text-center line fs-1 fw-bold text-main2 d-flex align-items-center justify-content-center mt-3 mb-5'>{t('departments')}</p>
      <Swiper id='departments' className="d-flex justify-content-between pb-5 cursor-grab"
          modules={[EffectFade, Autoplay, Pagination]}
          slidesPerView={1}
          slidesPerGroup={1}
          loop={true}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          breakpoints={{
            992: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 2,
            },
          }}
          spaceBetween={40}
          direction="horizontal"
          speed={2000}
          pagination={{
            clickable: true,
          }}
          >
            <SwiperSlide className='position-relative py-5'>
              <div className={`start-0 end-0 top-0 ${language==='en'? 'text-end':''} text-body-tertiary opacity-25 z-n1 display-1 fw-bold roboto-font`}>01.</div>
              <div className={`pt-2 d-flex ${language==='en'? '':'flex-row-reverse'}`}>
                <div className='bg-main h-fit rounded-circle'>
                  <i className="fa-solid fa-hard-hat fs-2 circle-icon d-flex align-items-center justify-content-center text-light"></i>
                </div>
                <div className={`${language==='en'? 'ps-3':'text-end pe-3'}`}>
                  <h5 className='fw-semibold fs-5 pb-3'>{t('departmentHeader1')}</h5>
                  <p className='text-secondary'>{t('departmentText1')}</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='position-relative py-5'>
              <div className={`start-0 end-0 top-0 ${language==='en'? 'text-end':''} text-body-tertiary opacity-25 z-n1 display-1 fw-bold roboto-font`}>02.</div>
              <div className={`pt-2 d-flex ${language==='en'? '':'flex-row-reverse'}`}>
                <div className='bg-main h-fit rounded-circle'>
                  <i className="fa-solid fa-check-double fs-2 circle-icon d-flex align-items-center justify-content-center text-light"></i>
                </div>
                <div className={`${language==='en'? 'ps-3':'text-end pe-3'}`}>
                  <h5 className='fw-semibold fs-5 pb-3'>{t('departmentHeader2')}</h5>
                  <p className='text-secondary'>{t('departmentText2')}</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='position-relative py-5'>
              <div className={`start-0 end-0 top-0 ${language==='en'? 'text-end':''} text-body-tertiary opacity-25 z-n1 display-1 fw-bold roboto-font`}>03.</div>
              <div className={`pt-2 d-flex ${language==='en'? '':'flex-row-reverse'}`}>
                <div className='bg-main h-fit rounded-circle'>
                  <i className="fa-solid fa-mountain fs-2 circle-icon d-flex align-items-center justify-content-center text-light"></i>
                </div>
                <div className={`${language==='en'? 'ps-3':'text-end pe-3'}`}>
                  <h5 className='fw-semibold fs-5 pb-3'>{t('departmentHeader3')}</h5>
                  <p className='text-secondary'>{t('departmentText3')}</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='position-relative py-5'>
              <div className={`start-0 end-0 top-0 ${language==='en'? 'text-end':''} text-body-tertiary opacity-25 z-n1 display-1 fw-bold roboto-font`}>04.</div>
              <div className={`pt-2 d-flex ${language==='en'? '':'flex-row-reverse'}`}>
                <div className='bg-main h-fit rounded-circle'>
                  <i className="fa-solid fa-building-circle-check fs-2 circle-icon d-flex align-items-center justify-content-center text-light"></i>
                </div>
                <div className={`${language==='en'? 'ps-3':'text-end pe-3'}`}>
                  <h5 className='fw-semibold fs-5 pb-3'>{t('departmentHeader4')}</h5>
                  <p className='text-secondary'>{t('departmentText4')}</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='position-relative py-5'>
              <div className={`start-0 end-0 top-0 ${language==='en'? 'text-end':''} text-body-tertiary opacity-25 z-n1 display-1 fw-bold roboto-font`}>05.</div>
              <div className={`pt-2 d-flex ${language==='en'? '':'flex-row-reverse'}`}>
                <div className='bg-main h-fit rounded-circle'>
                  <i className="fa-solid fa-flask fs-2 circle-icon d-flex align-items-center justify-content-center text-light"></i>
                </div>
                <div className={`${language==='en'? 'ps-3':'text-end pe-3'}`}>
                  <h5 className='fw-semibold fs-5 pb-3'>{t('departmentHeader5')}</h5>
                  <p className='text-secondary'>{t('departmentText5')}</p>
                </div>
              </div>
            </SwiperSlide>
            
            {/* navigation buttons */}
            {/* <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div> */}
      </Swiper>
    </div>:''}
    
    {/* branches */}
    {site==='company'? '':<div className='min-vh-100 linear-gradient-bg position-relative py-4 d-flex align-items-center'>
      <div className="position-absolute top-0 bottom-0 start-0 end-0 bg-branches z-n1"></div>
      <div className="container-md mx-auto w-90 my-4">
        <p className={`${language==='en'? '':'text-end'} ${site==='company'? 'text-black-50':'text-main2'} fs-3 text-uppercase`}>{t('branches')}</p>
        <p className={`${language==='en'? '':'text-end'} text-black fs-3 fw-bold py-4`}>{site==='company'? t('branchesTextCompany'):t('branchesTextLabs')}</p>
        <Link to={'/contact-us'} className={`d-flex w-100 ${language === 'en'? '':'justify-content-end'}`}>
          <div className={`btn py-2 px-3 fs-5 text-white btn-main fw-semibold d-flex w-fit ${language === 'en'? 'flex-row-reverse':''} justify-content-end align-items-center`}><i className={`fa-solid fa-arrow-left ${language==='en'? 'rotateY':''} pe-3 pt-1`}></i><p>{t('contactUs')}</p></div>
        </Link>
        <div className={`row px-0 ${language === 'en'? '':'flex-row-reverse'} g-4 mt-5`}>
        <div className="col-md-6">
            <div className='bg-light branch h-100 trans3'>
              <Link to='https://maps.app.goo.gl/RXaqrpKpGTGtyKcd7' target='_blank' className={`d-flex flex-column justify-content-between h-100 ${language==='en'? 'align-items-start':'align-items-end text-end'}`}>
                <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3623.395074685985!2d46.756395774596!3d24.74763989976409!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2efe7b6828bd77%3A0xf8810d910a311565!2z2YXZg9iq2Kgg2KfZhNmF2YPZitin2YQg2KfZhNiz2LnZiNiv2Yog2YTZhNmH2YbYr9iz2Kk!5e0!3m2!1sen!2seg!4v1733227479364!5m2!1sen!2seg"
                  className='w-100'
                  style={{ border: 'none' }} 
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Google Map Location"
                ></iframe>
                <div className='py-4 px-lg-3 px-md-1 px-3'>
                  <p className='pb-3 fs-5 fw-medium text-uppercase'>{t('branch1')}</p>
                  <p className='text-secondary'>{t('branch1Detailed')}</p>
                </div>
                  <hr className={`my-0 border-light-subtle border-2 opacity-100 w-90 ${language === 'en'? ' ms-auto':'me-auto'}`} />
                  <button className={`btn d-flex my-4 px-lg-3 px-md-1 px-3 ${language === 'en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i><p className='text-body-secondary trans3 text-hover fw-bold'>{t('navLocationT')}</p></button>
              </Link>
            </div>
          </div>
          {site==='company'? '':<>
        <div className="col-md-6">
          <div className='bg-light branch h-100 trans3'>
            <Link to='https://maps.app.goo.gl/WezG6ZoSgS9uxZK96' target='_blank' className={`d-flex flex-column justify-content-between h-100 ${language==='en'? 'align-items-start':'align-items-end text-end'}`}>
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3633.03599306697!2d39.658640500000004!3d24.4148171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15bdebda9746622b%3A0xe063cea16509cbf9!2z2YXYrtiq2KjYsSDYp9mE2YXZg9mK2KfZhCDYp9mE2LPYudmI2K_ZiiDZhNmB2K3YtSDYp9mE2KrYsdio2Kkg2YjYp9mE2K7Ysdiz2KfZhtipINmI2KPYudmF2KfZhCDYp9mE2KzYs9in2Ko!5e0!3m2!1sen!2seg!4v1734274805999!5m2!1sen!2seg"
                className='w-100'
                style={{ border: 'none' }} 
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map Location"
              ></iframe>
              <div className='py-4 px-lg-3 px-md-1 px-3'>
                <p className='pb-3 fs-5 fw-medium text-uppercase'>{t('branch2')}</p>
                <p className='text-secondary'>{t('branch2Detailed')}</p>
              </div>
              <hr className={`my-0 border-light-subtle border-2 opacity-100 w-90 ${language === 'en'? ' ms-auto':'me-auto'}`} />
              <button className={`btn d-flex my-4 px-lg-3 px-md-1 px-3 ${language === 'en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i><p className='text-body-secondary trans3 text-hover fw-bold'>{t('navLocationT')}</p></button>
            </Link>
          </div>
        </div>
        <div className="col-md-6">
          <div className='bg-light branch h-100 trans3'>
            <Link to='https://maps.app.goo.gl/gZwMGxV9MppRM6NG7' target='_blank' className={`d-flex flex-column justify-content-between h-100 ${language==='en'? 'align-items-start':'align-items-end text-end'}`}>
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3578.5269671489286!2d50.200639699999996!3d26.2445554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49c30065c10ad5%3A0x448c5dd143b5d4a7!2z2YXYrtiq2KjYsSDYp9mE2YXZg9mK2KfZhCDYp9mE2LPYudmI2K_ZiiDZhNmB2K3YtSDYp9mE2KrYsdio2Kkg2Ygg2KfZhNiu2LHYs9in2YbYqSDZiCDYp9i52YXYp9mEINin2YTYrNiz2KfYqi4gQWxNZWt5YWwgQWxTYXVkaSBMYWI!5e0!3m2!1sen!2seg!4v1734274943661!5m2!1sen!2seg"
                className='w-100'
                style={{ border: 'none' }} 
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map Location"
              ></iframe>
              <div className='py-4 px-lg-3 px-md-1 px-3'>
                <p className='pb-3 fs-5 fw-medium text-uppercase'>{t('branch3')}</p>
                <p className='text-secondary'>{t('branch3Detailed')}</p>
              </div>
              <hr className={`my-0 border-light-subtle border-2 opacity-100 w-90 ${language === 'en'? ' ms-auto':'me-auto'}`} />
              <button className={`btn d-flex my-4 px-lg-3 px-md-1 px-3 ${language === 'en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i><p className='text-body-secondary trans3 text-hover fw-bold'>{t('navLocationT')}</p></button>
            </Link>
          </div>
        </div>
        <div className="col-md-6">
          <div className='bg-light branch h-100 trans3'>
            <Link to='https://maps.app.goo.gl/xQqdJn3ziRPshA467' target='_blank' className={`d-flex flex-column justify-content-between h-100 ${language==='en'? 'align-items-start':'align-items-end text-end'}`}>
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3555.035427571613!2d49.615694874675285!3d26.997429056446116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x23e803fbc3eb4219%3A0xc0ea948d6f9da5b9!2z2YXYrtiq2KjYsSDYp9mE2YXZg9mK2KfZhCDYp9mE2LPYudmI2K_ZiiDZhNmB2K3YtSDYp9mE2KrYsdio2Kkg2YjYp9mE2YXZiNin2K8g2KjYp9mE2KzYqNmK2YQ!5e0!3m2!1sen!2seg!4v1734444231457!5m2!1sen!2seg"
                className='w-100'
                style={{ border: 'none' }} 
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map Location"
              ></iframe>
              <div className='py-4 px-lg-3 px-md-1 px-3'>
                <p className='pb-3 fs-5 fw-medium text-uppercase'>{t('branch4')}</p>
                <p className='text-secondary'>{t('branch4Detailed')}</p>
              </div>
              <hr className={`my-0 border-light-subtle border-2 opacity-100 w-90 ${language === 'en'? ' ms-auto':'me-auto'}`} />
              <button className={`btn d-flex my-4 px-lg-3 px-md-1 px-3 ${language === 'en'? 'flex-row-reverse':''} align-items-center justify-content-center`}><i className={`fa-solid fa-arrow-left text-main ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i><p className='text-body-secondary trans3 text-hover fw-bold'>{t('navLocationT')}</p></button>
            </Link>
          </div>
          </div>
         </>
      }
        </div>
      </div>
    </div>}
</>
}
