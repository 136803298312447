import React from 'react';
import ReactDOM from 'react-dom/client';
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import 'aos/dist/aos.css';
import './assets/index.css';
import './assets/media.css'
import App from './App.jsx';
import './assets/i18n';
import reportWebVitals from './reportWebVitals';
import LangContextProvider from './components/context/LangContext.js';
import DesiredPageContextProvider from './components/context/DesiredPageContext.js';
import { ParallaxProvider } from 'react-scroll-parallax';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ParallaxProvider>
      <DesiredPageContextProvider>
        <LangContextProvider>
          <App />
        </LangContextProvider>
      </DesiredPageContextProvider>
  </ParallaxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
