import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { LangContext } from '../context/LangContext'
import { useTranslation } from 'react-i18next'

export default function Footer() {
  const { t } = useTranslation()
  let {language}= useContext(LangContext)
  const [footer, setFooter] = useState(false)
  const location = useLocation()
  const site = sessionStorage.getItem('desiredPage')

  useEffect(() => {
    if (location.pathname === "/select-site") {
      setFooter(false)
    } else {
      setFooter(true)
    }
  }, [location])

  return <>
    {footer? <div className='text-white pt-4 pb-3 bg-main w-100'>
      <div className={`container-lg row gy-5 gx-5 px-0 mx-auto pb-2 ${language === 'en' ? 'flex-row': 'flex-row-reverse'}`}>
        <div className="col-xl-3 col-md-6">
          <div>
            <p className={`fs-5 pb-3 fw-semibold border-bottom border-1 border-secondary-subtle ${language === 'en' ? '': 'text-end'}`}>{t('footerAddress')}</p>
            <p className={`d-flex align-items-center py-3 small ${language === 'en' ? 'flex-row': 'flex-row-reverse'}`}><i className={`fa-solid fa-location-dot ${language === 'en' ? 'pe-2': 'ps-2'}`}></i><span>{t('location')}</span></p>
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3623.395074685985!2d46.756395774596!3d24.74763989976409!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2efe7b6828bd77%3A0xf8810d910a311565!2z2YXZg9iq2Kgg2KfZhNmF2YPZitin2YQg2KfZhNiz2LnZiNiv2Yog2YTZhNmH2YbYr9iz2Kk!5e0!3m2!1sen!2seg!4v1733227479364!5m2!1sen!2seg"
              className='w-100 rounded-4 d-flex'
              style={{ border: 'none' }} 
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Map Location"
            ></iframe>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className='d-flex flex-column justify-content-between h-100'>
            <p className={`fs-5 pb-3 fw-semibold border-bottom border-secondary-subtle ${language === 'en' ? '': 'text-end'}`}>{t('footerWorkHours')}</p>
            <p className={`py-3 fw-medium ${language === 'en' ? '': 'text-end'}`}>{t('footerWelcome')}</p>
            <div className='h-100 small d-flex justify-content-end flex-column'>
              <div className={`d-flex align-items-center justify-content-between ${language === 'en' ? 'flex-row-reverse': ''} border-bottom border-secondary-subtle pb-2 pt-3`}>
                <p dir={language==='en'? '':'rtl'}>{site==='company'? t('footerWeekTimeCompany'):t('footerWeekTimeLabs')}</p>
                <p>{site==='company'? t('footerWeekCompany'):t('footerWeekLabs')}</p>
              </div>
              {site==='company'? '':<div className={`d-flex align-items-center justify-content-between ${language === 'en' ? 'flex-row-reverse': ''} border-bottom border-secondary-subtle pb-2 pt-3`}>
                <p>{t('footerHoliday')}</p>
                <p>{t('footerFriday')}</p>
              </div>}
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div>
            <p className={`fs-5 pb-3 fw-semibold border-bottom border-secondary-subtle ${language === 'en' ? '': 'text-end'}`}>{site === 'company'? t('footerCompany'): t('footerLabs')}</p>
            <p className={`pt-3 fw-light lh-md ${language === 'en' ? '': 'text-end'}`}>{site === 'company' ? t('footerCompanyAbout') : t('footerLabsAbout')}</p>
          </div>
        </div>
        <div className="col-xl-3 col-md-6">
          <div className='d-flex flex-column justify-content-between h-100'>
            <p className={`fs-5 pb-3 fw-semibold border-bottom border-secondary-subtle ${language === 'en' ? '': 'text-end'}`}>{t('footerLinks')}</p>
            <div className={`d-flex flex-column justify-content-between h-100 pt-3 ${language === 'en' ? '': 'text-end'}`}>
              <Link to={'/about'} className='text-white pb-2 fw-semibold'>{t('about')}</Link>
              <Link to={'/services'} className='text-white py-2 fw-semibold'>{t('services')}</Link>
              <Link to={'/projects/all'} className='text-white py-2 fw-semibold'>{site==='company'? t('projects'):t('projectsLabs')}</Link>
              <Link to={'/contact-us'} className='text-white pt-2 fw-semibold'>{t('contactUs')}</Link>
            </div>
          </div>
        </div>
      </div>
      <span className='d-flex align-items-center roboto-font pt-5 justify-content-center w-100 small text-center'>Copyright © ElMekyal AlSaudi 2024 Powered by&nbsp;
        <Link className='fw-semibold text-white' to={'https://mas-itc.com'} target='_blank'> MAS-ITC</Link>
      </span>
    </div>
    :''}
  </>
}