import React, { useContext } from 'react'
// import style from './Soil.module.css'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../context/LangContext'
import soilTest1 from '../../assets/images/services/المختبرات/اختبارات التربة والركام/اختبار دك التربة بالجهاز النووي 1.webp'
import soilTest2 from '../../assets/images/services/المختبرات/اختبارات التربة والركام/03.webp'
import soilTest3 from '../../assets/images/services/المختبرات/اختبارات التربة والركام/04.webp'
import soilTest4 from '../../assets/images/services/المختبرات/اختبارات التربة والركام/_MG_8751.webp'
import soilTest5 from '../../assets/images/services/المختبرات/اختبارات التربة والركام/_MG_8758.webp'
import soilTest6 from '../../assets/images/services/المختبرات/اختبارات التربة والركام/فني مختبر 9.webp'
import soilTest7 from '../../assets/images/services/المختبرات/اختبارات التربة والركام/اختبار لوس أنجلوس لمواد الركام.webp'
import soilTest8 from '../../assets/images/services/المختبرات/اختبارات التربة والركام/تجهيز عينة تربة للاختبار.webp'
import soilTest9 from '../../assets/images/services/المختبرات/اختبارات التربة والركام/فني مختبر 7.webp'
import soilTest10 from '../../assets/images/services/المختبرات/اختبارات التربة والركام/فني مختبر 8.webp'
import soilTest11 from '../../assets/images/services/المختبرات/اختبارات التربة والركام/اختبار التحليل المنخلي للتربة -Sieve Analysis Of Soil.webp'
import soilTest12 from '../../assets/images/services/المختبرات/اختبارات التربة والركام/اختبار نقطة التحميل لعينة صخرية مكسرة Point load test.webp'
import soilTest13 from '../../assets/images/services/المختبرات/اختبارات التربة والركام/WhatsApp Image 2025-02-11 at 9.06.33 PM.jpeg'
import soilTest14 from '../../assets/images/services/المختبرات/اختبارات التربة والركام/WhatsApp Image 2025-02-11 at 9.06.34 PM.jpeg'
import soilTest15 from '../../assets/images/services/المختبرات/اختبارات التربة والركام/WhatsApp Image 2025-02-11 at 9.07.19 PM.jpeg'
import asphaltTest from '../../assets/images/services/المختبرات/اختبارات الاسفلت/Screenshot.webp'
import asphaltTest1 from '../../assets/images/services/المختبرات/اختبارات الاسفلت/أخذ عينة اسفلت - كور من أحد المواقع منطقة المدينة المنورة.webp'
import asphaltTest2 from '../../assets/images/services/المختبرات/اختبارات الاسفلت/أخذ كور من طبقة اسفلتية 1.webp'
import asphaltTest3 from '../../assets/images/services/المختبرات/اختبارات الاسفلت/أخذ كور من طبقة الاسفلت لأخد المواقع.webp'
import asphaltTest4 from '../../assets/images/services/المختبرات/اختبارات الاسفلت/اختبار الكثافة القصوى للاسفلت.webp'
import asphaltTest5 from '../../assets/images/services/المختبرات/اختبارات الاسفلت/اختبار قياس دمك الاسفلت بالجهاز النووي.webp'
import asphaltTest6 from '../../assets/images/services/المختبرات/اختبارات الاسفلت/اختبار مارشال على عينة اسلفت.webp'
import asphaltTest7 from '../../assets/images/services/المختبرات/اختبارات الاسفلت/استخرج عينة اسفلتية من فرن الاستخلاص بالحرق لتحديد نسبة البيتومين.webp'
import asphaltTest8 from '../../assets/images/services/المختبرات/اختبارات الاسفلت/اختبار قياس الثبات لعينة مارشال الاسفلتية.webp'
import asphaltTest9 from '../../assets/images/services/المختبرات/اختبارات الاسفلت/WhatsApp Image 2025-02-11 at 9.08.44 PM.jpeg'
import asphaltTest10 from '../../assets/images/services/المختبرات/اختبارات الاسفلت/WhatsApp Image 2025-02-11 at 9.08.45 PM.jpeg'
import bitumen1 from '../../assets/images/services/المختبرات/اختبارات البيتومين السائل/Bitumen-Sprayer-Truck-Asphalt-Distributor-with-High-Quality.webp'
import concentrate from '../../assets/images/services/المختبرات/اختبارات الخرسانة/image000501.webp'
import concentrate1 from '../../assets/images/services/المختبرات/اختبارات الخرسانة/021.webp'
import concentrate2 from '../../assets/images/services/المختبرات/اختبارات الخرسانة/022.webp'
import concentrate3 from '../../assets/images/services/المختبرات/اختبارات الخرسانة/image00009.webp'
import concentrate4 from '../../assets/images/services/المختبرات/اختبارات الخرسانة/اختبار عينات كور خرساني.webp'
import concentrate5 from '../../assets/images/services/المختبرات/اختبارات الخرسانة/اختبار SLUMP CONE.webp'
import concentrate6 from '../../assets/images/services/المختبرات/اختبارات الخرسانة/اختبار قياس مقاومة الخرسانة.webp'
import concentrate7 from '../../assets/images/services/المختبرات/اختبارات الخرسانة/اختبار قياس مقاومة الضغط للعينات الخرسانة1.webp'
import concentrate8 from '../../assets/images/services/المختبرات/اختبارات الخرسانة/اختبار مقاومة الضغط للخرسانة.webp'
import concentrate9 from '../../assets/images/services/المختبرات/اختبارات الخرسانة/تغليف عينات الكور الخرساني بالكبريت قبل اختبارها بجهاز الكسر.webp'
import concentrate10 from '../../assets/images/services/المختبرات/اختبارات الخرسانة/تجهيز قوالب عينات الخرسانة الاسطوانية بأحد المواقع.webp'
import concentrate11 from '../../assets/images/services/المختبرات/اختبارات الخرسانة/قص وتجهيز عينات اللب الخرساني.webp'
import concentrate12 from '../../assets/images/services/المختبرات/اختبارات الخرسانة/معالجة الخرسانة.webp'
import concentrate13 from '../../assets/images/services/المختبرات/اختبارات الخرسانة/WhatsApp Image 2025-02-11 at 9.12.15 PM (1).jpeg'
import concentrate14 from '../../assets/images/services/المختبرات/اختبارات الخرسانة/WhatsApp Image 2025-02-11 at 9.12.15 PM.jpeg'
import blockTile1 from '../../assets/images/services/المختبرات/اختبارات البلوك/اختبار.webp'
import blockTile2 from '../../assets/images/services/المختبرات/اختبارات البلوك/اختبار الانحناء لعينات البلاط2.webp'
import blockTile3 from '../../assets/images/services/المختبرات/اختبارات البلوك/اختبار مقاومة البلوك.webp'
import penetration from '../../assets/images/services/المختبرات/اختبارات البيتومين السائل/bitumen-penetration-test-apparatus-500x500.webp'
import flashPoint from '../../assets/images/services/المختبرات/اختبارات البيتومين السائل/flash-point-tester-500x500.webp'
import flashDrawing from '../../assets/images/services/المختبرات/اختبارات البيتومين السائل/be9e3ea7d2183918b1e0649b3d36aaca.webp'
import softeningPoint from '../../assets/images/services/المختبرات/اختبارات البيتومين السائل/0023740_softening-point-ring-and-ball-apparatus_600.webp'
import volatility from '../../assets/images/services/المختبرات/اختبارات البيتومين السائل/Rotational-Viscometer-used-for-the-Brookfield-Viscosity-Test-Source-18.webp'
import viscosity from '../../assets/images/services/المختبرات/اختبارات البيتومين السائل/H95542028253843d3bf71fac534aef7e4V.jpg_720x720q50.webp'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Soil() {
  
  const { t } = useTranslation()
  let {language}= useContext(LangContext)

  const soilTestImages = [
    soilTest1, soilTest2, soilTest3, soilTest4, soilTest5, soilTest6, soilTest7, soilTest8, soilTest9, soilTest10, soilTest11, soilTest12, soilTest13, soilTest14, soilTest15,
  ]

  const asphaltTestImages = [
    asphaltTest1, asphaltTest2, asphaltTest3, asphaltTest4, asphaltTest5, asphaltTest6, asphaltTest7, asphaltTest8, asphaltTest9, asphaltTest10,
  ]
  
  const concentrateImages = [
    concentrate1, concentrate2, concentrate3, concentrate4, concentrate5, concentrate6, concentrate7, concentrate8, concentrate9, concentrate10, concentrate11, concentrate12, concentrate13, concentrate14,
  ]
  const bitumenImages = [
    { id:'1',
      name:'penetration test',
      image:penetration
    }, 
    { id:'2',
      name:'flash Point test',
      image:flashPoint
    }, 
    { id:'3',
      name:'flash Drawing test',
      image:flashDrawing
    }, 
    { id:'4',
      name:'softening Point test',
      image:softeningPoint
    }, 
    { id:'5',
      name:'volatility test',
      image:volatility
    }, 
    { id:'6',
      name:'viscosity test',
      image:viscosity
    }
  ]

  return <>
  <div dir={language==='en'? '':'rtl'} className="header-img py-5">
    <div className='w-90 mx-auto d-flex h-100 flex-column align-items-center justify-content-center'>
      <p className='position-relative line fs-2 fw-bold text-black d-flex align-items-center text-center justify-content-center pb-3 mt-3 mb-5'>{t('navSoil')}</p>
    </div>
  </div> 
  <div dir={language==='en'? '':'rtl'} className='container-md w-90 mx-auto pb-4'>
    <section id='soilAggregateTest' className='w-100 pt-5'>
      <div className="row align-items-center mt-md-5">
        <div className="col-xxl-8 col-xl-7 col-lg-6">
          <ul className={`fs-4 fw-bold px-0`}>
            <li className='py-2'>
              <p className='position-relative w-fit line3 text-main2'>1. {t('navSoilAggregateTest')} :</p>
              <ul className='pt-4 pb-2 fs-5 list-style-disc fw-normal'>
                <li className='py-1'>{t('soilFirst.1')}</li>
                <li className='py-1'>{t('soilFirst.2')}</li>
                <li className='py-1'>{t('soilFirst.3')}</li>
                <li className='py-1'>{t('soilFirst.4')}</li>
                <li className='py-1'>{t('soilFirst.5')}</li>
                <li className='py-1'>{t('soilFirst.6')}</li>
                <li className='py-1'>{t('soilFirst.7')}</li>
                <li className='py-1'>{t('soilFirst.8')}</li>
                <li className='py-1'>{t('soilFirst.9')}</li>
                <li className='py-1'>{t('soilFirst.10')}</li>
                <li className='py-1'>{t('soilFirst.11')}</li>
              </ul>
            </li>
            <li className='pt-3'>
              <p>{t('aggregateTesting')} :</p>
              <ul className='pt-4 pb-2 fs-5 list-style-disc fw-normal'>
                <li className='py-1'>{t('aggregateSecond.1')}</li>
                <li className='py-1'>{t('aggregateSecond.2')}</li>
                <li className='py-1'>{t('aggregateSecond.3')}</li>
                <li className='py-1'>{t('aggregateSecond.4')}</li>
                <li className='py-1'>{t('aggregateSecond.5')}</li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="col-xxl-4 col-xl-5 col-lg-6">
          <Slider
            dots={true}
            autoplay={true}
            infinite={true}
            speed={2000}
            autoplaySpeed= {4000}
            initialSlide= {0}
            slidesToShow={3}
            slidesToScroll={3}
            rtl={language==='en'? false:true}
            vertical= {true}
            verticalSwiping= {true}
            responsive={[
              {
                breakpoint: 991,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  vertical: false,
                  verticalSwiping: false,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  vertical: false,
                  verticalSwiping: false,
                },
              },
            ]}
          >
            {soilTestImages.map((image, index) => <div className='px-lg-0 px-2' key={index}>
              <img loading='lazy' className='w-100 rounded-3' src={image} alt="soil tests" />
            </div>)}
          </Slider>
        </div>
      </div>
    </section>

      <section id='asphaltTest' className='w-100 pt-5'>
        <p className='fs-4 fw-bold pt-3 mt-md-5 mb-4 position-relative w-fit line3 text-main2'>2. {t('navAsphaltTest')} :</p>
        <p>{t('asphalt.text1')}</p>
        <p className='fw-bold py-3 fs-5'>{t('asphalt.text2')}</p>
        <div className="px-lg-4 px-2">
          <p className='fs-5 fw-semibold'>{t('asphalt.text3.text')}</p>
          <div className="row pt-4 g-3 px-md-4">
            <div className="col-xl-7 col-lg-6">
              <ul className={`list-style-auto fs-5 ${language==='en'? 'ps-4 pe-0':'pe-4 ps-0'}`}>
                <li className='py-1'>{t('asphalt.text3.1')}</li>
                <li className='py-1'>{t('asphalt.text3.2')}</li>
                <li className='py-1'>{t('asphalt.text3.3')}</li>
                <li className='py-1'>{t('asphalt.text3.4')}</li>
                <li className='py-1'>{t('asphalt.text3.5')}</li>
                <li className='py-1'>{t('asphalt.text3.6')}</li>
                <li className='py-1'>{t('asphalt.text3.7')}</li>
                <li className='py-1'>{t('asphalt.text3.8')}</li>
              </ul>
            </div>
            <div className="col-xl-5 col-lg-6 d-lg-flex d-none">
              <div className='px-2'>
                <img loading='lazy' className='w-100 rounded-3' src={asphaltTest} alt={t('navAsphaltTest')} />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-lg-5 pt-3">
          <p className='fw-bold py-3 fs-5'>{t('asphalt.text4.text')}</p>
          <ul className={`list-style-disc fs-5 ${language==='en'? 'ps-4 pe-0':'pe-4 ps-0'}`}>
            <li className='py-1'>{t('asphalt.text4.1')}</li>
            <li className='py-1'>{t('asphalt.text4.2')}</li>
            <li className='py-1'>{t('asphalt.text4.3')}</li>
            <li className='py-1'>{t('asphalt.text4.4')}</li>
          </ul>
        </div>
        <div className="pt-lg-5 py-3">
          <p className='fw-bold py-3 fs-5'>{t('asphalt.text5.text')}</p>
          <ul className={`list-style-disc fs-5 ${language==='en'? 'ps-4 pe-0':'pe-4 ps-0'}`}>
            <li className='py-1'>{t('asphalt.text5.1')}</li>
            <li className='py-1'>{t('asphalt.text5.2')}</li>
          </ul>
        </div>
        <Slider
          dots={true}
          autoplay={true}
          infinite={true}
          speed={2000}
          autoplaySpeed= {4000}
          initialSlide= {0}
          slidesToShow={3}
          slidesToScroll={3}
          rtl={language==='en'? false:true}
          responsive={[
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {asphaltTestImages.map((image, index) => <div className='px-2 cursor-grab' key={index}>
            <img loading='lazy' className='w-100 rounded-3' src={image} alt="soil tests" />
          </div>)}
        </Slider>
      </section>

      <section id='liquidBitumenTest' className='w-100 pt-5'>
        <p className='fs-4 fw-bold pt-3 mb-4 mt-md-5 position-relative w-fit line3 text-main2'>3. {t('navLiquidBitumenTest')} :</p>
        <p className='fw-bold py-3 fs-5'>{t('bitumenText')}</p>
        <div className="row pt-4 g-lg-3 gx-1 gy-5 px-md-4">
          <div className="col-xxl-8 col-xl-7 col-lg-6">
            <div>
              <ul className={`list-style-disc fs-5 ${language==='en'? 'ps-4 pe-0':'pe-4 ps-0'} mb-0`}>
                <li className='py-1'>{t('penetration')}</li>
                <li className='py-1'>{t('viscosity')}</li>
                <li className='py-1'>{t('ductility')}</li>
                <li className='py-1'>{t('softeningPoint')}</li>
                <li className='py-1'>{t('flashAndFire')}</li>
                <li className='py-1'>{t('specificGravity')}</li>
                <li className='py-1'>{t('solubility')}</li>
                <li className='pt-1'>{t('volatility')}</li>
              </ul>
            </div>
          </div>
          <div className="col-xxl-4 col-xl-5 col-lg-6 d-lg-flex d-none">
            <div className='px-2'>
              <img loading='lazy' className='w-100 rounded-3' src={bitumen1} alt="Bitumen Sprayer Truck Asphalt Distributor" />
            </div>
          </div>
          {bitumenImages.map((image) => <div key={image.id} className="col-md-2 col-sm-4 col-6 pt-md-4">
            <div>
              <img loading='lazy' className='w-100' src={image.image} alt={image.name} />
            </div>
          </div>
          )}
        </div>
      </section>

      <section id='concreteTest' className='w-100 pt-5'>
        <p className='fs-4 fw-bold pt-3 mb-4 mt-lg-5 position-relative w-fit line3 text-main2'>4. {t('navConcreteTest')} :</p>
        <p className='fs-5 py-3 lh-md'>{t('concrete.text1')}<br />{t('concrete.text2')}</p>
        <p className='fw-bold fs-5 py-3'>{t('concrete.text3.text')}</p>
        <div className="row gy-0 g-lg-3 gx-1 pt-3 pb-5 align-items-center">
          <div className="col-xl-7 col-lg-6">
            <ul className={`list-style-auto fs-5 ${language==='en'? 'ps-4 pe-0':'pe-4 ps-0'} mb-0`}>
              <li>
                <p className='pb-3 fw-medium'>{t('concrete.text3.one.name')}</p>
                <ul className={`list-style-disc ${language==='en'? 'ps-4 pe-0':'pe-4 ps-0'}`}>
                  <li className='py-2'>{t('concrete.text3.one.1')}</li>
                  <li className='py-2'>{t('concrete.text3.one.2')}</li>
                  <li className='py-2'>{t('concrete.text3.one.3')}</li>
                  <li className='py-2'>{t('concrete.text3.one.4')}</li>
                  <li className='py-2'>{t('concrete.text3.one.5')}</li>
                </ul>
              </li>
              <li className='fw-medium pt-4'>{t('concrete.text3.two')}</li>
            </ul>
          </div>
          <div className="col-xl-5 col-lg-6 d-lg-flex d-none">
            <div className='px-2'>
              <img loading='lazy' className='w-100 rounded-3' src={concentrate} alt={t('navConcreteTest')} />
            </div>
          </div>
        </div>
        <p className='fw-bold fs-5 py-3'>{t('concrete.text4.text')}</p>
        <ul className={`list-style-auto fs-5 ${language==='en'? 'ps-4 pe-0':'pe-4 ps-0'} mb-0`}>
          <li className='py-3'>
            <p className='pb-3 fw-semibold'>{t('concrete.text4.firstName')}</p>
            <p className='lh-md'>{t('concrete.text4.firstText')}</p>
          </li>
          <li className='py-3'>
            <p className='pb-3 fw-semibold'>{t('concrete.text4.secondName')}</p>
            <p className="lh-md">{t('concrete.text4.secondText')}</p>
          </li>
        </ul>
        <Slider
          dots={true}
          autoplay={true}
          infinite={true}
          speed={2000}
          autoplaySpeed= {4000}
          initialSlide= {0}
          slidesToShow={3}
          slidesToScroll={3}
          rtl={language==='en'? false:true}
          responsive={[
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {concentrateImages.map((image, index) => <div className='px-2 cursor-grab' key={index}>
            <img loading='lazy' className='w-100 rounded-3' src={image} alt="soil tests" />
          </div>)}
        </Slider>
      </section>

      <section id='blockTileTest' className='w-100 pt-5 pb-sm-5 pb-3'>
        <p className='fs-4 fw-bold pt-3 mb-4 mt-md-5 position-relative w-fit line3 text-main2'>5. {t('navBlockTileTest')} :</p>
        <div className="row align-items-center">
          <div className="col-lg-4">
            <ul className={`list-style-disc fs-5 ${language==='en'? 'ps-4 pe-0':'pe-4 ps-0'} mb-0 lh-lg`}>
              <li className='py-1'>{t('block.1')}</li>
              <li className='py-1'>{t('block.2')}</li>
              <li className='py-1'>{t('block.3')}</li>
            </ul>
          </div>
          <div className="col-lg-8 row px-0 pt-4">
            <div className='col-4'>
              <div>
                <img loading='lazy' className='w-100 rounded-3' src={blockTile1} alt={t('navBlockTileTest')} />
              </div>
            </div>
            <div className='col-4'>
              <div>
                <img loading='lazy' className='w-100 rounded-3' src={blockTile3} alt={t('navBlockTileTest')} />
              </div>
            </div>
            <div className='col-4'>
              <div>
                <img loading='lazy' className='w-100 rounded-3' src={blockTile2} alt={t('navBlockTileTest')} />
              </div>
            </div>
          </div>
        </div>
      </section>
  </div>
  </>
}
