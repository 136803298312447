import React, { useContext, useEffect } from 'react'
import style from './Workshops.module.css'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../context/LangContext'
import { Link } from 'react-router-dom'
import image1_1 from '../../assets/images/workshops/1/WH335D~1.webp'
import image1_2 from '../../assets/images/workshops/1/WHATSA~2.webp'
import image1_3 from '../../assets/images/workshops/1/WHATSA~3.webp'
import image1_4 from '../../assets/images/workshops/1/WHATSA~4.webp'
import image2_1 from '../../assets/images/workshops/2/WhatsApp Image 2024-10-01 at 15.04.36_a1825a46.webp'
import image2_2 from '../../assets/images/workshops/2/WhatsApp Image 2024-10-01 at 15.09.44_98e9062e.webp'
import image2_3 from '../../assets/images/workshops/2/WhatsApp Image 2024-10-01 at 15.11.51_79563e77.webp'
import image2_4 from '../../assets/images/workshops/2/WhatsApp Image 2024-10-01 at 15.20.51_d5fba4d1.webp'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import training1 from '../../assets/images/workshops/labs/أحدى ورش التدريب في مختبرات المكيال السعودي لنقل المعرفة وتدريب الكوادر الفنية.webp'
import training2 from '../../assets/images/workshops/labs/أعمال تدريب عملي لتطوير ورفع كفاءة العاملين.webp'
import training3 from '../../assets/images/workshops/labs/جانب من أعمال تدريب وتطوير كفاءة الفنيين في مختبرات المكيال السعودي.webp'
import training4 from '../../assets/images/workshops/labs/جانب من ورش العمل والتدريب لرفع كفاءة فنيي  المختبر في المكيال السعودي.webp'
import training5 from '../../assets/images/workshops/labs/ورشة عمل في أمانة الرياض بخصوص دراسات التربة لمخططات البلديات - المحور الجنوبي.webp'
import training6 from '../../assets/images/workshops/labs/وشة عمل ضمن مشروع اختبارات المواد بالجبيل الصناعية مع الهيئة الملكية بالجبيل.webp'

export default function Workshops() {
    
    const { t } = useTranslation()
    let {language}= useContext(LangContext)
    const site = sessionStorage.getItem('desiredPage')
               
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])

    const imagesOne = [
      image1_1, image1_2, image1_3, image1_4
    ]

    const imagesTwo = [
      image2_3, image2_2, image2_1, image2_4
    ]

    const trainingLabsGoals = [
      {
        id: 1,
        goal: t('trainingLabs.section2.goal1'),
        goalSentence: t('trainingLabs.section2.goalSentence1'),
      },
      {
        id: 2,
        goal: t('trainingLabs.section2.goal2'),
        goalSentence: t('trainingLabs.section2.goalSentence2'),
      },
      {
        id: 3,
        goal: t('trainingLabs.section2.goal3'),
        goalSentence: t('trainingLabs.section2.goalSentence3'),
      },
      {
        id: 4,
        goal: t('trainingLabs.section2.goal4'),
        goalSentence: t('trainingLabs.section2.goalSentence4'),
      },
      {
        id: 5,
        goal: t('trainingLabs.section2.goal5'),
        goalSentence: t('trainingLabs.section2.goalSentence5'),
      },
      {
        id: 6,
        goal: t('trainingLabs.section2.goal6'),
        goalSentence: t('trainingLabs.section2.goalSentence6'),
      },
      {
        id: 7,
        goal: t('trainingLabs.section2.goal7'),
        goalSentence: t('trainingLabs.section2.goalSentence7'),
      },
    ]
      
  const trainingImages = [
    training1, training2, training3, training4, training5, training6
  ]

    const trainingLabsList = [
      t('trainingLabs.section3.text1'),  t('trainingLabs.section3.text2'),  t('trainingLabs.section3.text3'),
      t('trainingLabs.section3.text4'),  t('trainingLabs.section3.text5'),  t('trainingLabs.section3.text6')
    ]

  return <>
    <div dir={language==='en'? '':'rtl'}>
                                        {/* header */}
      <div className="header-img py-5">
        <div className='w-90 mx-auto d-flex h-100 flex-column align-items-center justify-content-center'>
          <p className='position-relative line text-center fs-2 fw-bold text-black d-flex align-items-center justify-content-center pb-3 mt-3 mb-5'>{t('workshopHeader')}</p>
          <div className='d-flex'>
            <Link to={'/'}className='text-hover trans3'>{t('navHome')}</Link>
            <span className='text-black px-2'>/</span>
            <p className='text-secondary'>{site ==='company'? t('navWorkshops'):t("navTrainingLabs")}</p>
          </div>
        </div>
      </div>  
      
                                        {/* content */}
      <div className="container-lg w-90 mx-auto">
        <div className='pt-sm-5 pt-3 mt-3 fs-5 mb-5'>
          <h3 className='text-main2 position-relative line3 text-center w-fit fs-3 fw-bold mb-5'>{site ==='company'? t('navWorkshops'):t("navTrainingLabs")} :</h3>   
          {site==='company'? <>
            <p>{t('workshops.text1')}</p>
            <p className='fw-bold pt-5 pb-3'>{t('workshops.text2')}</p>
            <ol>
              <li className='py-3 fs-6 fw-medium'>
                <p>{t('workshops.workshop1')}</p>
                <div className="row g-4 pt-3">
                  {imagesOne.map((image, index) => <>
                    <div key={index} className="col-lg-3 col-sm-6">
                      <div>
                        <img className='w-100' src={image} alt="" />
                      </div>
                    </div>
                  </>)}
                </div>
              </li>
              <li className='py-4 fs-6 fw-medium'>
                <p>{t('workshops.workshop2')}</p>
                <div className="row g-4 pt-3">
                  {imagesTwo.map((image, index) => <>
                    <div key={index} className="col-lg-3 col-sm-6">
                      <div>
                        <img className='w-100' src={image} alt="" />
                      </div>
                    </div>
                  </>)}
                </div>
              </li>
            </ol>
          </>
          :<>
            <p>{t('trainingLabs.text1')}</p>
          </>}
        </div>
      </div>
      {site==='company'? '':<>
        <div className="bg-light">
        <div className="px-3 container-lg w-90 mx-auto">
          <div className='pt-5 pb-4'>
            <p className='fw-bold fs-5'>{t('trainingLabs.text2')}</p>
            <ul className='py-3 list-style-disc px-md-5 px-3'>
              {trainingLabsGoals.map((goal)=> <li key={goal.id} className='py-2 lh-lg'>
                <div className="d-flex">
                  <p><span className='text-decoration-underline fw-semibold'>{goal.goal} : </span>&nbsp;{goal.goalSentence}</p>
                </div>
              </li>)}
            </ul>
          </div>
        </div>
      </div>
      <div className="px-3 container-lg w-90 mx-auto pb-5">
        <div className='pt-5 pb-4'>
          <p className='fw-bold fs-5'>{t('trainingLabs.text3')}</p>
          <div className='py-3 px-md-3 px-3'>
            {trainingLabsList.map((item , index)=> <p key={index} className='fw-normal py-2 lh-lg'>
              <i className={`fa-solid fa-check text-main ${language==='en'? 'pe-2':'ps-2'}`}></i>
              <span className='fw-medium'>{item}</span>
            </p>)}
          </div>
        </div>
        <Slider
          dots={true}
          autoplay={true}
          infinite={true}
          speed={2000}
          autoplaySpeed= {4000}
          initialSlide= {0}
          slidesToShow={4}
          slidesToScroll={1}
          rtl={language==='en'? false:true}
          responsive={[
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 560,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {trainingImages.map((image, index) => <div className='px-2 cursor-grab' key={index}>
            <img loading='lazy' className='w-100 rounded-3' src={image} alt="training" />
          </div>)}
        </Slider>
      </div>
      </>}
    </div>
  </>
}
