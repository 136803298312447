import React, { useContext } from 'react'
import style from './ProjectsCategories.module.css'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../context/LangContext'
import projectDesign1_1 from '../../assets/images/services/الاستشارات/architectural/1/1/15.webp'
import projectHydro1_1 from '../../assets/images/services/الاستشارات/hydrological/projects/1/elahmar.webp'
import projectGis1_1 from '../../assets/images/services/الاستشارات/gis/المشاريع - الأعمال المساحية/01- أعمال الرفع المساحي لوكالة الثروة السمكية لوكالة الزراعة بمنطقة مكه المكرمة/WhatsApp Image 2023-04-01 at 15.30.45.webp'
import projectSuper1_1 from '../../assets/images/services/الاستشارات/supervision/المشاريع - الإشراف على التنفيذ/1/IMG-20230802-WA0103.webp'
import projectInfra1_1 from '../../assets/images/services/الاستشارات/infrastructure/1/WhatsApp Image 2024-03-05 at 7.39.58 AM.webp'
import projectTrans1_1 from '../../assets/images/services/الاستشارات/transport/1/21.webp'
import projectElectrical1_1 from '../../assets/images/services/الاستشارات/electrical/1/WhatsApp Image 2024-07-28 at 8.49.54 PM.webp'
import projectEvaluation1_1 from '../../assets/images/services/الاستشارات/evaluation/1/020.webp'
import { Link } from 'react-router-dom'

export default function ProjectsCategories() {
  
    const { t } = useTranslation()
    let {language}= useContext(LangContext)
    const site = sessionStorage.getItem('desiredPage')

  const companyProjects = [
    {
      id: 'architecturalProjects',
      name: t("navArchitectural"),
      images: [projectDesign1_1]
    },
    {
      id: 'hydrologicalProjects',
      name: t("navHydrological"),
      images: [projectHydro1_1]
    },
    {
      id: 'gisProjects',
      name: t("navGIS"),
      images: [projectGis1_1]
    },
    {
      id: 'supervisionProjects',
      name: t("navSupervision"),
      images: [projectSuper1_1]
    },
    {
      id: 'infrastructureProjects',
      name: t("navInfrastructure"),
      images: [projectInfra1_1]
    },
    {
      id: 'transportProjects',
      name: t("navTransport"),
      images: [projectTrans1_1]
    },
    {
      id: 'electricalProjects',
      name: t("navElectrical"),
      images: [projectElectrical1_1]
    },
    {
      id: 'evaluationProjects',
      name: t("navEvaluation"),
      images: [projectEvaluation1_1]
    },
  ]

  return <>
  <div dir={language==='en'? '':'rtl'} className={`py-5 container-md w-90 px-0`}>
    <div className="row justify-content-center g-xl-5 g-lg-4 g-3 px-0 mt-2 mb-5">
      {companyProjects.map((project) => <div key={project.id} className={site==='company'? 'col-lg-3 col-md-4 col-sm-6':'col-lg-6'}>
          <Link to={site==='company'? `/projects/${project.id}`:`/projectDetails/${project.id}`} className='position-relative project trans3'>
            <div className={site==='company'? 'square':'portrait'}>
              <img loading='lazy' className='w-100 h-100 object-center object-cover' src={project.images[0]} alt="project" />
            </div>
            <div className="position-absolute py-2 overflow-hidden linear-gradient-card z-1 top-0 bottom-0 start-0 end-0 d-flex align-items-end">
              <div className={`w-100 px-xxl-5 px-xl-4 px-3 ${language === 'en'? '':'text-end'}`}>
                <h5 className='text-white fs-6 project-name fw-semibold'>{t('achieveProjects')}&nbsp;{project.name}</h5>
                <button className={`btn text-white px-0 text-hover pt-xl-4 pb-sm-4 pt-2 pb-3 d-flex w-100 align-items-center`}><p>{site==='company'? t('forMore'):t('details')}</p><i className={`fa-solid fa-arrow-left ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></button>
              </div>
            </div>
          </Link>
        </div>)}
      </div>
    </div>
  </>
}
