import React, { useContext, useEffect, useState } from 'react'
import style from './Navbar.module.css'
import { LangContext } from '../context/LangContext'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { Link as ScrollLink } from 'react-scroll'
import { useTranslation } from 'react-i18next'
import logoLabs from '../../assets/images/logo_mekyal_mobile (1).webp'
import logoLabs2 from '../../assets/images/logo_mekyal_w-1 (1).webp'
import logoCompany from '../../assets/images/Picsart_24-11-12_13-11-18-690.webp'
import logoCompany2 from '../../assets/images/Picsart_24-10-31_15-51-20-334.webp'

export default function Navbar() {

  const { t } = useTranslation()
  let {language}= useContext(LangContext)
  const [showNavbar, setShowNavbar] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const [hoverMenu, setHoverMenu] = useState('')
  const [hoverSecondaryMenu, setHoverSecondaryMenu] = useState('')
  const location = useLocation()
  const navigate = useNavigate()
  const site = sessionStorage.getItem('desiredPage')

  
  useEffect(() => {
    if (location.pathname === "/select-site") {
      setShowNavbar(false)
    } else {
      setShowNavbar(true)
    }
  }, [location])

  useEffect(() => {
    const handleScrollNav = () => {      
      if (window.scrollY > 400) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }
    window.addEventListener('scroll', handleScrollNav)

    return () => {
      window.removeEventListener('scroll', handleScrollNav)
    }
  }, [])


  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = '/ALMEKYAL Company Profile - new_2.3.pdf';  
    link.download = 'AlMekyalProfile.pdf';  
    link.click();
  }

  const handleScrollAbout = (section) => {
    navigate('/about')
    setTimeout(() => {
      const sectionElement = document.getElementById(section);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100)
  }

  const handleScrollSoilService = (section) => {
    navigate('/services/soil')
    setTimeout(() => {
      const sectionElement = document.getElementById(section);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100)
  }

  return <>
      {showNavbar? <>
        <div className={`pt-4 px-0 container-xl w-95 mx-auto d-lg-flex justify-content-between align-items-center d-none ${language === 'en' ? 'flex-row-reverse': 'flex-row'}`}>
          <div className={`d-flex align-items-center ${language === 'en' ? 'flex-row-reverse': 'flex-row'}`}>
            <button className={`btn btn-main py-2 d-flex align-items-center ${language === 'en' ? 'flex-row-reverse ms-5': 'flex-row me-5'}`}
            onClick={handleDownload}
            >
              <i className={`fa-solid fa-download p-2 ${language === 'en' ? ' pe-0': ' ps-0'}`}></i>
              <p>{t('navProfile')}</p>
            </button>
            <div className='pe-4'>
              <p className={`text-secondary ${language === 'en' ? '': 'text-end'}`}>{t("navLocationT")}</p>
              <Link to='https://maps.app.goo.gl/md8Eho975vnQB2xE6' target='_blank' className={`d-flex align-items-center ${language === 'en' ? '': 'flex-row-reverse'}`}><i className={`fa-solid fa-location-dot text-main ${language === 'en' ? 'pe-2': 'ps-2'}`}></i><p>{t('location')}</p></Link>
            </div>
            <div className='pe-4'>
              <p className={`text-secondary ${language === 'en' ? '': 'text-end'}`}>{t("navEmailT")}</p>
              <Link to={`mailto:${site==='company'? 'info@almekyal.com':'lab@almekyal.com'}`} className={`d-flex align-items-center ${language === 'en' ? '': 'flex-row-reverse'}`}><i className={`fa-solid fa-envelope text-main ${language === 'en' ? 'pe-2': 'ps-2'}`}></i><p>{site==='company'? 'info@almekyal.com':'lab@almekyal.com'}</p></Link>
            </div> 
            <div className='pe-4'>
              <p className={`text-secondary ${language === 'en' ? '': 'text-end'}`}>{t("navPhoneT")}</p>
              <Link to={`tel:${site==='company'? '+966555116840':'+966501533348'}`} className={`d-flex align-items-center ${language === 'en' ? '': 'flex-row-reverse'}`}><i className={`fa-solid fa-phone text-main ${language === 'en' ? 'pe-2': 'ps-2'}`}></i><p>{site==='company'? '+966555116840':'+966501533348'}</p></Link>
            </div>
          </div>
          <div className={style.w11}>
            {site === 'company'? <img loading='lazy' className='w-100' src={logoCompany} alt="ElMekyal Company Logo" />
            :<img loading='lazy' className='w-100' src={logoLabs} alt="ElMekyal Labs Logo" />}
          </div>
        </div>
        <div className={`navbar rem-5 navbar-expand-lg bg-main ${isScrolled? `${style.navPosition10vh}`:`d-none`}`}></div>
          <nav className={`navbar rem-5 navbar-expand-lg bg-main shadow-lg container-xl w-95 mx-auto z-3 ${isScrolled? `fixed-top ${style.navPosition2vh}`:`${style.navPosition10vh}`} trans3 rounded-2`}>
          <div className={`container-fluid bg-main rounded-2 ${language === 'en' ? 'justify-content-end': 'justify-content-start'}`}>
          <div className={`d-lg-none d-flex w-100 justify-content-between align-items-center ${language === 'en' ? '': 'flex-row-reverse'}`}>
            <div className={style.w11}>
              {site === 'company'? <img loading='lazy' className='w-100' src={logoCompany2} alt="ElMekyal Company Logo" />
              :<img loading='lazy' className='w-100' src={logoLabs2} alt="ElMekyal Labs Logo" />}
            </div>
              <button className="navbar-toggler border-0 shadow-none rem-5" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <i className="fa-solid fa-bars text-white"></i>
              </button>
          </div>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className={`navbar-nav me-auto mb-2 mb-lg-0 w-100 d-flex align-items-center justify-content-around ${language === 'en' ? 'flex-lg-row': 'flex-lg-row-reverse'} flex-column`}>
                <li className="nav-item py-lg-0 py-1">
                  <NavLink className={`nav-link text-white trans5 ${style.navHover} rounded-2 px-xl-2 mx-1 px-lg-1 px-3 text-center`} aria-current="page" to={'/'}>{t("navHome")}</NavLink>
                </li>

                {/* about desktop */}
                <li className={`nav-item py-lg-0 py-1 d-lg-flex d-none position-relative ${style.rem5} align-items-center justify-content-center trans3`} onMouseEnter={() => setHoverMenu('about')} onMouseLeave={() => setHoverMenu('')}>
                  <NavLink className={`nav-link text-white trans5 ${style.navHover} ${hoverMenu === 'about'? 'bg-main2':''} rounded-2 px-xl-2 mx-1 px-lg-1 px-3 text-center d-flex align-items-center ${language === 'en' ? '': 'flex-row-reverse'}`} to={'/about'}>
                    <p>{t("about")}</p>
                    <i className={`fa-solid fa-angle-down ${language === 'en' ? 'ps-2': 'pe-2'}`}></i>
                  </NavLink>
                  <ul className={`bg-white shadow rounded-2 position-absolute z-1 p-0 trans3 w-max overflow-hidden ${style.top5} ${hoverMenu === 'about'? '':'d-none'}`}>
                    <li className="nav-item w-100">
                      <ScrollLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom px-md-5 px-4 py-md-3 py-2 text-center cursor-pointer`} to={site==='company'? '/whyUs':'/ourMessage'} onClick={() => handleScrollAbout(site==='company'? 'whyUs':'ourMessage')}>{site==='company'? t("navWhyUs"):t("navMessage")}</ScrollLink>
                    </li>
                    <li className="nav-item w-100">
                      <ScrollLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom px-md-5 px-4 py-md-3 py-2 text-center cursor-pointer`}  to={site==='company'? '/ourMessage':'/navDepartments'} onClick={() => handleScrollAbout(site==='company'? 'ourMessage':'navDepartments')}>{site==='company'? t("navOurMessage"):t("departments")}</ScrollLink>
                    </li>
                    <li className="nav-item w-100">
                      <ScrollLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom px-md-5 px-4 py-md-3 py-2 text-center cursor-pointer`} to={site==='company'? '/growth':'/aboutOrganization'} onClick={() => handleScrollAbout(site==='company'? 'growth':'aboutOrganization')}>{site==='company'? t("navGrowth"):t("aboutOrganization")}</ScrollLink>
                    </li>
                    <li className="nav-item w-100">
                      <ScrollLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom px-md-5 px-4 py-md-3 py-2 text-center cursor-pointer`}  to={site==='company'? '/aboutOrganization':'/importantEngineers'} onClick={() => handleScrollAbout(site==='company'? 'aboutOrganization':'importantEngineers')}>{site==='company'? t("aboutCompanyOrganization"):t("importantEngineers")}</ScrollLink>
                    </li>
                  </ul>
                </li>

                {/* about small screens */}
                <li className="nav-item py-lg-0 py-1">
                  <NavLink className={`nav-link text-white trans5 d-lg-none ${style.navHover} rounded-2 px-xl-2 mx-1 px-lg-1 px-3 text-center`} aria-current="page" to={'/about'}>{t("about")}</NavLink>
                </li>

                 {/* services desktop */}
                 <li className={`nav-item py-lg-0 py-1 position-relative ${style.rem5} d-lg-flex d-none align-items-center justify-content-center`} onMouseEnter={() => setHoverMenu('services')} onMouseLeave={() => setHoverMenu('')}>
                  <NavLink className={`nav-link text-white trans5 ${style.navHover} ${hoverMenu === 'services'? 'bg-main2':''} rounded-2 px-xl-2 mx-1 px-lg-1 px-3 text-center d-flex align-items-center ${language === 'en' ? '': 'flex-row-reverse'}`} to={'/services'}>
                    <p>{t("services")}</p>
                    <i className={`fa-solid fa-angle-down ${language === 'en' ? 'ps-2': 'pe-2'}`}></i>
                  </NavLink>
                  <ul className={`bg-white shadow rounded-2 position-absolute z-1 p-0 trans3 w-max ${style.top5} ${hoverMenu === 'services'? '':'d-none'}`}>
                    <li className="nav-item w-100">
                      <NavLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom rounded-top-2 px-4 py-md-3 py-2 text-center`} to={site==='company'? '/services/architectural':'/services/geotechnical'}>{site==='company'? t('navArchitectural'):t('navGeotechnical')}</NavLink>
                    </li>
                    {site==='company'? '': <li className="nav-item w-100 position-relative" onMouseEnter={() => setHoverSecondaryMenu('soil')} onMouseLeave={() => setHoverSecondaryMenu('')}>
                      <NavLink className={`nav-link ${hoverSecondaryMenu === 'soil'? 'bg-main2 text-white':'text-main2'} text-hover3 trans3 ${style.navHover} border-bottom px-4 py-md-3 py-2 text-center d-flex align-items-center ${language === 'en' ? '': 'flex-row-reverse'}`} to={'/services/soil'}>
                        <p>{t("navSoil")}</p>
                        <i className={`fa-solid ${language === 'en' ? 'fa-angle-right': 'fa-angle-left'} ${language === 'en' ? 'ps-2': 'pe-2'}`}></i>
                      </NavLink>
                      <ul className={`bg-white overflow-hidden shadow rounded-2 position-absolute z-1 p-0 trans3 w-max top-0 ${style.top5} ${language === 'en' ? 'start-100 ms-1': 'end-100 me-1'} ${hoverSecondaryMenu === 'soil'? '':'d-none'}`}>
                        <li className="nav-item w-100">
                          <ScrollLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom px-4 py-md-3 py-2 text-center cursor-pointer`} to={'/soilAggregateTest'} onClick={() => handleScrollSoilService('soilAggregateTest')}>{t("navSoilAggregateTest")}</ScrollLink>
                        </li>
                        <li className="nav-item w-100">
                          <ScrollLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom px-4 py-md-3 py-2 text-center cursor-pointer`} to={'/asphaltTest'} onClick={() => handleScrollSoilService('asphaltTest')}>{t("navAsphaltTest")}</ScrollLink>
                        </li>
                        <li className="nav-item w-100">
                          <ScrollLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom px-4 py-md-3 py-2 text-center cursor-pointer`} to={'/liquidBitumenTest'} onClick={() => handleScrollSoilService('liquidBitumenTest')}>{t("navLiquidBitumenTest")}</ScrollLink>
                        </li>
                        <li className="nav-item w-100">
                          <ScrollLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom px-4 py-md-3 py-2 text-center cursor-pointer`} to={'/concreteTest'} onClick={() => handleScrollSoilService('concreteTest')}>{t("navConcreteTest")}</ScrollLink>
                        </li>
                        <li className="nav-item w-100">
                          <ScrollLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} px-4 py-md-3 py-2 text-center cursor-pointer`} to={'/blockTileTest'} onClick={() => handleScrollSoilService('blockTileTest')}>{t("navBlockTileTest")}</ScrollLink>
                        </li>
                      </ul>
                    </li>}
                    <li className="nav-item w-100">
                      <NavLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom px-4 py-md-3 py-2 text-center`} to={site==='company'? '/services/hydrological':'/services/chemical-analysis'} >{site==='company'? t('navHydrological'):t('navChemical')}</NavLink>
                    </li>
                    <li className="nav-item w-100">
                      <NavLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom px-4 py-md-3 py-2 text-center`} to={site==='company'? '/services/GIS':'/services/building'}>{site==='company'? t('navGIS'):t('navBuilding')}</NavLink>
                    </li>
                    <li className="nav-item w-100">
                      <NavLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} ${site==='company'? '':'rounded-bottom-2'} border-bottom px-4 py-md-3 py-2 text-center`} to={site==='company'? '/services/supervision' :'/services/soil-studies'}>{site==='company'? t('navSupervision'):t('navStudies')}</NavLink>
                    </li>
                    {site==='company'? <>
                      <li className="nav-item w-100">
                        <NavLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom px-4 py-md-3 py-2 text-center`} to={'/services/infrastructure'}>{t('navInfrastructure')}</NavLink>
                      </li>
                      <li className="nav-item w-100">
                        <NavLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom px-4 py-md-3 py-2 text-center`} to={'/services/transport'}>{t('navTransport')}</NavLink>
                      </li>
                      <li className="nav-item w-100">
                        <NavLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom px-4 py-md-3 py-2 text-center`} to={'/services/electrical'}>{t('navElectrical')}</NavLink>
                      </li>
                      <li className="nav-item w-100">
                        <NavLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} rounded-bottom-2 px-4 py-md-3 py-2 text-center`} to={'/services/evaluation'}>{t('navEvaluation')}</NavLink>
                      </li>
                    </>:''}
                  </ul>
                </li>

                {/* services small screens */}
                <li className="nav-item py-lg-0 py-1">
                  <NavLink className={`nav-link text-white trans5 d-lg-none ${style.navHover} rounded-2 px-xl-2 mx-1 px-lg-1 px-3 text-center`} aria-current="page" to={'/services'}>{t("services")}</NavLink>
                </li>
                

                {/* projects desktop */}
                <li className={`nav-item py-lg-0 py-1 position-relative ${style.rem5} d-lg-flex d-none align-items-center justify-content-center`} onMouseEnter={() => setHoverMenu('projects')} onMouseLeave={() => setHoverMenu('')}>
                  <NavLink className={`nav-link text-white trans5 ${style.navHover} ${hoverMenu === 'projects'|| location.pathname.includes('/projects/')? 'bg-main2 ':''} rounded-2 px-xl-2 mx-1 px-lg-1 px-3 text-center d-flex align-items-center ${language === 'en' ? '': 'flex-row-reverse'}`} to={site==='company'? '/projects':'/projects/all'}>
                    <p>{site==='company'? t("projects"):t("projectsLabs")}</p>
                    <i className={`fa-solid fa-angle-down ${language === 'en' ? 'ps-xl-2 ps-lg-1 ps-2': 'pe-xl-2 pe-lg-1 pe-2'}`}></i>
                  </NavLink>
                  <ul className={`bg-white shadow rounded-2 position-absolute z-1 p-0 trans3 w-max overflow-hidden ${style.top5} ${hoverMenu === 'projects'? '':'d-none'}`}>
                  {site==='company'? <>
                    <li className="nav-item w-100">
                      <NavLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom px-4 py-md-3 py-2 text-center ${language==='en'? 'd-flex flex-row-reverse justify-content-center':''}`} to={'/projects/architecturalProjects'}><span>{t("achieveProjects")}&nbsp;</span><span>{t("navArchitectural")}&nbsp;</span></NavLink>
                    </li>
                    <li className="nav-item w-100">
                      <NavLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom px-4 py-md-3 py-2 text-center ${language==='en'? 'd-flex flex-row-reverse justify-content-center':''}`} to={'/projects/hydrologicalProjects'}><span>{t('achieveProjects')}&nbsp;</span><span>{t("navHydrological")}&nbsp;</span></NavLink>
                    </li>
                    <li className="nav-item w-100">
                      <NavLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom px-4 py-md-3 py-2 text-center ${language==='en'? 'd-flex flex-row-reverse justify-content-center':''}`} to={'/projects/gisProjects'}><span>{t('achieveProjects')}&nbsp;</span><span>{t("navGIS")}&nbsp;</span></NavLink>
                    </li>
                    <li className="nav-item w-100">
                      <NavLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover}  border-bottom px-4 py-md-3 py-2 text-center ${language==='en'? 'd-flex flex-row-reverse justify-content-center':''}`} to={'/projects/supervisionProjects'}><span>{t('achieveProjects')}&nbsp;</span><span>{t("navSupervision")}&nbsp;</span></NavLink>
                    </li>
                    <li className="nav-item w-100">
                      <NavLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom px-4 py-md-3 py-2 text-center ${language==='en'? 'd-flex flex-row-reverse justify-content-center':''}`} to={'/projects/infrastructureProjects'}><span>{t('achieveProjects')}&nbsp;</span><span>{t('navInfrastructure')}&nbsp;</span></NavLink>
                    </li>
                    <li className="nav-item w-100">
                      <NavLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom px-4 py-md-3 py-2 text-center ${language==='en'? 'd-flex flex-row-reverse justify-content-center':''}`} to={'/projects/transportProjects'}><span>{t('achieveProjects')}&nbsp;</span><span>{t('navTransport')}&nbsp;</span></NavLink>
                    </li>
                    <li className="nav-item w-100">
                      <NavLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom px-4 py-md-3 py-2 text-center ${language==='en'? 'd-flex flex-row-reverse justify-content-center':''}`} to={'/projects/electricalProjects'}><span>{t('achieveProjects')}&nbsp;</span><span>{t('navElectrical')}&nbsp;</span></NavLink>
                    </li>
                    <li className="nav-item w-100">
                      <NavLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} rounded-bottom-2 px-4 py-md-3 py-2 text-center ${language==='en'? 'd-flex flex-row-reverse justify-content-center':''}`} to={'/projects/evaluationProjects'}><span>{t('achieveProjects')}&nbsp;</span><span>{t('navEvaluation')}&nbsp;</span></NavLink>
                    </li>
                    </>:<>
                    <li className="nav-item w-100">
                      <NavLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom px-4 py-md-3 py-2 text-center`} to={'/projects/geotechnicalProjects'}>{t("navGeotechnicalProject")}</NavLink>
                    </li>
                    <li className="nav-item w-100">
                      <NavLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} border-bottom px-4 py-md-3 py-2 text-center`} to={'/projects/qualityProjects'}>{t("navQualityProject")}</NavLink>
                    </li>
                    <li className="nav-item w-100">
                      <NavLink className={`nav-link text-main2 text-hover3 trans3 ${style.navHover} px-4 py-md-3 py-2 text-center`} to={'/projects/structuralProjects'}>{t("navBuildingProject")}</NavLink>
                    </li></>}
                  </ul>
                </li>

                {/* projects small screens */}
                <li className="nav-item py-lg-0 py-1">
                  <NavLink className={`nav-link text-white trans5 d-lg-none ${style.navHover} rounded-2 px-xl-2 mx-1 px-lg-1 px-3 text-center`} aria-current="page" to={'/projects/all'}>{site==='company'? t("projects"):t("projectsLabs")}</NavLink>
                </li>

                <li className="nav-item py-lg-0 py-1">
                  <NavLink className={`nav-link text-white trans5 ${style.navHover} rounded-2 px-xl-2 mx-1 px-lg-1 px-3 text-center`} aria-current="page" to={'/clients'}>{t("navClients")}</NavLink>
                </li>

                <li className="nav-item py-lg-0 py-1">
                  <NavLink className={`nav-link text-white trans5 ${style.navHover} rounded-2 px-xl-2 mx-1 px-lg-1 px-3 text-center`} to={site==='company'?'/quality':'/trainingLabs'}>{site==='company'? t("navSafety"):t("navTrainingLabs")}</NavLink>
                </li>
                <li className="nav-item py-lg-0 py-1">
                  <NavLink className={`nav-link text-white trans5 ${style.navHover} rounded-2 px-xl-2 mx-1 px-lg-1 px-3 text-center`} to={site==='company'?'/workshops':'/quality'}>{site==='company'? t("navWorkshops"):t("navQualityLabs")}</NavLink>
                </li>
                <li className="nav-item py-lg-0 py-1">
                  <NavLink className={`nav-link text-white trans5 ${style.navHover} rounded-2 px-xl-2 mx-1 px-lg-1 px-3 text-center`} to={site==='company'?'/comingSoon':'/media-center'}>{t("navMediaCenter")}</NavLink>
                </li>
                <li className="nav-item py-lg-0 py-1">
                  <NavLink className={`nav-link text-white trans5 ${style.navHover} rounded-2 px-xl-2 mx-1 px-lg-1 px-3 text-center`} to={'/contact-us'}>{t("contactUs")}</NavLink>
                </li>
                <button className={`btn py-2 d-lg-none text-white d-flex align-items-center ${style.navHover} ${language === 'en' ? 'flex-row-reverse': 'flex-row'}`}
                onClick={handleDownload}
                >
                  <i className={`fa-solid fa-download p-2 ${language === 'en' ? ' pe-0': ' ps-0'}`}></i>
                  <p>{t('navProfile')}</p>
                </button>
              </ul>
            </div>
          </div>
        </nav>
      </>
      :''}
    </>
}
