import React, { useContext } from 'react'
// import style from './Hydrological.module.css'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../context/LangContext'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import hydrological1 from '../../assets/images/services/الاستشارات/hydrological/WhatsApp Image 2022-09-01 at 1.15.50 PM.webp'
import hydrological2 from '../../assets/images/services/الاستشارات/hydrological/WhatsApp Image 2024-07-25 at 9.00.05 AM.webp'
import hydrological3 from '../../assets/images/services/الاستشارات/hydrological/WhatsApp Image 2024-07-25 at 9.19.36 AM.webp'
import hydrological4 from '../../assets/images/services/الاستشارات/hydrological/WhatsApp Image 2024-07-25 at 9.13.18 AM.webp'
import hydrological5 from '../../assets/images/services/الاستشارات/hydrological/WhatsApp Image 2024-07-25 at 9.16.39 AM.webp'
import hydrological6 from '../../assets/images/services/الاستشارات/hydrological/WhatsApp Image 2024-07-25 at 9.02.18 AM.webp'
import hydrological7 from '../../assets/images/services/الاستشارات/hydrological/WhatsApp Image 2024-07-25 at 9.20.57 AM.webp'
import hydrological8 from '../../assets/images/services/الاستشارات/hydrological/final water.webp'
import hydrological9 from '../../assets/images/services/الاستشارات/hydrological/دراسات هيدرولوجية.webp'
import project2 from '../../assets/images/services/الاستشارات/hydrological/projects/2/PHOTO-2023-06-18-15-39-08.webp'
import project3 from '../../assets/images/services/الاستشارات/hydrological/projects/5/ااااااااااااااااا_page-0051.webp'
import { Link } from 'react-router-dom'

export default function Hydrological() {
  
    const { t } = useTranslation()
    let {language}= useContext(LangContext)
    
    const data = [
      {
        id: '1',
        name:'الدراسات الهيدرولوجية  لأراضي ومخططات في بلديات المحور الجنوبي في منطقة الرياض',
        images: hydrological7
      },
      {
        id: '2',
        name:'دراسة وإعداد الكود السعودي لمصادر المياه واستخداماتها',
        images: project2
      },
      {
        id: '3',
        name:'العديد من الدراسات الجزئية مع أمانة منطقة الرياض ووكالة الأمانة ووزارة النقل وغيرها من الجهات',
        images: project3
      },
    ]

    const hydrological = [hydrological1, hydrological2, hydrological3, hydrological4, hydrological5, hydrological6, hydrological7, hydrological8, hydrological9]
      
  return <>
  <div dir={language==='en'? '':'rtl'}>
    <div className="header-img py-5">
      <div className='w-90 mx-auto d-flex h-100 flex-column align-items-center justify-content-center'>
        <p className='position-relative line fs-2 fw-bold text-black d-flex align-items-center text-center justify-content-center pb-3 mt-3 mb-5'>{t('navHydrological')}</p>
      </div>
    </div> 
    <div className='container-md w-90 mx-auto pb-4'>
      <p className='pt-5 pb-4 fs-5 lh-lg'>{t('hydrological.text1')}<br />{t('hydrological.text2')}<br />{t('hydrological.text3')}<br />{t('hydrological.text4')}</p>
    </div> 
    <div className="bg-light">
      <div className='container-md w-90 mx-auto pb-5'>
        <p className='pt-5 mb-4 fs-4 text-main2 fw-bold position-relative w-fit text-center line3'>{t('hydrological.water.header')}</p>
        <p className='pt-3'>{t('hydrological.water.text1')}</p>
        <ul className={`pt-2 list-style-disc fw-medium ${language==='en'? 'ps-4 pe-0':'pe-4 ps-0'}`}>
          <li className='py-2'>
            <p>{t('hydrological.water.point1')}</p>
          </li>
          <li className='py-2'>
            <p>{t('hydrological.water.point2')}</p>
          </li>
        </ul>
        <p className='lh-lg pb-4'>{t('hydrological.water.text2')}<br />{t('hydrological.water.text3')}</p>
        <Slider
        dots={true}
        autoplay={true}
        infinite={true}
        speed={2000}
        autoplaySpeed= {4000}
        initialSlide= {0}
        slidesToShow={4}
        slidesToScroll={3}
        rtl={language==='en'? false:true}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 575,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ]}
      >
      {hydrological.map((item ,index) => (
          <div key={index} className='px-2'>
            <img loading='lazy' className='w-100' src={item} alt="management" />
          </div>
        ))}
        </Slider>
      </div>
    </div>
    <div className='container-md w-90 mx-auto pb-4'>
      <p className='pt-5 fs-5 fw-bold'>{t('hydrological.studies.text')}</p>
      <ul className={`pt-3 list-style-disc fw-medium ${language==='en'? 'ps-4 pe-0':'pe-4 ps-0'}`}>
        <li className='py-2'>
          <p>{t('hydrological.studies.list.1')}</p>
        </li>
        <li className='py-2'>
          <p>{t('hydrological.studies.list.2')}</p>
        </li>
        <li className='py-2'>
          <p>{t('hydrological.studies.list.3')}</p>
        </li>
        <li className='py-2'>
          <p>{t('hydrological.studies.list.4')}</p>
        </li>
        <li className='py-2'>
          <p>{t('hydrological.studies.list.5')}</p>
        </li>
        <li className='py-2'>
          <p>{t('hydrological.studies.list.6')}</p>
        </li>
      </ul>
    </div>
      <div className="bg-light">
        <div className='container-md w-90 mx-auto py-5'>
          <div className="d-flex justify-content-center">
            <p className='pt-2 mb-2 fs-4 text-main2 fw-bold position-relative w-fit line3'>{t('featuredProjects')}</p>
          </div>
          <div className='d-flex justify-content-end py-3'>
            <Link to={'/projects/all'} className='text-main fw-medium text-hover2 trans3 border-bottom border-main border-2 pb-2 d-flex w-fit align-items-center small'>{t('seeMore')}<i className={`fa-solid fa-arrow-left ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
          </div>
          <Slider
          autoplay= {3}
          infinite={false}
          arrows={false}
          dots={false}
          speed={2000}
          autoplaySpeed= {4000}
          initialSlide= {0}
          slidesToShow={3}
          slidesToScroll={1}
          rtl={language==='en'? false:true}
          responsive={[
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: true,
                dots: true,
                infinite: true,
              },
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                dots: true,
                infinite: true,
              },
            },
          ]}
        >
        {data.map((item) => (
            <div key={item.id} className='px-2'>
              <Link className='position-relative project trans3'>
                <div className='vw-20'>
                  <img loading='lazy' className='w-100 h-100 object-center object-cover' src={item.images} alt="project" />
                </div>
                <div className={ `position-absolute py-3 overflow-hidden linear-gradient-card z-1 top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-end ${language==='en'? '':'text-end'}`}>
                  <h5 className='text-white px-sm-5 px-3 pb-md-2 pb-sm-0 pb-2 fs-6 project-name fw-semibold'>{item.name}</h5>
                  <button className={`btn text-white text-hover px-sm-5 px-3 py-2 d-flex w-100 align-items-center`}>{t('details')}<i className={`fa-solid fa-arrow-left ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></button>
                </div>
              </Link>
            </div>
          ))}
          </Slider>
        </div>
      </div>
  </div>
  </>
}
