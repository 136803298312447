import React, { useContext } from 'react'
// import style from './Transport.module.css'
import { useTranslation } from 'react-i18next'
import { LangContext } from './../context/LangContext';
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import project1 from '../../assets/images/services/الاستشارات/transport/1/22.webp'
import project2 from '../../assets/images/services/الاستشارات/transport/2/124.webp'
import project3 from '../../assets/images/services/الاستشارات/transport/3/5.webp'
import { Link } from 'react-router-dom';

export default function Transport() {
  
    const { t } = useTranslation()
    let {language}= useContext(LangContext)
      
    const data = [
      {
        id: '1',
        name:'دراسة التأثير المروري لمستشفيي سليمان الحبيب بحي الحمراء',
        images: project1
      },
      {
        id: '2',
        name:'دراسة التأثير المروري لمجمع مدارس جلوري العالمية بحى بدر',
        images: project2
      },
      {
        id: '3',
        name:'دراسة التأثير المروري لمشروع يمام بحي السفارات.',
        images: project3
      },
    ]
      
  return <>
    <div dir={language==='en'? '':'rtl'}>
      <div className="header-img py-5">
        <div className='w-90 mx-auto d-flex h-100 flex-column align-items-center justify-content-center'>
          <p className='position-relative line fs-2 fw-bold text-black d-flex align-items-center text-center justify-content-center pb-3 mt-3 mb-5'>{t('navTransport')}</p>
        </div>
      </div> 
      <div className='container-md w-90 mx-auto pb-4'>
        <p className='pt-5 pb-4 fs-5 lh-lg'>{t('transport.text')}</p>
      </div> 
      <div className="bg-light">
        <div className='container-md w-90 mx-auto pb-5'>
          <p className='pt-5 fs-5 fw-bold'>{t('include')}</p>
          <ul className={`pt-4 list-style-disc ${language==='en'? 'ps-4 pe-0':'pe-4 ps-0'}`}>
            <li className='py-3'>
              <p className='fw-medium'>{t('transport.1.text')}</p>
            </li>
            <li className='py-3'>
              <p className='fw-medium'>{t('transport.2.text')}</p>
            </li>
            <li className='py-3'>
              <p className='fw-medium'>{t('transport.3.text')}</p>
            </li>
            <li className='py-3'>
              <p className='fw-medium'>{t('transport.4.text')}</p>
            </li>
            <li className='py-3'>
              <p className='fw-medium'>{t('transport.5.text')}</p>
            </li>
          </ul>
          <div className="d-flex justify-content-center">
            <p className='pt-2 mb-2 fs-4 text-main2 fw-bold position-relative w-fit line3'>{t('featuredProjects')}</p>
          </div>
          <div className='d-flex justify-content-end py-3'>
            <Link to={'/projects/all'} className='text-main fw-medium text-hover2 trans3 border-bottom border-main border-2 pb-2 d-flex w-fit align-items-center small'>{t('seeMore')}<i className={`fa-solid fa-arrow-left ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></Link>
          </div>
          <Slider
          dots={data.length > 3}
          autoplay={data.length > 3}
          infinite={data.length > 3}
          speed={2000}
          autoplaySpeed= {4000}
          initialSlide= {0}
          slidesToShow={3}
          slidesToScroll={1}
          rtl={language==='en'? false:true}
          responsive={[
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
        >
        {data.map((item) => (
            <div key={item.id} className='px-2'>
              <Link className='position-relative project trans3'>
                <div className='vw-20'>
                  <img loading='lazy' className='w-100 h-100 object-center object-cover' src={item.images} alt="project" />
                </div>
                <div className={ `position-absolute py-3 overflow-hidden linear-gradient-card z-1 top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-end ${language==='en'? '':'text-end'}`}>
                  <h5 className='text-white px-sm-5 px-3 pb-md-2 pb-sm-0 pb-2 fs-6 project-name fw-semibold'>{item.name}</h5>
                  <button className='btn text-white text-hover px-sm-5 px-3 py-2 d-flex w-100 align-items-center'>{t('details')}<i className={`fa-solid fa-arrow-left ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></button>
                </div>
              </Link>
            </div>
          ))}
          </Slider>
        </div>
      </div>
    </div>
  </>
}
