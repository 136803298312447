import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../context/LangContext'
import { Link } from 'react-router-dom'

export default function Contact() {
    
  const { t } = useTranslation()
  let {language}= useContext(LangContext)
  const site = sessionStorage.getItem('desiredPage')

  return <>
      <div dir={language==='en'? '':'rtl'} className='container-md mx-auto pb-4 mt-lg-1 mt-5 pt-5'>
        <div className={`mb-5 mt-4 d-sm-flex px-5 align-items-center justify-content-between contact-us`}>
          <Link dir='ltr' to={`tel:${site==='company'? '+966555116840':'+966501533348'}`} className='d-flex flex-column my-3 align-items-center trans3 bg-hover px-lg-5 py-lg-4 px-md-4 py-md-3 p-2 rounded-3'><i className={`fa-solid fa-phone fs-4 text-main trans3 pb-3`}></i><p className='text-main2 trans3'>{site==='company'? '+966555116840':'+966501533348'}</p></Link>
          <Link to={`mailto:${site==='company'? 'info@almekyal.com':'lab@almekyal.com'}`} className='d-flex flex-column my-3 align-items-center trans3 bg-hover px-lg-5 py-lg-4 px-md-4 py-md-3 p-2 rounded-3'><i className={`fa-solid fa-envelope fs-4 text-main trans3 pb-3`}></i><p className='text-main2 trans3'>{site==='company'? 'info@almekyal.com':'lab@almekyal.com'}</p></Link>
          <Link to='https://maps.app.goo.gl/md8Eho975vnQB2xE6' target='_blank' className='d-flex flex-column my-3 align-items-center trans3 bg-hover px-lg-5 py-lg-4 px-md-4 py-md-3 p-2 rounded-3'><i className={`fa-solid fa-location-dot fs-4 text-main trans3 pb-3`}></i><p className='text-main2 trans3'>{t('location')}</p></Link>
        </div>
        <div className='w-75 my-5 mx-auto py-2'>
          {site==='company'? <form className={`border rounded-2 px-3 row gx-5 gy-4 pb-5`}>
            <div className="col-12">
              <div className='d-flex flex-column'>
                <label htmlFor="full" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('fullName')}</label>
                <input id='full' placeholder={t('yourFullName')} type="text" className={`rounded-2 border-0 bg-body-secondary p-3 ${language==='en'? '':'text-end'}`} />
              </div>
            </div>
            <div className="col-12">
              <div className='d-flex flex-column'>
                <label htmlFor="email" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('formEmail')}</label>
                <input id='email' placeholder={t('yourEmail')} type="email" className={`rounded-2 border-0 bg-body-secondary p-3 ${language==='en'? '':'text-end'}`} />
              </div>
            </div>
            <div className="col-12">
              <div className='d-flex flex-column'>
                <label htmlFor="tel" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('formPhone')}</label>
                <input id='tel' placeholder={t('yourPhone')} type="tel" className={`rounded-2 border-0 bg-body-secondary p-3 ${language==='en'? '':'text-end'}`} />
              </div>
            </div>
            <div className="col-12">
              <div className='d-flex flex-column'>
                <label htmlFor="companyName" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('companyName')}</label>
                <input id='companyName' placeholder={t('optional')} type="text" className={`rounded-2 border-0 bg-body-secondary p-3 ${language==='en'? '':'text-end'}`} />
              </div>
            </div>
            <div className="col-md-6">
              <div className='d-flex flex-column'>
                <label htmlFor="serviceDetails" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('serviceDetails')}</label>
                <select id="serviceDetails" className={`rounded-2 border-0 bg-body-secondary p-3 dropdown-select ${language === 'en' ? '' : 'text-end'}`} defaultValue="">
                  <option value="" disabled selected>{t('select')}</option>
                    <option value="architectural">{t('architecturalDesign')}</option>
                    <option value="infrastructure">{t('infrastructureConsulting')}</option>
                    <option value="supervision">{t('projectSupervision')}</option>
                    <option value="environmental">{t('environmentalConsulting')}</option>
                    <option value="electrical">{t('electricalProjectSupervision')}</option>
                    <option value="other">{t('other')}</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className='d-flex flex-column'>
                <label htmlFor="projectSite" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('projectSite')}</label>
                <input id='projectSite' placeholder={t('site')} type="text" className={`rounded-2 border-0 bg-body-secondary p-3 ${language==='en'? '':'text-end'}`} />
              </div>
            </div>
            <div className="col-md-6">
              <div className='d-flex flex-column'>
                <label htmlFor="attachedFiles" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('attachedFiles')}</label>
                <input id='attachedFiles' placeholder={t('optional')} type="file" className={`rounded-2 border-0 bg-body-secondary p-3 ${language==='en'? '':'text-end'}`} />
              </div>
            </div>
            <div className="col-md-6">
              <div className='d-flex flex-column'>
                <label htmlFor="favoriteContact" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('favoriteContact')}</label>
                <select id="favoriteContact" className={`rounded-2 border-0 bg-body-secondary p-3 dropdown-select ${language === 'en' ? '' : 'text-end'}`}>
                  <option value="" disabled selected>{t('select')}</option>
                  <option value="email">{t('emailContact')}</option>
                  <option value="phone">{t('phoneContact')}</option>
                  <option value="meeting">{t('meetingContact')}</option>
                </select>
              </div>
            </div>
            <div className="col-12">
              <div className='d-flex flex-column'>
                <label htmlFor="additionalNotes" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('additionalNotes')}</label>
                <textarea id='additionalNotes' placeholder={t('anyInformation')} rows={5} className={`rounded-2 border-0 bg-body-secondary p-3 ${language==='en'? '':'text-end'}`} />
              </div>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-center">
              <button className='btn btn-main px-4'>{t('send')}</button>
            </div>
          </form>
          :<form className={`border rounded-2 px-3 row gx-5 gy-4 pb-5`}>
          <div className="col-md-6">
            <div className='d-flex flex-column'>
              <label htmlFor="first" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('firstName')}</label>
              <input id='first' placeholder={t('yourFirstName')} type="text" className={`rounded-2 border-0 bg-body-secondary p-3 ${language==='en'? '':'text-end'}`} />
            </div>
          </div>
          <div className="col-md-6">
            <div className='d-flex flex-column'>
              <label htmlFor="last" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('lastName')}</label>
              <input id='last' placeholder={t('yourLastName')} type="text" className={`rounded-2 border-0 bg-body-secondary p-3 ${language==='en'? '':'text-end'}`} />
            </div>
          </div>
          <div className="col-12">
            <div className='d-flex flex-column'>
              <label htmlFor="email" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('formEmail')}</label>
              <input id='email' placeholder={t('yourEmail')} type="email" className={`rounded-2 border-0 bg-body-secondary p-3 ${language==='en'? '':'text-end'}`} />
            </div>
          </div>
          <div className="col-12">
            <div className='d-flex flex-column'>
              <label htmlFor="tel" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('formPhone')}</label>
              <input id='tel' placeholder={t('yourPhone')} type="tel" className={`rounded-2 border-0 bg-body-secondary p-3 ${language==='en'? '':'text-end'}`} />
            </div>
          </div>
          <div className="col-12">
            <div className='d-flex flex-column'>
              <label htmlFor="subject" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('subject')}</label>
              <input id='subject' placeholder={t('yourSubject')} type="text" className={`rounded-2 border-0 bg-body-secondary p-3 ${language==='en'? '':'text-end'}`} />
            </div>
          </div>
          <div className="col-12">
            <div className='d-flex flex-column'>
              <label htmlFor="message" className={`${language==='en'? '':'text-end'} pb-2 small text-main`}>{t('message')}</label>
              <textarea id='message' placeholder={t('yourMessage')} rows={5} className={`rounded-2 border-0 bg-body-secondary p-3 ${language==='en'? '':'text-end'}`} />
            </div>
          </div>
          <div className="d-flex w-100 align-items-center justify-content-center">
            <button className='btn btn-main px-4'>{t('send')}</button>
          </div>
        </form>}
        </div>
      </div>
  </>
}
