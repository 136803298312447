import React, { useContext, useEffect } from 'react'
import style from './Geotechnical.module.css'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../context/LangContext'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import geotechnical1 from '../../assets/images/services/المختبرات/الهندسة الجيوتقنيه/DSC_0702.webp'
import geotechnical2 from '../../assets/images/services/المختبرات/الهندسة الجيوتقنيه/DSC_0713.webp'
import geotechnical3 from '../../assets/images/services/المختبرات/الهندسة الجيوتقنيه/DSC_0783.webp'
import geotechnical4 from '../../assets/images/services/المختبرات/الهندسة الجيوتقنيه/أعمال تنفيذ جسات تربة في احد المواقع في مدينة الرياض.webp'
import geotechnical5 from '../../assets/images/services/المختبرات/الهندسة الجيوتقنيه/اعمال جسات تربة في أحد المواقع في المدينة الصناعية بالرياض.webp'
import geotechnical6 from '../../assets/images/services/المختبرات/الهندسة الجيوتقنيه/حفارات 3.webp'
import geotechnical7 from '../../assets/images/services/المختبرات/الهندسة الجيوتقنيه/صورة 2-فقرة الدرسات الجيوتقنية.webp'
import geotechnical8 from '../../assets/images/services/المختبرات/الهندسة الجيوتقنيه/WhatsApp Image 2025-02-11 at 9.11.03 PM (1).jpeg'
import geotechnical9 from '../../assets/images/services/المختبرات/الهندسة الجيوتقنيه/WhatsApp Image 2025-02-11 at 9.11.04 PM (1).jpeg'

export default function Geotechnical() {

  const { t } = useTranslation()
  let {language}= useContext(LangContext)

  const geotechnicalImages = [
    geotechnical1, geotechnical2, geotechnical3, geotechnical4, geotechnical5, geotechnical6, geotechnical7, geotechnical8, geotechnical9
  ]

  return <>
    <div dir={language==='en'? '':'rtl'}>
      <div className="header-img py-5">
        <div className='w-90 mx-auto d-flex h-100 flex-column align-items-center justify-content-center'>
          <p className='position-relative line fs-2 fw-bold text-black d-flex align-items-center text-center justify-content-center pb-3 mt-3 mb-5'>{t('navGeotechnical')}</p>
        </div>
      </div> 
      <div className='container-md w-90 mx-auto pb-4'>
        <p className='pt-5 pb-4 fs-5 lh-lg'>{t('geoHeader.section1.text1')}<br />{t('geoHeader.section1.text2')}</p>
      </div>
      <div className="bg-light">
        <div className='container-md w-90 mx-auto py-5'>
          <p className='fw-bold pb-4'>
            <i className={`fa-solid fa-check text-main ${language==='en'? 'pe-2':'ps-2'}`}></i>
            <span>{t('geoHeader.section2.text')}</span>
          </p>
          <Slider
            dots={true}
            autoplay={true}
            infinite={true}
            speed={2000}
            autoplaySpeed= {4000}
            initialSlide= {0}
            slidesToShow={4}
            slidesToScroll={4}
            rtl={language==='en'? false:true}
            responsive={[
              {
                breakpoint: 991,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 575,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {geotechnicalImages.map((image, index) => <div className='px-2 cursor-grab' key={index}>
              <img loading='lazy' className='w-100 rounded-3' src={image} alt="geotechnical test" />
            </div>)}
          </Slider>
        </div>
      </div>
      <div className='container-md w-90 mx-auto pb-4'>
        <p className='pt-5 mb-4 fs-5 text-main2 fw-bold position-relative w-fit line3'>{t('geoHeader.section3.text')}</p>
        <ul className={`py-4 fs-5 list-style-disc ${language==='en'? 'ps-4 pe-0':'pe-4 ps-0'}`}>
          <li className='py-3'>
            <p className='text-main2 fw-bold pb-3'>{t('geoHeader.section3.1.header')}</p>
            <p>{t('geoHeader.section3.1.text')}</p>
          </li>
          <li className='py-3'>
            <p className='text-main2 fw-bold pb-3'>{t('geoHeader.section3.2.header')}</p>
            <p>{t('geoHeader.section3.2.text')}</p>
          </li>
          <li className='py-3'>
            <p className='text-main2 fw-bold pb-3'>{t('geoHeader.section3.3.header')}</p>
            <p>{t('geoHeader.section3.3.text')}</p>
          </li>
          <li className='py-3'>
            <p className='text-main2 fw-bold pb-3'>{t('geoHeader.section3.4.header')}</p>
            <p>{t('geoHeader.section3.4.text')}</p>
          </li>
          <li className='py-3'>
            <p className='text-main2 fw-bold pb-3'>{t('geoHeader.section3.5.header')}</p>
            <p>{t('geoHeader.section3.5.text1')}<br />{t('geoHeader.section3.5.text2')}</p>
          </li>
          <li className='py-3'>
            <p className='text-main2 fw-bold pb-3'>{t('geoHeader.section3.6.header')}</p>
            <ul className={`list-style-auto ${language==='en'? 'ps-4 pe-0':'pe-4 ps-0'}`}>
              <li className="py-1">{t('geoHeader.section3.6.list.1')}</li>
              <li className="py-1">{t('geoHeader.section3.6.list.2')}</li>
              <li className="py-1">{t('geoHeader.section3.6.list.3')}</li>
              <li className="py-1">{t('geoHeader.section3.6.list.4')}</li>
              <li className="py-1">{t('geoHeader.section3.6.list.5')}</li>
              <li className="py-1">{t('geoHeader.section3.6.list.6')}</li>
              <li className="py-1">{t('geoHeader.section3.6.list.7')}</li>
              <li className="py-1">{t('geoHeader.section3.6.list.8')}</li>
              <li className="py-1">{t('geoHeader.section3.6.list.9')}</li>
              <li className="py-1">{t('geoHeader.section3.6.list.10')}</li>
            </ul>
          </li>
          <li className='py-3'>
            <p className='text-main2 fw-bold'>{t('geoHeader.section3.7.header')}</p>
          </li>
          <li className='py-3'>
            <p className='text-main2 fw-bold pb-3'>{t('geoHeader.section3.8.header')}</p>
            <p>{t('geoHeader.section3.8.text')}</p>
          </li>
          <li className='py-3'>
            <p className='text-main2 fw-bold'>{t('geoHeader.section3.9.header')}</p>
            <p className='py-3'>{t('geoHeader.section3.9.text1')}</p>
            <p className='text-decoration-underline fw-bold fs-6'>{t('geoHeader.section3.9.text2')}</p>
            <ul className={`py-2 fs-5 list-style-disc ${language==='en'? 'ps-4 pe-0':'pe-4 ps-0'} fs-6`}>
              <li className='py-1'>{t('geoHeader.section3.9.list.1')}</li>
              <li className='py-1'>{t('geoHeader.section3.9.list.2')}</li>
              <li className='py-1'>{t('geoHeader.section3.9.list.3')}</li>
              <li className='py-1'>{t('geoHeader.section3.9.list.4')}</li>
              <li className='py-1'>{t('geoHeader.section3.9.list.5')}</li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="bg-light">
        <div className='container-md w-90 mx-auto py-5'>
          <p className='text-center text-main2 small fw-bold'>{t('geoHeader.section4.text1')}</p>
          <p className='py-3 fw-bold'>{t('geoHeader.section4.text2')}</p>
          <ul className={`list-style-disc ${language==='en'? 'ps-4 pe-0':'pe-4 ps-0'}`}>
            <li className="py-1">{t('geoHeader.section4.list.1')}</li>
            <li className="py-1">{t('geoHeader.section4.list.2')}</li>
            <li className="py-1">{t('geoHeader.section4.list.3')}</li>
            <li className="py-1">{t('geoHeader.section4.list.4')}</li>
            <li className="py-1">{t('geoHeader.section4.list.5')}</li>
            <li className="py-1">{t('geoHeader.section4.list.6')}</li>
            <li className="py-1">{t('geoHeader.section4.list.7')}</li>
            <li className="py-1">{t('geoHeader.section4.list.8')}</li>
            <li className="py-1">{t('geoHeader.section4.list.9')}</li>
          </ul>
        </div>
      </div>
    </div>
  </>
}
